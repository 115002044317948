.research__list {
	list-style: none;
	padding: 0;
}

.research__item {
	display: flex;
	justify-content: space-around;
	flex-flow: row wrap;
	align-items: stretch;
    margin-bottom: 3rem;
	.research__header {
		flex: 1 100%;
	}
	.research__icon {
		
	}
	.research__content {
		flex: 1 2 auto;
	}
}

.research__icon {
	width: 14rem;
	margin-right: 2rem;
	height: 14rem;
	padding: 2rem; // flex-shrink: 0;
	border-radius: 100%;
	font-size: 4rem;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 100%;
	}
	span {
		font-size: 50%;
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-xs-max) {
	.research__item{
		flex-direction: column;
		align-items: center;
		.research__icon{
			margin: 2rem 0;
		}
        .research__content{
            width: 100%;
        }
	}
	.research__item .research__item .research__header,
	.research__item .research__item .research__icon,
	.research__item .research__item .research__content {
		flex: inherit;
	}
}



/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {
}