.video__container {
    background-color: $dark-blue;

    @each $color, $var in $color-list {
        &.#{$color} {
            background-color: #{$var};

            @if $var == white or $var == off-white {
                color: #35424a;
            }
            @else {
                color: white;
            }
        }
    }

    color: $white; // margin: -6rem 0 2rem 0;
    .video,
    .video__content {
        flex-grow: 1;
    }

    .video {
        flex-basis: 60%; // display: flex;
        // align-items: center;
    }

    .video__content {
        flex-basis: 40%;
        background-position: 50%;
        background-repeat: no-repeat;
    }

    .video__content {
        padding: 4rem;
        position: relative;

        &:before {
            width: 3rem;
            height: 2px;
            background-color: $blue;
            content: "";
            position: absolute;
            top: 4rem;
        }

        .video__eyebrow {
            display: block;
            padding-top: 1rem;
        }

        h2 {
            margin: 1rem 0;
        }
    }

    &.video--hero {
        margin-top: 0;

        .video {
            flex-basis: 40%;
            margin: 6rem 0;
        }

        .video__content {
            flex-basis: 60%;
        }
    }

    &.video--reverse {
        .container--flex {
            flex-direction: row-reverse;

            .video {
                // flex-basis: 40%;
                margin: 0;
            }

            .video__content {
                // flex-basis: 60%;
                padding: 4rem 6rem 2rem 0;
            }
        }
        // 	.video {
        // 		flex-basis: 40%;
        // 		margin: 6rem 0;
        // 	}
        // 	.video__content {
        // 		flex-basis: 60%;
        // 	}
    }
}

.video {
    position: relative;
    cursor: pointer;

    img {
        opacity: 0.6;
    }

    .embed-responsive {
        background-color: black;
        // Possibly use this for locked videos, to overlay onto thumbnail
    }

    &.video__link:after {
        position: absolute;
        color: white;
        font-size: 4.6rem;
        height: 8rem;
        width: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include icon('ion-play');
        padding-left: .5rem;
        top: calc(50% - 4rem);
        left: calc(50% - 4rem);
        z-index: 1;
        border-radius: 100%;
        border: .3rem solid white;
        opacity: 0.8;
    }

    &.video__linkbtn:after {
        position: absolute;
        color: white;
        font-size: 4.6rem;
        height: 8rem;
        width: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include icon('ion-play');
        padding-left: .5rem;
        top: calc(50% - 4rem);
        left: calc(50% - 4rem);
        z-index: 1;
        border-radius: 100%;
        border: .3rem solid white;
        opacity: 0.8;
    }
}

.embed-responsive[loaded]:after {
    content: initial;
}

.video-figure {
    &--margin-top {
        margin-top: 5rem;
    }

    &__bl {
        margin-bottom: 4rem;
    }

    &__info {
        margin-top: 2rem;
        font-size: 1.4rem;
    }

    &__caption {
        margin-bottom: 0.5rem;
        font-family: $font-heading;
        font-size: 1.8rem;
        font-weight: 300;
    }
}

.embed-responsive-1by1 {
    padding-bottom: 100.00%
}

.video-square {
    flex-basis: 30% !important;
}

.video__content-square {
    flex-basis: 70% !important;
}

.embed-responsive-16by9 .wistia_responsive_padding {
    padding: 56.25% 0 0 0;
}

.embed-responsive-1by1 .wistia_responsive_padding {
    padding: 100% 0 0 0;
}

.wistia-llg-inline  {
    position: relative;
    .wistia_responsive_wrapper{
        .wistia_embed{
            display: inline-block;
        }
    }
}

.wistia_responsive_wrapper{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    .wistia_embed{
        height: 100%; 
        width: 100%
    }
}


// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-md-max) {
    .video__container .container, .video__container.video--reverse .container {
        padding: 0;
        display: block;
        margin: auto;

        .video__content {
            padding: 2rem;
            background-size: cover;

            &:before {
                top: 2rem;
            }
        }
    }
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝
@include break(min, $screen-md-min) {
}


// ████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
// ╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
// 	 ██║   ███████║██████╔╝██║     █████╗     ██║
// 	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║
// 	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║
// 	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝


@include break(min, $screen-md-min, max, $screen-md-max) {
}
