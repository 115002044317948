/*nav footer*/
.footer {
	background-color: $dark-blue;
	color: white;
	a {
		color: $off-white;
	}
	ul {
		list-style-type: none;
		padding: 0;
		li {
			padding-top: 1rem;
		}
	}
	.footer__row {
		justify-content: space-between;
		flex-wrap: nowrap;
	}
	.footer__item {

		padding-bottom: 2rem;
		margin-top: 3rem;
		margin: 1rem;

		h3 {
			font-size: 1.8rem;
			font-weight: bold;
			font-family: $font-body;
		}
		p {
			margin-bottom: 0;
		}
		a {
			@include underline(white);
		}
	}
	.footer__utility {
		border-top: 2px solid $white-translucent;
		padding-top: 4rem;
		margin-top: 2rem;
	}
	.footer__legal {
		font-size: 1.6rem;
		align-self: center;
		margin: 0;
	}
	.social-links {
		font-size: 2rem;
		padding-left: 0.7rem;
		padding-right: 0.7rem;
		align-self: center;
		margin: 0;
		.social-links__item {
			display: inline;
			padding: 1rem;
		}
		a {
			text-decoration: none;
			display: inline-block;
			text-align: center;
			line-height: 1.6;
			color: #d5d5d5;
			transition: $transition--all;
			&:focus,
			&:hover {
				color: white;
				transition: $transition--all;
			}
		}
		i {
			font-size: 2.8rem;
		}
	}
	.footer__lower {
		background-color: darken($dark-blue, 10%);
		>.container {
			display: flex;
			height: 6rem;
		}
		.footer__legal {
			flex-grow: 1;
		}
		.footer__contact>img {
			max-width: 20rem;
			margin: 2rem 0;
		}
	}
	.footer__signup {
		background-color: $laser-blue;
		form {
			display: flex;
			height: 12rem;
			align-items: center;
			justify-content: space-around;
			.input--large {
				display: block;
				width: auto;
				width: 38rem;
				margin-right: 2rem;
			}
		}
		label h3{
			margin: 0;
		}
		.form__submit {
			background: 0 0;
			border: none;
			font-size: 5rem;
		}
		.signup__form {
			display: flex;
			align-items: center;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-xs-max) {
	.footer {
		.footer__legal {
			padding-bottom: 1rem;
		}
		.footer__lower {
			flex-direction: column;
			padding: 1rem 0;
			height: auto;
			margin: 0 -1rem;
			text-align: center;
		}
	}
}

@include break(max, $screen-sm-max) {
	.footer {
		padding: 1rem;
		padding-bottom: 0;
		.footer__item {
			max-width: none;
			padding: 1rem 0;
			h3 {
				margin: 0;
			}
		}
		.footer__nav {
			.footer__item {
				margin: 0;
				max-width: none;
				padding: 1rem 0;
				position: relative;
				cursor: pointer;
				user-highlight: none;
				&:after {
					@include icon("ion-arrow-down-b");
					transition: $transition--all;
					transform: rotate(0deg);
					margin-left: 1rem;
					align-self: center;
					font-size: 2rem;
					position: absolute;
					top: 1.4rem;
					right: 1rem;
				}
				ul {
					max-height: 0;
					margin: 0;
					overflow: hidden;
					transition: $transition--all;
				}
				&[open] {
					ul {
						max-height: 60rem;
						transition: $transition--all;
					}
					&:after {
						transition: $transition--all;
						transform: rotate(180deg);
					}
				}
			}
		}
		.row.footer__row {
			flex-direction: column;
		}
		.footer__legal {
			float: none;
			width: 100%;
		}
		.social-links {
			float: none;
			width: 100%;
		}
		.footer__lower>.container {
			flex-direction: column;
			height: auto;
		}
		.footer__signup {
			margin: -1rem;
			margin-bottom: 1rem;
			form {
				flex-direction: column;
				height: auto;
				padding: 1rem;
				margin-bottom: 2rem;
				label{
					flex-basis: initial;
				}
				.signup__form {
					flex-basis: initial;
					flex-direction: column;
					flex-basis: inital;
					width: 100%;
				}
				.input--large {
					width: 100%;
					margin: 0;
				}
				.form__submit {
					display: inline-block;
					cursor: pointer;
					text-align: center;
					line-height: 1.2;
					font-size: 3rem;
					padding: .3rem;
					margin-top: 1rem;
					width: 100%;
					user-select: none;
					position: relative;
					background-color: $light-blue;
					border-radius: $border-radius;
					transition: $transition--all;
				}
			}
		}
	}
	.footer__contact>img {
		max-width: 20rem;
		margin: 2rem 0;
	}
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
@include break(min, $screen-md-min) {
	.footer {
		.footer__contact {
			border-right: 1px solid $white-translucent;
			padding-right: 4rem;
		}
        .footer__item {
        	flex: 1 1 0%;
        }
    }
}
