$tag-color: #358ed7;
$label-color: #00a9e0;
.search-results__header {
	h1 {
		text-transform: none;
	}
	p {
		margin: 0;
	}
}

.search-results__utility {
	@include clearfix;
	margin-top: 1rem;
	padding: 2rem 0;
	>span {
		float: left;
	}

	&.search-results--in_title_bar
	{
		position: relative;
		top: -75px;
	}
}

.pagination.search-results__pagination {
	float: right;
	margin: 0;
	li a {
		border: none;
		border-radius: 0;
		&:hover,
		&:focus {
			background-color: transparent;
		}
	}
	.button {
		border: 1px solid;
		transition: $transition--all;
		&:hover,
		&:focus {
			color: white;
			border: 1px solid;
			transition: $transition--all;
		}
	}
	.pagination--selected {
		font-weight: bold;
		color: $text-slate;
		pointer-events: none;
	}
}

.search__footer {
	@include clearfix;
	.pagination {
		margin: 1rem 0;
	}
}

.search-results__select {
	width: 18rem;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	display: inline-block;
	position: relative;
    margin: 0 0.5rem;
	&:after {
		position: absolute;
		content: '';
		display: block;
		right: 0;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid black;
	}
}

.result-view
{
	min-height: 300px;	
}

.search-filter__menu {
	margin-top: 1rem;
	padding-top: 2rem;
}

.selected-filters {
	min-height: 62px;
	border-top: 1px solid $off-white;
	border-bottom: 1px solid $off-white;
	padding-top: 1rem;
	padding-bottom: .5rem;
	p {
		font-weight: bold;
		display: inline-block;
		padding-right: 2rem;
		line-height: 3rem;
        margin-bottom: 0;
	}
    // Make tag inline block
    span > span {
        display: inline-block;
    }
    .pill {
        margin-bottom: 1rem;
    }
}

.search-filters {

	label {
		position: relative;
		font-weight: normal;
        padding-right: 2rem;
        width: 100%;
		a {
			display: inline;
			padding-left: 3rem;
			position: relative;
			z-index: 1;
		}
	}
	label:before,
	label:after {
		font-size: 2rem;
		position: absolute;
		top: 0.2rem;
		left: 0;
		z-index: 0;
	}
	/*unchecked*/
	label:before {
		@include icon('ion-android-checkbox-outline-blank');
		color: royalblue;
	}
	/*checked*/

	label.label--checked:before {
		@include icon('ion-android-checkbox-outline');
		color: #67D8A4;
		max-width: 3rem;
	}
	.search-filter {
		position: relative;
		float: none;
		padding: 0;
		height: auto;
		display: block;
		border-bottom: 1px solid $off-white;
		.search-filter__drop {
			top: -3.4rem;
			right: 0;
			width: 100%;
			height: 4rem;
			border: none;
			position: absolute;
			display: block;
			text-align: right;
			i {
				transform: rotate(0);
				transition: all .3s ease-in-out;
				color: #2E598A;
				transform-origin: center;
				line-height: 2rem;
				height: 2rem;
				width: 2rem;
				display: inline-block;
				font-size: 1.6rem;
				text-align: center;
			}
		}
		&[data-open="true"] .search-filter__drop i {
			transform: rotate(180deg);
			transition: $transition--all-fast;
		}
		.search-filter__list {
			margin: 1rem 0;
			padding: 0;
			position: static;
			border: none;
			box-shadow: none;
			transition: $transition--all-fast;
			li {
				padding: 0.4rem; // visibility: hidden;
				// transition: $transition--all;
			}
			.search-filter__drop {
				position: absolute;
				right: 0;
				top: -4rem;
				width: 100%;
				z-index: 2;
			}
			list-style:none;
			input.checkbox {
				float: left;
			}
			.search-filter__rbl {
				color: #368ed8;

				span
				{
					color: black;	
				}
			}
			.search-filter__rbl::before {
				content: none;
			}
		}
	}
	h3 {
		// font-family: $font-sans-wide;
	}
	h4 {
		font-weight: bold;
		font-family: $font-body;
	}
}

.result-item {
	border-bottom: 1px solid $off-white;
	padding: 2rem 0;
	@include clearfix;
	&__featured {
		padding: 1.5rem 3rem;
		background: lighten($light-grey, 5%);
		margin-bottom: 3rem;
	}
	&__featured-border {
		margin-bottom: 0;
		margin-top: 0;
		border-color: lighten($light-grey, 5%);
	}
	.result__tag {
		margin: 0.5rem;
		color: #fff;
		font-size: 1.4rem;
		font-weight: normal;
		display: inline-block;
		padding: .4rem 1rem;
		background-color: turquoise;
        border-radius: 0.4rem;
		i {
			font-style: normal;
			padding-right: 1rem;
		}
		&.result__tag--member {
			background-color: $red;
		}
		&.result__tag--doc {
			background-color: royalblue;
		}
	}
	.result__label {
		margin: 1rem 0;
		margin-right: 1rem;
		font-size: 1.4rem;
		font-weight: 400;
		min-width: 8rem;
		border-radius: 4px;
		text-align: center;
		display: inline-block;
		padding: .4rem 1rem;
		background: #f2f2f2;
		border: 1px solid #ddd;
	}
	.result-item__date {
		margin: 0;
		color: $dark-blue;
		font-style: italic;
	}
	.result-item__title {
		margin: 0.8rem 0;
		font-weight: bold;
		font-size: 2.2rem; // font-family: $font-sans-wide;
	}
	.result-item__author {
		margin: 0;
		margin-bottom: 1.2rem;
	}
	.result-item__summary {
		@include clearfix;
		p {
			margin: 0;
			float: left;
			width: 80%;
			padding-right: 3.5rem;
		}
		.result-item__image {
			float: left;
			width: 20%;
		}
	}
	&.result-item--event {
		.result-item__date-icon {
			width: 10rem;
			height: 10rem;
			background: $red;
			border-radius: 100%;
			text-align: center;
			font-size: 3rem;
			color: white;
			float: left;
			margin-right: 3rem;
			margin-top: .8rem;
			span {
				margin: 1.2rem;
				display: inline-block;
				line-height: 1.2;
			}
		}
		.result-item__summary,
		.result-item__header {
			width: calc(100% - 13rem);
			float: right;
			p {
				float: none;
				width: auto;
			}
		}
		.result-item__location {
			color: $grey;
			padding-top: 1rem;
			i {
				color: $red;
			}
		}
	}
}

.searchTypeSwitch
{
	height: 60px;

	span
	{
		display: block;
		float: left;
		width: 50%;
		color: $limra-blue;
		padding: 4px;
		text-align: center;
		border: 1px solid $limra-blue;
        cursor: pointer;
	
		&.selected
		{
			color: white;
			background-color: $limra-blue;	
		}
	}
}

.search__form {
	margin-bottom: 2rem;
	height: 3rem;
	input.search__input {
		background: transparent;
		width: calc(100% - 3rem);
		border: 1px solid $border-grey;
		float: left;
		border-right: none;
		height: 3rem;
		padding: 0.4rem;
		&::-ms-clear {
			display: none;
		}
		&::-webkit-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder {
			color: $off_white;
		}
	}
	a.search__submit {
		float: left;
		display: block;
		width: 3rem;
		border: 1px solid $border-grey;
		border-left: none;
		height: 3rem;
		cursor: pointer;
		text-align: center;
		i {
			font-size: 2rem;
			line-height: 2.8rem;
			text-align: center;
		}
	}
}

.checkbox-container {}



/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/
@include break(max, $screen-xs-max) {
    // Hide utility section on mobile    
    .search-results__utility {
        display: none;
    }
}

@include break(max, $screen-sm-max) {
    .button--filter {
        // border: 1px solid $nacac_blue;
        text-align: center;
        padding: 1rem;
        margin: 2rem 0;
        display: block;
    }


    .js-filter-close {
        display: block;
        text-align: center;
        font-size: 275%;
        margin-bottom: 1rem;
    }

    .search-filter__menu {
        position: absolute;
        height: auto;
        background: rgba(255, 255, 255, 0.925); // border-right: 1px solid $nacac_border-lightgrey;
        transform: translateZ(0) translateX(-100%);
        transition: all 0.33s cubic-bezier(0, 0.68, 0.44, 0.99);
        z-index: 1;
        position: fixed;
        top: 8rem;
        right: 0;
        margin: 0;
        width: 100%;
        box-shadow: none;
        height: calc(100vh - 7rem);
        width: 100vw;
        overflow: auto;
        padding: 1rem;
        overflow-x: hidden;
        overflow: auto;
        overflow-x: hidden;
    }

    .search-results__utility {
        padding: 0.4rem 0;
    }

    .search-filter__menu[data-open="true"] {
        -webkit-transform: translateZ(0) translateX(0);
        -ms-transform: translateZ(0) translateX(0);
        transform: translateZ(0) translateX(0);
    }

    .selected-filters {
        background-color: transparent;

        .pill {
            margin-bottom: 1rem;
        }

        p {
            font-weight: bold;
            display: block;
            border-right: none;
            padding: 0;
            margin: 0;
        }
    }

    .search-results__utility > span {
        padding-bottom: 1rem;
    }

    .search-results__pagination {
        float: none;

        &.pagination--top {
            font-size: 90%;
        }
    }

    i.search-filter__close {
        position: absolute;
        right: 3rem;
        top: 3rem;
        font-size: 3rem;
    }

    .result-item .result__tag {
        margin: 1rem 1rem 1rem 0;
    }

    .result-item .result-item__summary {
        p {
            margin: 0;
            float: none;
            width: auto;
            padding-right: 0;
        }

        .result-item__image {
            display: none;
        }
    }

    .result-item.result-item--event {
        .result-item__date-icon {
            width: 6rem;
            height: 6rem;
            font-size: 1.8rem;
            margin-right: 1rem;
        }

        .result-item__header, .result-item__summary {
            width: calc(100% - 7rem);
        }
    }

    .pagination.search-results__pagination {
        float: none;
    }
}

// fix for date-picker
.mx-input-append
{
	right: 30px !important;
}
.results__select{
	.search-results__select {
		width: 50rem !important;
	}

}
/*
    Tablet and above
*/
@include break(min, $screen-sm-min) {
    .results__select {
        display: none;
    }
}

/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
															
*/



@include break(min, $screen-md-min) {
    a.button.button--filter,
    .js-filter-close {
        display: none;
    }
}
