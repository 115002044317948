﻿#MyInterests {
    input {
        cursor: pointer;
        &.child {
            margin-left: 4px;
        }
    }

    label {
        cursor: pointer;
        display: inline;
    }
}

.interests {
    border: 1px solid $off-white;
    background-color: white;
    padding: 0;
    padding-bottom: 2rem;

    .interests__navigation{
        display: flex;
        background: $off-white;
    }

    .interests__link {
        flex-grow: 1;
        height: 8rem;
        @include heading;
        font-size: 2.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-grey;
        color: $text-slate;

        i {
            padding-right: 1rem;
        }

        &:focus,
        &:hover {
            text-decoration: none;
        }

        &[selected="true"] {
            color: white;
            position: relative;
            cursor: default;
            pointer-events: none;
            background-color: $light-green;
        }
    }
}

#MyInterests, #MyInterests div, #MyInterests table, #MyInterests td
{
	line-height: 1.5em;
	margin: 0;
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██�███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-sm-max) {
    .interests {
        display: block;
        margin-top: 2rem;

        .interests__navigation {
            display: block;
        }

        .interests__link {
            padding: 1rem;
            height: 6rem;
            font-size: 2rem;

            h3 {
                margin-top: 0;
            }

            &:after {
                display: none;
            }
        }
    }
}
