.address{
	&__info{
		margin-bottom: 2.5rem;
		font-weight: 600;
		p{
			margin-bottom: 0;
			font-size: 1.8rem;
			line-height: 1.4
		}
	}
	&__pic{
		max-width: 220px;
		margin-bottom: 2rem;
		img{
			max-width: 100%;
		}
	}
}

@include break(max, $screen-sm-max) {
	.address{
		margin-top: 1rem;
	}
}