.inner-nav {
	padding: 1rem 0;
	width: 100%;
	margin: auto;
	border-bottom: 1px solid #ddd;
	z-index: 2;
	.inner-nav__container {
		max-width: 99rem;
		margin: auto;
		display: flex;
		height: 4rem;
		justify-content: center;
		.inner-nav__item {
			flex-grow: 0;
			align-self: center;
			min-width: 14rem;
		}
	}
	&[scrolled] {
		position: fixed;
		top: 18rem;
		padding: 1rem 0;
		background-color: $off-white;
	}
	.inner-nav__item {
		text-align: center;
		border-right: 1px solid $border-grey;
		padding: 0 1rem;
		&:last-child {
			border: none;
		}
	}
	.inner-nav__link {
		text-decoration: none;
		font-weight: bold;
		padding-bottom: 0.2rem;
		position: relative;
		transition: $transition--all;
		border-bottom: 3px solid transparent;
		&[link-selected] {
			pointer-events: none;
			color: $dark-blue;
			&:after,
			&:before {
				opacity: 1;
				visibility: visible;
			}
		}
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			top: 1.9rem;
			opacity: 0;
			visibility: hidden;
			margin: 1.3rem 0 0 -1.4rem;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0 1.4rem 1rem 1.4rem;
			border-color: transparent transparent white transparent;
		}
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 1.8rem;
			opacity: 0;
			visibility: hidden;
			margin: 1.3rem 0 0 -1.4rem;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0 1.4rem 1rem 1.4rem;
			border-color: transparent transparent $border-grey transparent;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-sm-max) {
	.inner-nav .inner-nav__container {
		flex-direction: column;
		margin: 0;
		height: auto;
	}
	.inner-nav .inner-nav__container .inner-nav__item {
		min-width: auto;
		width: 100%;
		flex-basis: 100%;
		-webkit-flex-basis: initial;
		text-align: left;
		border: none;
		border-top: 1px solid #ddd;
		padding: 1rem 0;
		font-size: 1.8rem;
		a.inner-nav__link{
			color: $laser-blue;
		}
	}
	.inner-nav .inner-nav__link:before,
	.inner-nav .inner-nav__link:after{
		content: initial;
	}
	.inner-nav.js-inner-nav {
		padding: 0;
		margin: 0 0 4rem;
		position: static;
		.inner-nav__item{
			padding: 1rem;
		}
	}
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
@include break(min, $screen-md-min) {}