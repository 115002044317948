.validation-summary-errors {
    color: red;
    margin-top: 2rem;
}

.form-control {
	width: calc(100% - 3rem);
}


textarea,
input[type="text"],
input[type="search"],
input[type="email"],
input[type="number"],
{
	background: white;
	color: $text-slate;
	font-size: 1.4rem;
	width: calc(100% - 3rem);
	border: .1rem solid $border-grey;
	border-radius: $border-radius;
	/*float: left;*/
	padding: 0.4rem;
	transition: $transition--all;
	&:focus {
		border-color: light-blue;
		box-shadow: 0 0 .2rem royalblue;
		transition: $transition--all;
		outline: none;
	}
	&::-ms-clear {
		display: none;
	}
	&::-webkit-input-placeholder,
	&:-moz-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {
		color: $grey;
	}
	&.input--large {
		height: 5rem;
		padding: 1.2rem;
		font-size: 1.8rem;
	}
	&.input-validation-error
	{
		background-color: red;	
		color: white;
	}
}


select {
	-webkit-appearance: menulist;
	-moz-appearance: menulist;
	appearance: menulist;
	border-radius: 0;
    font-size: 1.4rem;
    max-width: 100%;

	&.input-validation-error
	{
		background-color: red;	
		color: white;
	}
}

textarea
{
	&.input-validation-error
	{
		background-color: red;	
		color: white;
	}
}

label.label--select {
	position: relative;
	display: block;
	width: 30rem;
	/*overflow: hidden;*/
	cursor: pointer;
	margin: 0;
	margin-left: 1rem;

    select {
        appearance: none;
    }
}

label.label--select::after {
	content: ' ';
	position: absolute;
	right: 0;
	top: 0;
	width: 4rem;
	height: 100%;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABuklEQVRoQ+3XS07DMBAG4LFAQHfcBG5AewQkeoCueByIx6oHAIkjNNwAbsKOh0BBVpRSN07s8cxvVZGzq5LM5JuJnamhkRxmJA4qkF3rZOlI6QioAuXVAhU2OWzpSHLpQDcGO7JcPh9/fP+sbP7Jwf5ssTh/Bz2LE5abdxDSBjNkTm2WmurXHJiUvL2Q7WBtudCY1LxeSF8wNEaStwMJBUNhpHkdSGwwbYxGXgdyd/+0IkNTzq4kXTNcxPrZaqqury5m7W8HcvvwWBkyZxyIZDdLRjQ76MvN5Xxd9M6r9fn1W5GhEzRGgqCa3o4O96ab3zTvYkdjtBG26L3bLwqDQPRC7AmbUBuDQgxCtDFIRBCihbFx7ODZzmysjcSzsH33B6dfDUxTsWbwZB2RiKiOtIkla4b18P9jQ2eLHYoT1ZHsGEYnvF/2mMrBO5OAYL1am0gYJhGRDJFuAN7OCxAiiCpGiBBDVDAKCBWICKOEUIMkYRQRqhAWRhmhDonCABAQyCAGhIBBvBggAgpxMES0/R87ZhziXMMaGjmBc19bILkrHspXOhKqUO7zpSO5Kx7KVzoSqlDu86PpyB+tB7JCg0yLKQAAAABJRU5ErkJggg==) no-repeat center center;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 2rem;
	pointer-events: none;
}

label.label--select select {
	border: 1px solid $laser-blue;
	border-radius: $border-radius;
	background: white;
	padding: .4rem 1rem;
	width: 100%;
	cursor: pointer;
}

label.label--select select::-ms-expand {
	display: none;
}

/* Not sure if this is needed to normalize select widths
    label.label--select :-moz-any(select) {
	width: 110%;
}*/

label.label--select.ie9 select {
	width: 110%;
}

label.label--select select:focus {
	outline: .1rem dotted #A9A9A9;
}

button {
	border: none;
}

.form {
	margin: 3rem 0;
	padding: 3rem 1.5rem 3rem;
	font-size: 1.4rem;
	.form--dark {
		background: $dark-blue;
		color: $white;
		.form__input {
			color: $dark-blue;
		}
	}
	.form--center {
		max-width: 52.5rem;
		margin: auto;
		text-align: center;
	}
	.form__row {
		margin: 2rem 0;
		margin-top: 3rem;
	}
	.form__title {
		margin-top: 0;
	}
	.form__label {
		display: block;
		font-size: 1.4rem;
		font-weight: 400;
		text-align: left;
	}
	.form__input {
		width: 100%;
		padding: 1.1rem;
	}
}

.form__calculator {
	margin: 3rem 0;
	padding: 0;
	.form__label {
		display: block;
		h3 {
			margin: 0;
			margin-top: 2rem;
		}
	}
	.form__input {
		width: 10rem;
		display: inline;
		margin-right: 2rem;
		float: none;
		&.input--error {
			background-color: #fffae0;
		}
	}
	.error__box {
		min-height: 3rem;
		display: block;
		color: red;
		padding: .4rem 0;
	}
}

.form__search {
	display: flex;
	align-items: center;
	max-width: 65rem;
	margin: auto;
	label>* {
		margin: 0;
	}
	.search__input {
		height: 4rem;
		margin-left: 4rem;
		width: auto;
		border-radius: $border-radius 0 0 $border-radius;
		border-right: none;
		&::-ms-clear {
			display: none;
		}
		&::-webkit-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder {
			color: $grey;
		}
	}
	.search__submit {
		// float: left;
		display: block;
		width: 3rem;
		height: 4rem;
		border: .1rem solid $border-grey;
		border-left: none; // height: 3.4rem;
		cursor: pointer;
		text-align: center;
		border-radius: 0 $border-radius $border-radius 0;
		i {
			font-size: 2rem;
			line-height: 1.8;
			text-align: center;
		}
	}
}

// epi forms
.Form__Element__ValidationError
{
	color: red;	
}

.Form__Element__ValidationError:before 
{
   content: "** ";
}

.epiTextCounterBox
{
	clear: both;	
}

.epiTextCounter{
	font-style:italic;
}

.epiTextCounter-error{
	color: red;
}

.epiTextCharRemaining{
	font-style:italic;
	clear: both;
}

// DatePicker override
.mx-datepicker {
    margin-bottom: 1rem;
    .mx-input-wrapper {
        position: unset;
    }
} 

// Episerver Forms

.Form__Status__Message {
    padding: 2rem;
    &.Form__Success__Message {
        border: 1px solid $limra-light-green;
    }
} 


.EPiServerForms.container {
    max-width: 83rem;
    // Padding to match article padding
    padding-bottom: 6rem;
}

.Form__Element__Caption {
    font-weight: 700;
}

.Form__Title, .Form__Description {
    text-align: center;
}

.Form__Element {
    margin-bottom: 2rem;

    &.FormChoice label {
        font-weight: 400;
        display: block;
    }

    input, textarea {
        float: none;
    }
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-sm-min) {
	.form {
		padding: 3.5rem 0 2rem;
		&__button {
			margin-top: 2rem;
		}
		&__input {
			&--tablet-max-w {
				max-width: 50rem;
			}
		}
	}
	label.label--select {
		width: 20rem;
	}
	.form__search {
		display: none;
	}
}

@include break(max, $screen-xs-min) {
	.form {
		&__button {
			text-align: center;
		}
	}
}
