// ************************
// BUTTONS
// ************************
.button {
	display: inline-block;
	cursor: pointer;
	text-align: center;
	color: $white;
	font-family: $font-body;
	font-size: 1.4rem;
	font-weight: bold;
	padding: 1.4rem 2.8rem;
	line-height: 1.2;
	text-decoration: none;
	text-transform: uppercase;
	/*max-width: 26rem;*/
	min-width: 14rem;
	user-select: none;
	position: relative;
	background-color: $light-blue;
	border-radius: $border-radius;
	transition: $transition--all;
	span {
		z-index: 1;
		position: relative;
	}
	&:hover,
	&:focus {
		color: $white;
		text-decoration: none;
		background-color: lighten($blue, 10%);
		transition: $transition--all;
		box-shadow: 0 0 3rem rgba(0, 0, 0, .2);
	}
	&.button--small {
		padding: 1rem 2rem;
		font-size: 1.2rem;
	}
	&[disabled="disabled"],
	&[disabled] {
		cursor: none;
		pointer-events: none;
		opacity: 0.3;
	}
	&.button--no-margin {
		margin: 0;
	}
    &.button--apply-filters {
        text-align: center;
        display: inline-flex; 
        padding: 0 0.5rem; 
        border-radius: 0.5rem; 
        background-color: #368ed8; 
        color: white; 
        align-items: center; 
        min-width: auto;
        position: absolute;
        right: -0.5rem;
        top: 0.2rem;

        &:active {
            position: absolute;
            // Downshift to animate downclick
            top: 0.3rem;
        }

        > i {
            font-size: 1.6rem; 
            font-weight: 400; 
            height: 2rem;
            padding-top: 0.1rem;
        }
    }
}

.button--square {
	border-radius: 0;
	height: 100%;
}

@each $color,
$var in $color-list {
	.button--#{$color} {
		transition: $transition--all;
		background-color: #{$var};
		color: white;
		&:hover,
		&:focus {
			background-color: #{lighten($var,10%)};
		}
	}
}

.button--clear {
	border: 1px solid $white;
	background: transparent;
	color: $white;
	transition: $transition--all;
	&:hover,
	&:focus {
		background-color: white;
		transition: $transition--all;
		color: $text-slate;
	}
}

.button.button--center {
	display: block;
	margin: auto;
	max-width: 18rem;
	width: auto;
}

// Inline Button
a.button {
	margin-right: 2rem;
	margin-bottom: 2rem;
	&:hover,
	&:focus {
		text-decoration: none;
	}
    &.no-margin {
        margin: 0;
    }
    &.equal-margin {
		margin: 0.5rem;
	}
}

.button--center {
	margin: auto;
	display: block;
}

.button--right {
	float: right;
	margin-left: 1rem;
}

.button:active {
	position: relative;
	top: 1px;
}

.button__cluster {
	display: flex;
	justify-content: center;
	.button {
		border: 1px solid $border-grey;
		border-right: none;
		background: transparent;
		color: $blue;
		border-radius: 0;
		margin: 0;
		transition: $transition--all;
		&:hover,
		&:focus {
			background-color: white;
			transition: $transition--all;
			color: $text-slate;
		}
		&:first-child {
			border-radius: .4rem 0 0 .4rem;
			border-right: none;
		}
		&:last-child {
			border-radius: 0 .4rem .4rem 0;
			border-right: 1px solid $border-grey;
		}
	}
}

.button__banner {
	display: flex;
	min-height: 8rem;
	justify-content: center;
	.button {
		border-radius: 0;
		border: none;
		margin: 0;
		flex-grow: 1;
		max-width: none;
		min-width: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 120%;
		text-transform: none;
		.icon{
			margin-right: 1rem;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-sm-max) {
	.button__cluster {
		.button {
			min-width: 0;
		}
	}
	.button__banner {
		display: block;
		.button {
			height: 6rem;
		}
	}
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝
@include break(min, $screen-md-min) {}