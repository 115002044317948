﻿.roster__filters {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
}

.roster__countryChoose {
    display: flex;
}

.roster__titleChooseText {
    display: flex;
    flex: 2;
    input {
        width: 100%;
    }
}

.roster__titleChoose {
    padding: 0 4rem;
    display: flex;
}

#RosterList {
	padding-top: 20px;
}

.flyout--container {
    overflow: hidden;
}
.company--flyout {
    position: relative;
    animation: animatetop 1s;
    background: #eee;
    padding: 2rem;
    border: 1px solid #ccc;

    &.row {
        margin: 0;
    }
}

.col__divider {
    border-right: 1px solid white;
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
    .roster__filters {
        flex-direction: column;
    }

    .col__divider {
        border-right: none;
        border-bottom: 1px solid white;
    }
}