.mod-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.mod-wrapper {
    display: table-cell;
    vertical-align: middle;
}

// Web-to-leads form styles
[action*='servlet.WebTo'] > div > div.mod-body {
    max-width: 83rem;
    /*max-height: 40rem;*/
    margin:auto;
}

.mod-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 2rem 3rem;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: "Roboto", sans-serif;
	overflow-y: initial !important;

    &.regionPicker {
        max-width: 800px;
    }

	.article__header
	{
		margin-bottom: 10px;	
	}

	.mod-desc
	{
		text-align: center;
		width: 65%;
		margin: 0 auto;
	}
    
    i.right {
    float: right;
    font-size: 2rem;
    position: relative;
    // 1 more than parent to make it clickable
    z-index: 9999;
    }
}



.mod-body {
    margin: 1rem 0;
	max-height: 300px;
	overflow-y: auto;
}

/*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

.mod-enter {
    opacity: 0;
}

.mod-leave-active {
    opacity: 0;
}

.mod-enter .mod-container,
.mod-leave-active .mod-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.mod-footer
{
	width: 100%;
	display: flex;
	padding: 0;
	margin: 0;
	list-style: none;
	justify-content: center;
	align-items: center;
    > li {
        padding: 5px;
    }
}

.autofill__image {
	flex: 0 0 3.6rem;
	height: 3.6rem;
	display: block;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 1rem;
	img {
		width: 100%;
	}
}
.autofill__figure {
	display: flex;
	align-items: center;
}

.autofill {
    color: $text-slate;
    background: white;
    padding: .4rem;
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;
    font-size: 1.4rem;
    z-index: 5;
    min-height: 300px;

    a {
        cursor: pointer;
    }

    .autofill__input {
        position: relative;
        color: $text-slate;
        margin: 1rem 0;
        height: 4rem;
        flex-shrink: 0;
        width: 100%;

        &:focus {
            border: 1px solid $laser-blue;
        }
    }

    .autofill__item {
        padding: .6rem 0;
        /*flex: 0 0 auto; // safari < 9.0 fix*/
        a {
            color: $text-slate;
        }
    }

    .autofill__target {
        display: flex; // align-items: center;
        // flex-grow: 1;
    }

    .autofill__list {
        flex-direction: column;
        display: flex;
        flex-shrink: 0;
        @include unlist;
    }

    .autofill__selected {
        background: white;
        padding: 0 1rem;
        border-radius: .4rem;
        border-radius: 1px solid #eee;
        display: flex;
    }

    .courtries__name {
        align-self: center;
    }

    .autofill__caption {
        color: $text-slate;
    }

    .autofill__panel {
        margin-top: 1rem;
        display: flex;
        height: 0;
        flex-direction: column;

        &[open] {
            height: auto;
        }
    }
}



/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.autofill{
		background: transparent;
		padding: 0;
		width: auto;
		max-width: 100%;
		color: white;
	}

	/* note: too large for small screens */

    .mod-container {
        padding: 2rem;

        .mod-desc
	    {
            width: unset;
	    }
    }
}

/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝      
*/

@include break(min, $screen-lg-min) {

}

/*
  ████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
  ╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
	 ██║   ███████║██████╔╝██║     █████╗     ██║   
	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║   
	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║   
	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝   
*/

@include break(min, $screen-md-min, max, $screen-md-max) {

}
