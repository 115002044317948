/******************************************************************
███╗   ███╗██╗██╗  ██╗██╗███╗   ██╗███████╗
████╗ ████║██║╚██╗██╔╝██║████╗  ██║██╔════╝
██╔████╔██║██║ ╚███╔╝ ██║██╔██╗ ██║███████╗
██║╚██╔╝██║██║ ██╔██╗ ██║██║╚██╗██║╚════██║
██║ ╚═╝ ██║██║██╔╝ ██╗██║██║ ╚████║███████║
╚═╝     ╚═╝╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝╚══════╝
********************************************************************/

@mixin pseudo($placement, $height, $width, $color) {
  position: relative;
  &:#{$placement} {
    position: absolute;
    height: $height;
    width: $width;
    background-color: $color;
    content: "";
  }
}

@mixin unlist{
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin unlink{
  text-decoration: none;
  &:hover,&:focus{
    text-decoration: none;
  }
}

@mixin underline($color){
  display: inline-block;
  background-size: 0px 1px;
  background-position: 0px 98%;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:active,
  &:focus,
  &:hover {
    color: $color;
    background-image: linear-gradient(to top, currentcolor 50%, currentcolor 50%);
    background-size: 100% 1px;
  }
}

// Reference
// -- @each:

// @each $color,
// $var in $color-list {
//   &.pill--#{$color} {
//     background-color: #{$var};
//     border: 1px solid #{$var};
//     color: white;
//   }
// }

// -- @for
// @for $i from 1 through 3 {
//   .item-#{$i} { width: 2em * $i; }
// }


/*
 ██████╗██╗     ███████╗ █████╗ ██████╗ ███████╗██╗██╗  ██╗
██╔════╝██║     ██╔════╝██╔══██╗██╔══██╗██╔════╝██║╚██╗██╔╝
██║     ██║     █████╗  ███████║██████╔╝█████╗  ██║ ╚███╔╝
██║     ██║     ██╔══╝  ██╔══██║██╔══██╗██╔══╝  ██║ ██╔██╗
╚██████╗███████╗███████╗██║  ██║██║  ██║██║     ██║██╔╝ ██╗
 ╚═════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝╚═╝  ╚═╝
*/

@mixin clearfix {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}


/*
 ██████╗ ██████╗  █████╗ ██████╗ ██╗███████╗███╗   ██╗████████╗███████╗
██╔════╝ ██╔══██╗██╔══██╗██╔══██╗██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
██║  ███╗██████╔╝███████║██║  ██║██║█████╗  ██╔██╗ ██║   ██║   ███████╗
██║   ██║██╔══██╗██╔══██║██║  ██║██║██╔══╝  ██║╚██╗██║   ██║   ╚════██║
╚██████╔╝██║  ██║██║  ██║██████╔╝██║███████╗██║ ╚████║   ██║   ███████║
 ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝
*/


/* @include css-gradient(#dfdfdf,#f8f8f8); */

@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
  background-color: $to;
  background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background-image: -webkit-linear-gradient(top, $from, $to);
  background-image: -moz-linear-gradient(top, $from, $to);
  background-image: -o-linear-gradient(top, $from, $to);
  background-image: linear-gradient(to bottom, $from, $to);
}


/*
██████╗ ███████╗████████╗██╗███╗   ██╗ █████╗
██╔══██╗██╔════╝╚══██╔══╝██║████╗  ██║██╔══██╗
██████╔╝█████╗     ██║   ██║██╔██╗ ██║███████║
██╔══██╗██╔══╝     ██║   ██║██║╚██╗██║██╔══██║
██║  ██║███████╗   ██║   ██║██║ ╚████║██║  ██║
╚═╝  ╚═╝╚══════╝   ╚═╝   ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝
*/


/* @include background-image-retina(logo, png, 200px, 100px); */

@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    & {
      background-image: url($file + '@2x.' + $type);
      -webkit-background-size: $width $height;
      -moz-background-size: $width $height;
      -o-background-size: $width $height;
      background-size: $width $height;
    }
  }
}


/*
██████╗ ██████╗  █████╗ ██╗    ██╗    ██╗ ██████╗ ██████╗ ███╗   ██╗
██╔══██╗██╔══██╗██╔══██╗██║    ██║    ██║██╔════╝██╔═══██╗████╗  ██║
██║  ██║██████╔╝███████║██║ █╗ ██║    ██║██║     ██║   ██║██╔██╗ ██║
██║  ██║██╔══██╗██╔══██║██║███╗██║    ██║██║     ██║   ██║██║╚██╗██║
██████╔╝██║  ██║██║  ██║╚███╔███╔╝    ██║╚██████╗╚██████╔╝██║ ╚████║
╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝ ╚══╝╚══╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝
*/

@mixin icon-draw($fill-color) {
  $hash: unique-id();
  path {
    stroke: $fill-color;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: dash-#{$hash} 1200ms linear forwards;
    animation-delay: 600ms;
    opacity: 0;
  }
  @keyframes dash-#{$hash} {
    0% {
      opacity: 1;
      stroke-dashoffset: 300;
      stroke: $fill-color;
      fill: transparent;
    }
    75% {
      opacity: 1;
      stroke-dashoffset: 0;
      stroke: $fill-color;
      fill: transparent;
    }
    100% {
      opacity: 1;
      stroke: transparent;
      fill: $fill-color;
    }
  }
}


//triangle
@mixin arr($width, $height, $bg, $direction){
  width: 0px;
  height: 0px;
  border-style: solid;
  @if $direction == t{
    border-width: 0 $width / 2 + px $height + px $width / 2 + px;
    border-color: transparent transparent $bg transparent;
  }
  @if $direction == r{
    border-width: $height / 2 + px 0 $height / 2 + px $width + px;
    border-color: transparent transparent transparent $bg;
  }
  @if $direction == b{
    border-width: $height + px $width / 2 + px 0 $width / 2 + px;
    border-color: $bg transparent transparent transparent;
  }
  @if $direction == l{
    border-width: $height / 2 + px $width + px $height / 2 + px 0;
    border-color: transparent $bg transparent transparent;
  }
  @if $direction == tl{
    border-width: $height + px $width + px 0 0;
    border-color: $bg transparent transparent transparent;
  }
  @if $direction == tr{
    border-width: 0 $width + px $height + px 0;
    border-color: transparent $bg transparent transparent;
  }
  @if $direction == br{
    border-width: 0 0 $height + px $width + px;
    border-color: transparent transparent $bg transparent;
  }
  @if $direction == bl{
    border-width: $width + px 0 0 $height + px;
    border-color: transparent transparent transparent $bg;
  }
}