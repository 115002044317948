.magazinearticlefeednav { /*copied from .button*/
        margin: 0;
        display: inline-block;
        cursor: pointer;
        text-align: center;
        color: #fff;
        font-family: "Open Sans",sans-serif;
        font-size: 1.4rem;
        font-weight: 700;
        padding: 1.4rem 2.8rem;
        line-height: 1.2;
        text-decoration: none;
        text-transform: uppercase;
        min-width: 14rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: relative;
        background-color: #00437b;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out
    }

        .magazinearticlefeednav:hover {
            color: #fff;
            text-decoration: none;
            background-color: #26c9fe;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            -webkit-box-shadow: 0 0 3rem rgba(0,0,0,.2);
            box-shadow: 0 0 3rem rgba(0,0,0,.2)
        }

        .magazinearticlefeednav:focus {
            color: #fff;
            text-decoration: none;
            background-color: #26c9fe;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            -webkit-box-shadow: 0 0 3rem rgba(0,0,0,.2);
            box-shadow: 0 0 3rem rgba(0,0,0,.2)
        }

        .magazinearticlefeednav[selected] {
            color: #fff;
            text-decoration: none;
            background-color: #26c9fe;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            -webkit-box-shadow: 0 0 3rem rgba(0,0,0,.2);
            box-shadow: 0 0 3rem rgba(0,0,0,.2)
        }

.magazinezoom {
	padding: 0px;
	margin: 0 auto;
	transition: transform .6s;
}

	.magazinezoom:hover {
		-ms-transform: scale(1.5); /* IE 9 */
		-webkit-transform: scale(1.5); /* Safari 3-8 */
		transform: scale(1.5);
	}

    .magazinebillboard-desktop {
        display: block
    }

    .magazinebillboard-mobile {
        display: none
    }
    .magazine_dropdown {
        display: none
    }
    .magazinepagination.__pagination {
        margin: auto;
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        list-style-type: none;
        padding: 0;
    }

        .magazinepagination.__pagination li a {
            position: relative;
            float: left;
            padding: 6px 12px;
            margin-left: -1px;
            line-height: 1.42857143;
            color: #337ab7;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
        }

            .magazinepagination.__pagination li a:focus, .magazinepagination.__pagination li a:hover {
                background-color: transparent
            }

        .magazinepagination.__pagination .button {
            border: 1px solid;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out
        }

            .magazinepagination.__pagination .button:focus, .magazinepagination.__pagination .button:hover {
                color: #fff;
                border: 1px solid;
                -webkit-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out
            }

        .magazinepagination.__pagination .pagination--selected {
            font-weight: 700;
            color: #00437b;
            pointer-events: none
        }

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██�███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-xs-max) {

}

@include break(max, $screen-sm-max) {

    .magazine_dropdown {
        margin: auto;
        width: 50%;
        height: 45px;
        position: relative;
        display: flex;
        text-align: center;
        background-color: #00437b;
        color: white;
        font-family: "Open Sans",sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
    }

    .magazine_dropdown_item {
        background-color: #00437b;
        color: white;
        font-family: "Open Sans",sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
    }

    .show {
        display: block;
    }

    .magazinearticlefeednav {
        display: none
    }

        .magazineauthor-portrait {
            height: 150px !important;
            width: 150px !important;
            padding: 5px;
            
        }
        .magazineauthor-text {            
            float: left;
			padding-left: 10px;
        }
		.magazinebillboard {
            height: auto !important;
            width: auto !important;
        }

        div.magazinebillboard-desktop {
            display: none 
        }
        div.magazinebillboard-mobile{
            display: block;
            margin-left :auto;
            margin-right:auto;
            > img{
                display:block;
                margin:auto;
            }
        }
        .magazinearticlecolumn {
            display: flex;
            flex-direction: column;
        }

            .magazinearticlecolumn :nth-child(1) {
                order: 2;
            }

            .magazinearticlecolumn :nth-child(2) {
                order: 1;
            }

            .magazinearticlecolumn :nth-child(3) {
                order: 3;
            }

            .magazinearticlecolumn :nth-child(4) {
                order: 4;
            }

}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝
@include break(min, $screen-md-min, max, $screen-md-max) {

}
@include break(min, $screen-md-min) {

}
