.js-dropdown.dropdown--topic {
	padding: 0;
	border-bottom: 1px dotted #ddd;
	.js-dropdown-trigger {
		font-size: 1.686rem;
		border-bottom: none;
		padding: 2rem 0;
		font-family: Roboto, sans-serif;
		font-weight: 700;
		i {
			display: inline-block;
			transform: rotate(0deg);
			width: 2.4rem;
			text-align: center;
		}
	}
	&[data-open="true"]>.js-dropdown-trigger i {
		transform: rotate(180deg);
	}
	.js-dropdown-menu {
		padding-left: 2.8rem;
		a:not([class*="button"]) {
			font-size: 1.686rem;
		}
	}
	.topic__list{
		padding-left: 2.2rem;
	}
}

.column--topic {
	.topic__header {
		border-bottom: 1px solid #ddd;
	}
}

.pagination.pagination--alphabet {
	display: flex;
	justify-content: center;
	padding: 0;
	flex-wrap: wrap;
	>li {
		flex-grow: 1;
	}
	a {
		border: none;
		font-size: 2.2rem;
		font-weight: 700;
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-xs-min) {
	.pagination.pagination--alphabet {
		justify-content: flex-start;
		flex-wrap: wrap;
		>li {
			flex-grow: 0;
		}
	}
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝
@include break(min, $screen-md-min) {}