$navigation-height: 5rem;
$interior-width: 60rem;
[v-cloak] {
	visibility: hidden;
}

.navigation {
	margin: auto;
	position: fixed;
	padding: 0;
	width: 100%;
	overflow: visible;
	z-index: 3;
	top: $utility-height;
	background-color: #FFF;
	box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, .1);
	.primary__list,
	.secondary__list,
	.tertiary__list,
	.utility--mobile {
		@include unlist;
	}
	.primary__list {
		flex-basis: 100%;
		width: 100%;
		a {
			background: 0 0;
			font-family: $font-heading;
			text-transform: uppercase;
			color: $text-slate;
			user-drag: none;
			user-select: none;
			-moz-user-select: none;
			-webkit-user-drag: none;
			-webkit-user-select: none;
			-ms-user-select: none;
		}
	}
}

/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {

	.navigation__toggle {
		position: fixed;
		right: 1rem;
		top: .6rem;
		z-index: 6;
		font-size: 4rem;
		color: $dark-blue;;
		width: 5rem;
		display: flex;
		align-items: center;
		height: 5rem;
		justify-content: center;
		text-align: center;
		transition: $transition--all;
		&:after {
			@include icon("ion-navicon");
		}
		&[data-open="true"] {
			transform-origin: center;
			transform: rotate(180deg);
			transition: $transition--all;
			&:after {
				@include icon("ion-android-close");
			}
		}
	}
	.navigation {
		max-width: inherit;
		width: 200%;
		z-index: 4;
		transform: translate(50%, 0);
		transition: $transition--all;
		background-color: $dark-blue;
		color: white;
		left: 0;
		bottom: 0;
		display: block;
		top: 6.4rem;
		// height: calc(100vh - 13rem);
		overflow-y: auto;
		html .countries + .utility + .navigation__toggle + & {
			top: 12rem;
		}
		a {
			background: 0 0;
			font-size: 1.4rem;
			text-decoration: none;
		}
		&[data-open="true"] {
			transform: translate(0, 0);
			transition: $transition--all;
		}
		&[data-secondary="true"] {
			transform: translate(-50%, 0);
			transition: $transition--all;
		}
		&[data-tertiary="true"] {
			.secondary__list {
				transform: translateX(-100%);
			}
			.tertiary__list {
				right: 0;	
			}
		}
		.primary__list .utility--mobile {
			li {
				border: none;
				&:first-child {
					border-top: none;
				}
				a {
					text-transform: none;
					color: white;
					padding: .75rem 1rem;
				}
			}
		}
		.container {
			margin: 0;
			padding: 0;
			width: 100%;
		}

		.secondary__title, .primary__title {
			display: none !important;
		}

		.primary__list {
			a {
				background: 0 0;
				font-size: 1.6rem;
			}
			.primary__item {
				display: flex;
				align-items: center;
			} //height: 100%;

			.primary__item {
				position: relative;
				a {
					.link__innerSpan {
						//width: calc(100% - 6.4rem);
						display: flex;
						flex-basis: 100%;
						margin: -1rem;
						padding: 1rem 6.4rem 1rem 1rem;
					}
					> .icon {
						position: absolute;
						top: 0;
						right: 0;
						display: flex;
						height:100%;
						width: 6.4rem;
						font-size: 1.8rem;
						align-items: center;
						justify-content: center;
						box-shadow: inset 1px 0 #ddd;
					}
				}
			}



		}
		.secondary__list {
			transition: $transition--all;
		}
		.secondary__item {
			position: relative;
			a {

				.link__innerSpan {
					//width: calc(100% - 6.4rem);
					display: flex;
					flex-basis: 100%;
					margin: -1rem;
					padding: 1rem 6.4rem 1rem 1rem;
				}
				> .icon {
					position: absolute;
					top: 0;
					right: 0;
					display:flex;
					height:100%;
					width: 6.4rem;
					font-size: 1.8rem;
					align-items: center;
					justify-content: center;
					box-shadow: inset 1px 0 #ddd;
				}
			}
		}
		.secondary__title {
			display: none;
		}
		.navigation__interior {
			position: relative;
			border-radius: .4rem;
			overflow-x: hidden;
		}
		.primary__list,
		.navigation__interior {
			flex-direction: column;
			height: auto;
			max-width: 50%;
			flex-basis: 50%;
			li {
				justify-content: space-between;
				border-bottom: 1px solid #ddd;
				&:first-child {
					border-top: 1px solid #ddd;
				}
				&.secondary__title {
					+ {
						.tertiary__item {
							border-top: 1px solid #ddd;
						}
					}
				}
				a {
					padding: .75rem;
					width: 100%;
					display: flex;
					color: white;
				}
			}
		}
		.tertiary__list {
			//display: none;
			position: absolute;
			width: 100%;
			top: 4.2rem;
			right: -100%;
			//left: auto;
			height: 100%;
			z-index: 1;
			transition: $transition--all;
		}
		.navigation__back {
			padding: .75rem;
			font-size: 1.6rem;
			//margin-right: 6rem;
			text-transform: uppercase;
			font-family: $font-heading;
			white-space: nowrap;
		}
	}
}

/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {
	.navigation {
		border-bottom: 2px solid $blue;
		.navigation__interior {
			display: flex;
			position: absolute;
			z-index: 2;
			top: 6rem;
			max-width: $interior-width;
			a {
				background: 0 0;
				color: white;
			}
		}
		.utility--mobile {
			display: none;
		}
		.navigation__toggle,
		.navigation__back {
			display: none;
		}
		.primary__list {
			display: flex;
			height: $navigation-height;
		}
		.primary__item {
			flex-grow: 1;
			display: flex;
			justify-content: center;
			position: relative;
			cursor: pointer;
	
			> .primary__item__link {
				pointer-events: none;
			}
			a {
				background: 0 0;
				align-self: center;
				font-size: 1.4rem;
				&:hover,
				&:focus {
					color: $dark-blue;
				}
				.icon {
					display: none;
				}
			}
			&:after {
				@include icon("ion-arrow-down-b");
				margin-left: 1rem;
				align-self: center;
				font-size: 2rem;
				display: block;
			}
			&[data-selected="true"] {
				background-color: $dark-blue;
				margin-bottom: -2px;
				a {
					background: 0 0;
					color: white;
				}
				&:before {
					@include icon("ion-arrow-up-b");
					color: $light-green;
					z-index: 2;
					font-size: 4.2rem;
					position: absolute;
					top: 4rem;
					left: 0;
				}
				&:after {
					@include icon("ion-arrow-up-b");
					color: white;
					z-index: 2; // font-size: 4.2rem;
					// position: absolute;
					// top: 4rem;
				}
			}
		}

		.secondary__title, .primary__title {

			display: none;
			padding-left: 1.2rem;
			width: 360px;

			& a
			{
				font-size: 1.6rem;
				background: 0 0;
				color: $white;
				padding: .75rem;
				display: block;
				&:hover,&:focus {
					color: $light-green;
				}
			}
		}

		.primary__title
		{
			a
			{
				font-size: 2rem;
				 text-transform: uppercase;
				padding-bottom: 0;
				padding-top: .5rem;
			}
				
		}

		.secondary__list {
			// flex-basis: 40%;
			// width: 40%;
			background-color: $dark-blue;
			.secondary__item {
				width: 24rem;
				&:last-child{
					box-shadow: none;
				}
				&[data-selected="true"] {
					background-color: $tertiary-blue;
					a {
						background: 0 0;
						background-color: $tertiary-blue;
					}
				}
				
				a {
					display: flex;
					position: relative;
					padding: .75rem;
					padding-left: 1rem;
					//cursor: pointer;
					> span {
						//display contents keeps this from having an actual box
						//key here because wrapping text creates weird live/dead zones
						display: contents;
						cursor: default;
					}
					> .icon {
						position: absolute;
						top: 0;
						right: 0;
						display: none;
						height:100%;
						width: 4.8rem;
						font-size: 1.6rem;
						align-items: center;
						justify-content: center;
						box-shadow: inset 1px 0 #ddd;
						cursor: cell;
					}
					// &:hover {
					// 	background-color: darken($dark-blue, 3%);
					// }
				}
			}
		}
		a {
			background: 0 0;
			text-decoration: none;
			&:focus,
			&:hover {
				text-decoration: none; // color: $light-orange;
			}
		}
		.tertiary__list {
			background: $tertiary-blue;
			.tertiary__item a {
				font-size: 1.4rem;
				display: block;
				padding: .75rem;
				padding-left: 4.8rem;
				&:focus,
				&:hover {
					color: $light-green;
				}
			}
		}

	}
}

/*
████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
	 ██║   ███████║██████╔╝██║     █████╗     ██║   
	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║   
	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║   
	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝   
*/

@include break(min, $screen-lg, max, $screen-xl) {
	.navigation
	{
		.secondary__title a
		{
			font-size: 1.2rem;
		}	

		.primary__title a
		{
			font-size: 1.4rem;
		}

		.tertiary__list
		{
			.tertiary__item a
			{
				font-size: 1.1rem;
				padding-top: .6rem;
				padding-bottom: .6rem;
			}
		}
		.secondary__list 
		{
			.secondary__item a		
			{
				padding: .6rem;
				font-size: 1.2rem;
			}
		}
	}

}
//if wider than most tablets

@include break(min, 1025px) {
	html body .navigation .secondary__list .secondary__item a>.icon{
		box-shadow: none;
	}
}

@include break(min, $screen-md-min) {
	html {
		&:hover {
			.navigation .secondary__item a>.icon {
				box-shadow: none !important;
			}
		}
	}
}



/*
████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
	 ██║   ███████║██████╔╝██║     █████╗     ██║   
	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║   
	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║   
	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝   
*/

@include break(min, $screen-md-min, max, $screen-md-max) {
	.navigation {
		.primary__item {
			&:after,
			a {
				background: 0 0;
				font-size: 80%;
			}
		}
	}
}

@include break(min, $screen-sm, max, $screen-lg) {
	.secondary__item {
		font-size: 1.0rem;
	}

	.navigation
	{
		.secondary__title a
		{
			font-size: 1.1rem;
		}	

		.primary__title a
		{
			font-size: 1.2rem;
		}

		.tertiary__list
		{
			.tertiary__item a
			{
				font-size: 1.4rem;
				padding-top: .5rem;
				padding-bottom: .5rem;
			}
		}
		.secondary__list 
		{
			.secondary__item a		
			{
				padding: .5rem;
			}
		}

		.primary__item {
			&:after,
			a {
				background: 0 0;
				font-size: 80%;
			}
		}
	}
}

// ************************
// Accessiblity Skip Nav
// ************************
.skip-nav {
	display: block;
	z-index: 1;
	position: absolute;
	height: 1px;
	width: 1px;
	overflow: hidden;
	left: -1000px;
	top: -1000px;
	white-space: nowrap;
	padding: 10px;
	background: #fff;
}

.skip-nav:focus,
.skip-nav:active,
.skip-nav:hover {
	position: absolute;
	left: 0;
	top: 0;
	height: auto;
	width: auto;
	overflow: hidden;
	z-index: 999;
}
