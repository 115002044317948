/*cards*/

.cards {
	.interior-nav {
		padding: 1rem 0;
		padding-bottom: 2rem;
		border-bottom: 1px solid #ddd;
		width: 100%;
		max-width: 111rem;
		margin: auto;
		display: flex;
		transition: $transition--all;
		text-align: center;

		.interior-nav__link {
			flex-grow: 1;
			text-align: center;
			border-right: 1px solid $border-grey;
			padding: 0 1.4rem;
			display: flex;
			color: $grey;
			text-decoration: none;
			font-size: 1.4rem;
			cursor: pointer;
			font-weight: bold;
			position: relative;
			transition: $transition--all;
			&:last-child {
				border: none;
			}
			span {
				display: flex;
				align-self: flex-end;
				position: relative;
			}
			&[selected] {
				color: $green;
				&:before,
				&:after {
					content: "";
					height: 0;
					width: 0;
					border: 1rem solid transparent;
					position: absolute;
					left: calc(50% - 1rem);
				}
				&:before {
					border-bottom-color: $border-grey;
					bottom: -2.1rem;
				}
				&:after {
					border-bottom-color: white;
					bottom: -2.2rem;
				}
			}
		}
		.interior-nav__label {
			border-color: transparent;
			font-size: 1.8rem;
			font-weight: 700;
			padding-bottom: .2rem;
		}

	}

	.card__row {
		visibility: hidden;
		opacity: 0;
	}

	&[data-loading="false"] .card__row {
		visibility: visible;
		transition: $transition--all;
		opacity: 1;
	}

	&[data-loading="true"] .card__row {
		visibility: hidden;
		transform: translateY(1rem);
		opacity: 0;
	}
}

// Cards
// Linked Cards
a.card {
	text-decoration: none;
	.card__body h3 {
		text-align: left;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		box-shadow: -.6rem .6rem 3rem rgba(0, 0, 0, .2);
	}
}

a.featured_card
{
	&:hover,
	&:focus {
		color: $green;
	}
}

.card__row {
	max-width: 102rem;
	margin-top: 2rem;
	margin-left: auto;
	margin-right: auto;
}

.card {
	color: $text-slate;
	position: relative;

	&[class*="col-"] {
		margin: 1.5rem;
	}

	margin-bottom: 2rem;
	background: linear-gradient($off-white, #eee);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
	border: 1px solid #ddd;
	border-top: 3px solid $green;

	h3 {
		margin-top: 0;
	}

	h3 {
		margin-top: 2rem;

		a {
			display: block;
		}
	}
	/* Card Components */
	.card__header {
		position: relative;
		min-height: 5rem;

		&--underline {
			margin-bottom: 2rem;
			padding: 3rem 0;

			&:after {
				content: "";
				position: absolute;
				left: -1.5rem;
				right: -1.5rem;
				bottom: 0;
				border-bottom: 1px solid $grey;
			}
		}

		.card__icon {
			position: absolute;
			display: block;
			right: 0;
			font-size: 3rem;
			width: 5rem;
			height: 5rem;
			border-radius: 100%;
			border: 2px solid white;
			background: darkorchid;
			text-align: center;
			padding: .4rem;
			margin: 1rem;
			@include icon-draw(white);

			svg,
			img {
				height: 2rem;
				margin: auto;
				max-width: 2rem;
			}

			@each $color, $var in $color-list {
				&.#{$color} {
					background-color: #{$var};
				}
			}
		}
	}

	.card__body {
		img {
			margin-bottom: 12px;
		}
	}

	.list__labels-tags a {
		color: white;
	}

	.card__tag {
		a {
			color: $green;
		}

		display: block;
		font-size: 1.4rem;
		left: 0;
		font-weight: bold;
		width: 80%;
		text-align: left;
		padding: 2rem 0;
		margin: 0;
		text-transform: uppercase;
		display: flex;
		list-style: none;

		li {
			text-transform: uppercase;
			font-size: 1.3rem;
			margin: 0;
			padding: 0;
			margin-right: 0.6rem;
			padding-right: 0.6rem;
			border-right: 1px solid $grey;
			text-align: center;

			&:last-child {
				border: none;
			}
		}
	}

	.card__title {
		text-align: center;
		margin: 0;
	}

	.card__inner {
		padding: 2rem 0;
	}

	.card__el {
		padding: 0 2.5rem;
		margin-bottom: 2rem;
	}

	.card__footer {
		.button {
			display: inline-block;
			margin: 1rem;
			width: 20rem;
		}
	}
	/* Card Types */
	&.card--bare,
	&.card--event {
		background: 0 0;
		border: none;
		box-shadow: none;

		.card__header {
			padding-bottom: 40%;
			min-height: 24rem;
			color: #FFF;
			margin-left: -1.5rem;
			margin-right: -1.5rem;
			overflow: hidden;
			display: table;
			text-align: center;
			width: -webkit-calc(100% + 3rem);
			width: calc(100% + 3rem);
			background-repeat: no-repeat;
			background-position-y: 50%;
			background-size: 100%;
		}
	}

	&.card--event {
		text-align: center;
		position: relative;

		.card__date {
			position: absolute;
			background-color: white;
			top: 2rem;
			left: 3rem;
			height: 6.4rem;
			width: 6rem;
			border: 2px solid white;
			box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);

			.date__month {
				border-top: .6rem solid $dark-blue;
				font-family: $font-heading;
				font-weight: 700;
				font-size: 1.8rem;
				line-height: 1.2;
			}

			.date__day {
				font-family: $font-heading;
				font-weight: 700;
				font-size: 2.8rem;
				line-height: 1.2;
			}
		}

		.card__body {
			p {
				margin: 0;
			}

			.body__theme {
				font-style: italic;
				margin-bottom: 1rem;
			}
		}

		.card__footer {
			margin-top: 2rem;
		}
	}

	&.card--featured--event {
		display: flex;
		flex-direction: column;

		.card__featured__footer {
			margin-top: 2rem;
			flex-grow: 1;
			display: flex;
			justify-content: center;
			align-items: end;
		}
	}



	&.card--full {
		display: flex;
		border: none;
		box-shadow: none;
		position: relative;
		z-index: 1;
		margin-left: 0;
		margin-right: 0;
		width: 100%;

		.card__content {
			flex-basis: 50%;
			flex-direction: column;
			background-repeat: no-repeat;
			background-position: 50%;
			flex-grow: 1;
		}

		.card__body {
			flex: 1;
			position: relative;
			padding: 4rem;

			h3 {
				// text-align: center;
				font-size: 2.4rem;
			}
		}

		.card__image {
			flex-grow: 1;
			display: flex;
			position: relative;
			flex: 1 1 50%;
			background-size: cover;
			align-items: center;
			justify-content: center;

			img {
			}
		}

		.card__image--overlay {
			max-height: 45rem;
			display: block;
			width: 100%;
		}

		h2 {
			position: relative;
			margin-bottom: 3rem;

			&:after {
				height: .2rem;
				background-color: white;
				content: "";
				position: absolute;
				left: 0;
				top: -1rem;
				width: 4rem;
			}
		}
	}

	&.card--facet {
		background: white;
		padding: 0 1rem;
		border-radius: 0.5rem;
	}

	&.card--topic {
		background: white;
		padding-bottom: 4rem;

		.card__icon {
			position: absolute;
			bottom: 0;
			right: 0;
			background-color: $light-blue;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 4rem;
			width: 4rem;
			color: white;
		}
	}
	// Themes
	@each $color, $var in $color-list {
		&.#{$color} {
			background: #{$var};
			color: white;
		}
	}

	&.card--video {
		background: 0 0;
		border: none;
		box-shadow: none;

		.video__link {
			display: block;

			img {
				opacity: 1;
			}

			&:hover,
			&:focus {
				.embed-responsive:before {
					opacity: 0.6;
					transition: opacity 400ms;
				}
			}
		}

		.embed-responsive:before {
			content: "";
			background-color: black;
			opacity: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 0;
			transition: opacity 400ms;
		}
		/*.embed-responsive:after {
			@include icon('ion-play');
			padding: 0;
			border: none;
			z-index: 1;
			font-size: 3.8rem;
			height: 5rem;
			width: 5rem;
		}*/
		.card__label,
		.card__icon {
			position: absolute;
			padding: .4rem;
			z-index: 2;
			background-color: $orange;
			color: white;
		}

		.card__label {
			top: 0;
		}

		.card__icon {
			bottom: 0;
			display: block;
			right: 0;
			width: 3rem;
			text-align: center;
		}
	}

	&.card--featured--video {
		margin-bottom: -15px;
	}

	&.card--post {
		background: white;
		border-top-color: $blue;
	}

	&.card--report {
		border-top-color: $red;
		padding-bottom: 1rem;
		transition: $transition--all;

		.card__tag {
			text-align: center;
			color: $red;
			width: auto;
			display: block;
		}

		h3 {
			text-align: center;
			margin-bottom: 2rem;
		}

		.card__image {
			display: block;
			margin: auto;
			max-width: 40rem;
		}

		.card__percent {
			text-align: center;

			.percent__number {
				font-size: 8rem;
				color: $red;
				font-weight: bold;
			}
		}
	}

	&.card--infographic {
		text-align: center;
		padding: 0 2rem;
		border: none;
		background: white;

		span {
			display: block;
		}

		.card__image {
			margin-left: -2rem;
			margin-right: -2rem;

			img {
				width: 100%;
			}
		}
	}

	&.card--narrow {
		justify-content: space-around;
		background-position: center;
		background-repeat: no-repeat;

		.card__content {
			flex: 0 1 40%;
			align-self: center;

			h1, h2, h3, h4, h5, h6, h7 {
				margin-top: 0;
			}
		}

		.card__image {
			flex: 0 1 40%;
			background-position: center;
		}
	}
}

.gallery-slider {
	.card {
		min-width: 20rem;
		&.card--bare .card__header {
			position: relative;
			display: block;
			height: 0;
			padding: 0;
			overflow: hidden;
			padding-bottom: 56.25%;
			min-height: 0;
			margin: 0;
			width: 100%;
		}
		&.card.card__pattern {
			height: 100%;
			color: white;
			padding: 2rem;
			border: none;
			img {
				max-width: 20rem;
				padding: 2rem;
				display: block;
				margin: auto;
			}
		}
	}
}


/* Flex Box Overrides */

@include break(max, $screen-xs-max) {
	@for $i from 1 through 12 {
		.card.col-xs-#{$i} {
			$calc: ($i/12) * 100%;
			flex-basis: calc(#{$calc} - 3rem);
			max-width: 40rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

@include break(min, $screen-sm-min) {
	@for $i from 1 through 6 {
		.card.col-sm-#{$i} {
			$calc: ($i/12) * 100%;
			flex-basis: calc(#{$calc} - 3rem);
		}
	}
}

@include break(min, $screen-md-min) {
	@for $i from 1 through 6 {
		.card.col-md-#{$i} {
			$calc: ($i/12) * 100%;
			flex-basis: calc(#{$calc} - 3rem);
		}
	}
}

@include break(min, $screen-lg-min) {
	@for $i from 1 through 6 {
		.card.col-lg-#{$i} {
			$calc: ($i/12) * 100%;
			flex-basis: calc(#{$calc} - 3rem);
		}
	}
}


// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝
@include break(min, $screen-md, max, $screen-lg) {
    .card.card--full .card__body {
        font-size:1.3rem
    }
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝\
@include break(max, $screen-xs) {
    .card.card--full .card__content {
        background-size: cover;
    }
}

@include break(max, $screen-xs-max) {
	.cards .card {
		margin: 0 0 1rem;
	}

	.card.card--video.col-md-3 {
		display: block;
	}

	.card.card--topic {
		padding-bottom: 2rem;
		min-height: 26rem;
		margin-bottom: 2rem;
	}
    
	.card__slider .slider__control {
		&.next {
			right: 0;
		}
		&.prev {
			left: 0;
		}
	}
	.card .card__body {
		h2 {
			line-height: 1.2;
		}
	}
}

@include break(max, $screen-md-max) {
     // Cards Section - Vue Component
	.cards .card {
		box-shadow: none;
		h3 {
			font-size: 1.6rem;
			margin: 1rem 0 1rem;
		}
		.card__tag {
			padding: 1rem 0;
		}
		.card__date,
		.card__text {
			display: none;
		}
	}

	.cards {
		position: relative;

		.interior-nav {
			overflow-x: auto;
			overflow-y: hidden;
			padding: 0;
			margin: .5rem 0;
			&:after {
				content: "";
				width: 2em;
				right: 0;
				z-index: 2;
				height: 6rem;
				position: absolute;
				pointer-events: none;
				box-shadow: inset -3em 0 2em -3em rgba(0, 0, 0, .7);
				transition: all .3s ease-in-out;
			}
			.interior-nav__item {
				margin: 0 .6rem;
				min-height: 6rem;
				padding: 0;
				background: linear-gradient($off-white, #eee);
				min-width: 20%;
				border: none;
				a {
					height: 100%;
					width: 100%;
					display: flex;
					padding: .5rem;
					align-items: center;
					justify-content: center;
					border-left: 2px solid;
				}
			}
		}
	}
}
@include break(max, $screen-xl) {
    .card.card--full {
        & .card__body {
           padding: 2rem;
        } 
        h2 {
            margin-bottom: 0rem;
            margin-top: 1.5rem;
        }
    }
}


@include break(max, $screen-sm-max) {
	.card.card--full {
		display: block;
		height: auto;
        max-width: 72rem;
		background-size: cover;
		margin: auto;
	}

    
    .card.card--narrow {
        & .card__image {
            padding-bottom: 5rem;
        }
    }
}

@include break(max, $screen-sm-max) {
	.cards .interior-nav .interior-nav__item {
		min-width: 42%;
	} // .card {
	// 	margin: 0;
	// 	margin-bottom: 4rem;
	// }
}



// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝
@include break(min, $screen-md-min) {

    .cards .interior-nav {
        justify-content: center;
    }
}
