.testimonial {
	display: flex;
	padding: 1rem;
	max-width: 76rem;
	margin: auto;
	align-items: center;
	
	.testimonial__figure {
		text-align: center;
		margin-left: 6rem;
		h3,
		p {
			margin: 0;
		}
		h3 {
			color: $purple;
		}
		img {
			margin: 1rem;
			overflow: hidden;
			border-radius: 100%;
			max-width: 20rem;
		}
	}
	.testimonial__quote .quote--open,
	.testimonial__quote .quote--close {
		width: 3rem;
	}
	.testimonial__quote .quote--open {
		display: inline-block;
		position: absolute;
		left: -4rem
	}
	.testimonial__quote .quote--close {
		margin-left: 1rem;
		display: inline-block;
	}
	.testimonial__quote {
		position: relative;
		p {
			display: inline;
			font-size: 2rem;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max,
$screen-sm-max) {
	.testimonial {
		padding: 4rem;
		padding-bottom: 2rem;
		flex-direction: column;
		margin: 0;
		.testimonial__figure {
			margin-left: 0;
			margin-top: 4rem;
		}
		.testimonial__figure img {
			display: none;
		}
	}
	
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
// @include break(min,
// $screen-md-min) {}