.IE-11 {
	.col-sm.card.card--price {
		flex-basis: calc(25% - 6rem);
	}
	.video__background {

		display: none;
	}
	@include break(min, $screen-md-min, min, $screen-md-max) {
		.card.col-md-6 {
			flex-basis: calc(50% - 12rem);
		}
	}
	.partner__list {
		display: block;
	}
	.hero {
		/*height: 40rem;*/
		.hero__content {
			flex-grow: 1;
		}
	}
	.report__item{
		flex-basis: calc(50% - 9rem);
        &.wide {
            flex-basis: calc(75% - 9rem);
        }
	}
	.hero--interior.hero {
		/*height: 40rem;*/
		display: flex;
	}
	@include break(min, $screen-sm-min) {
		@for $i from 1 through 6 {
			.card.col-sm-#{$i} {
				$calc: ($i/12) * 100%;
				flex-basis: calc(#{$calc} - 7rem);
			}
		}
	}

	@include break(min, $screen-md-min) {
		@for $i from 1 through 6 {
			.card.col-md-#{$i} {
				$calc: ($i/12) * 100%;
				flex-basis: calc(#{$calc} - 7rem);
			}
		}
	}
	.card.card--full {
		width: 100%;
		min-height: 0;
	}
}

.Edge-14 {
	.article.article--video {
		background-color: black;
	}
}

[class*="Safari-"],
.IE-11 {
	.container:before,
	.container:after,
	.row:before,
	.row:after,
	.block_row:before,
	.block_row:after {
		content: normal // IE doesn't support `initial`
	}
}



/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {

	.Safari-6,
	.Safari-7,
	.Safari-8,
	.Safari-9 {
		.block__row {
			display: block;
		}
		.research__list {
			.research__item {
				display: block;
			}
			.research__icon {
				margin: 2rem auto;
			}
		}
		.partner__list,
		.partner__list .partner__logo {
			text-align: center;
			display: block;
		}
		.presenters [class*=col-sm].presenter {
			opacity: 1;
			max-width: calc(50% - 5rem);
		}
		// .video__container .container{
		// 	display: block;
		// }
	}
	.Safari-9 {
		.sponsor {
			flex-basis: calc(50% - 1px);
		}
	}
}




/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}