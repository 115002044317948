.docList
{
	padding-bottom: 15px;	
}

.docList ul
{
	position: relative;
	top: 10px;	
}
