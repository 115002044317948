/*breadcrumbs*/
.breadcrumb{
	background-color: transparent;
	border-radius: 0;
	margin-top: 2rem;
	margin-bottom: 0;
	padding: 1rem 0 0;
	display: block;
	&.breadcrumb--mb{
		margin-bottom: 1.5rem;
	}
	a{
		color: $laser-blue;
	}
}
.breadcrumb>li+li:before {
    content: ">";
    font-size: 1.4rem;
}

/* Potential buttonish style for home icon
.breadcrumb li:first-child > a {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 1rem;

    &:hover {
        background: #ddd;
    }
} */



// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

@include break(max, $screen-xs-max) {
    .breadcrumb li {
        max-width: 18.5rem;
        white-space: nowrap;
        > a {
            display: inherit;
            max-width: 17rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }
}

@include break(max, $screen-xs) {
    .breadcrumb li {
        max-width: 14rem;
        /*display: none;*/

        > a {
            max-width: 13rem;
        }

        /*&:nth-last-child(-n+3) {
            display: unset;
        }

		&:nth-last-child(1) {
            display: none;
        }*/
    }
}

@include break(max, 375px) {
    .breadcrumb li {
        max-width: 11rem;
        > a {
            max-width: 10rem;
        }
    }
}