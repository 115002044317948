.schedule {
	border: 1px solid $off-white;
	background-color: white;
	padding: 0;
	padding-bottom: 2rem;
	.schedule__navigation {
		display: flex;
		background: $off-white;
	}
	.schedule__link {
		flex-grow: 1;
		height: 8rem;
		@include heading;
		font-size: 2.6rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $light-grey;
		color: $text-slate;
		i {
			padding-right: 1rem;
		}
		&:focus,
		&:hover {
			text-decoration: none;
			&:not([selected="true"]){
				background-color: #eee;
        		border: 1px #84bd00 solid;
			}
		}
		&[selected="true"] {
			color: white;
			position: relative;
			cursor: default;
			pointer-events: none;
			background-color: $light-green;
		}
	}
}


.schedulePrint
{
	h1, h2, h3, h4
	{
		font-family: "Open Sans",sans-serif;
	}

	h2
	{
		font-size: 2.4rem;	
	}

	h3
	{
		font-weight: normal;
		font-size: 1.6rem;
		margin-top: 1rem;
	}

	p
	{
		line-height: 1;	
	}
}

.events__list {
	padding: 0;
	margin: 0;
}

.event__item {
	display: flex;
	margin: 3rem 2rem;
	.event__date {
		display: flex;
		flex-flow: row;
		flex-grew: 1;
		flex-basis: 25%;
		padding-right: 1rem;
		padding-left: 1rem;
		border-right: 1px solid $border-grey;
		justify-content: center;
		text-align: center;

		div
		{
			display: block;
			width: 33%;
			margin: auto;
		}
	}
	.event__theme {
		font-style: italic;
	}

	.event__full {
		flex-basis: 100%;
	}

	.event__row {
		flex-flow: column;
	}

	.event__right {
		justify-content: left;
		text-align: left;
	}

	.event__left {
		justify-content: right;
		text-align: right;
	}

	.event__day {
		font-size: 3.0rem;
		@include heading;
	}
	.event__month {
		font-size: 1.8rem;
		display: block;
	}
	.event__content {
		flex-grow: 1;
		flex-basis: 70%;
		padding-left: 1.5rem;
		h3,
		p {
			margin: 1rem 0;
		}
	}
}

.event__featured {

	h3
	{
		margin-top: 0;	
	}

	.event__details
	{
		margin-bottom: .4rem;	
	}
}

.event-link {
	font-weight: bold;
	i {
		color: $light-blue;
	}
	img {
		width: 3rem;
	}
	@include unlink;
}

.event-nav {
	margin-bottom: 4rem;
}

.button.event-nav__button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.event--sort {
	min-height: 150px;

	.events__list {
		display: flex;
		flex-wrap: wrap;
		.event__item {
			flex-basis: calc(33% - 4rem);
			p {
				font-size: 90%;
				line-height: 1.2;
				margin: 0 0 0.8rem;
			}
		}
		.event__date {
			justify-content: flex-start;
			color: $dark-blue;
		}
	}
	.article__header {
		margin: 0;
		text-align: left;
		max-width: none;
		display: flex;
		align-items: center;
		h2 {
			flex-grow: 1;
            position: relative;
		}
		h2:after {
		    bottom: -1.2rem;
			left: 0;
		}
		.icon {
			max-width: 12rem;
			margin-right: 2rem;
			margin-left: -4rem;
		}
		.select__sort {
			align-items: center;
			display: flex;
			font-weight: bold;
			.label--select select {
				padding: 1.4rem;
			}
		}
	}
	.event__back-to-nav {
		float: right;
		font-weight: bold;
		text-decoration: none;
		i {
			color: $grey;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██�███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-xs-max) {}

@include break(max, $screen-sm-max) {

    // Show featured event on top
    .event__row :nth-child(2)[class~="event__featured"]{
        order: -1;
    }


	.schedule {
		display: block;
		margin-top: 2rem;
		.schedule__navigation {
			display: block;
		}
		.schedule__link {
			padding: 1rem;
			height: 6rem;
			font-size: 2rem;
			h3 {
				margin-top: 0;
			}
			&:after {
				display: none;
			}
		}
	}
	.events__list {
		.event__item {
			margin: 2rem 0;
		}
		.event__month {
			font-size: 1.4rem;
		}
		.event__day {
			font-size: 3rem;
		}
		.event__date {
			flex-basis: 40%;
		}
		.event__content {
			flex-basis: 60%;
		}
	}
	nav.event-nav {
		display: none;
	}
	.select__nav {
		.label--select {
			width: 100%;
			margin: 0;
			font-size: 2rem;
			select {
				border-radius: 0;
				border-color: #ddd;
				padding: 1rem;
			}
		}
	}
	.event--sort {
		.select__sort {
			margin-bottom: 2rem;
		}
		.events__list {
			flex-direction: column;
			.event__item {
				flex-basis: initial;
			}
		}
		.article__header {
			display: block;
			width: 100%;
			.icon {
				display: none;
			}
			h2 {
				display: inline-block;
				margin: 2rem 0;
				margin-bottom: 4rem;
			}
			h2:after {
				left: 0;
			}
		}
	}
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝
@include break(min, $screen-md-min, max, $screen-md-max) {
	.event--sort  .events__list  .event__item {
		flex-basis: calc(50% - 4rem);
	}
}
@include break(min, $screen-md-min) {
	.event--sort {
		.events__list {
			margin-left: 6rem;
		}
	}
	.select__nav {
		display: none;
	}
}
