.taxonomy__list{
	@include unlist;
	display: flex;
	.taxonomy__item{
		// display:inline-flex;
		padding: 0 1rem;
		margin-top: 2rem;
		margin-bottom: -3rem;
		border-right: 1px solid;
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			border:none;
		}
	}
}
.taxonomy__home{

	width: 100%;
	min-height: 65px;

	ul
	{
		justify-content: center;
		align-items: center;
	}

	a
	{
		color: $light-blue;	
	}
}
