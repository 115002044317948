/*nav utility*/

$utility-height: 13rem;

a.regionLink {
    display: flex;
    align-items: center;

    .icon { 
        font-size: 2.5rem;
        margin-left: 1rem;
    }
}

.utility {
	transition: $transition--all;
	background-color: white;
	height: $utility-height;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 6;
	transition: all .3s ease-in-out;
	.container {
		justify-content: flex-start;
	}
	.utility__upper {
		padding: 1rem;
		color: white;
		height: $utility-height / 2;
		background-color: $dark-blue;
		a {
			color: white;
		}
	}
	.utility__section {
		align-self: center;
		display: flex;
		padding: 0 1rem;
        &.region {
            margin-right: auto;
        }
        &.utility__create {
            text-transform: uppercase;
        }
	}

	.utility__lower {
		display: flex;
		height: $utility-height / 2;
		.utility__logo img {
			height: 8rem
		}
	}
	.utility__dropdown>ul {
		width: 20rem;
	}
	&.utility--footer {
		position: static;
		background-color: turquoise;
		.utility__item>a {
			color: white;
		}
		.utility__dropdown {
			.js-dropdown-trigger,
			.js-dropdown-trigger i {
				border-color: white;
				color: white;
			}
			.dropdown__item.utility__item>a {
				color: $grey;
			}
		}
	}
	a {
		color: $light-blue;
		font-weight: bold;
		font-size: 1.3rem;
		&.button {
			color: white;
		}
	}
	ul {
		list-style: none;
		display: flex;
		padding: 0;
		margin-bottom: 0;
		li {
			padding: 0 1rem;
			display: flex;
			align-items: center;
		}
	}
	.utility__social {
		.social-links {
			font-size: 1.6rem;
			a {
				text-decoration: none;
				display: inline-block;
				color: #d6d6d6;
				padding: 0 1rem;
				text-align: center;
				line-height: 3rem;
				transition: $transition--all;
				&:focus,
				&:hover {
					color: white;
					transition: $transition--all;
				}
			}
			i {
				font-size: 1.8rem;
			}
		}
	}
	.search {
		width: 25%;
		text-align: right;
		padding: 1rem;
		position: relative;
		display: flex;
		align-items: center;
	}

	.search__wrapper {
		input.search__input {
			width: calc(100% - 3rem);
			border-radius: $border-radius 0 0 $border-radius;
			border-right: none;
			&::-ms-clear {
				display: none;
			}
			&::-webkit-input-placeholder,
			&:-moz-placeholder,
			&::-moz-placeholder,
			&:-ms-input-placeholder {
				color: $grey;
			}
		}
		a.search__submit {
			// float: left;
			display: block;
			width: 3rem;
			border: 1px solid $border-grey;
			border-left: none; // height: 3.4rem;
			cursor: pointer;
			text-align: center;
			border-radius: 0 $border-radius $border-radius 0;
			i {
				font-size: 2rem;
				line-height: 1.6;
				text-align: center;
			}
		}
	}
	.search__form {
		display: flex;
		margin: 0;
		padding: 0;
	}
	.search__close {
		width: 2rem; // height: 3rem;
		font-size: 2.3rem;
		display: block;
		float: right;
		text-align: center;
	} // Hide placeholder when active in Chrome
	.gn-search:focus::-webkit-input-placeholder {
		color: transparent;
	}

	.search__input:focus,
	.search__submit:focus {
		outline: none;
	}
}

.utility__slogan {
	width: 18rem;
	font-size: 1.2rem;
	line-height: 1.8;
}

.utility__buttons .buttons,
.utility .button {
	max-width: 100%;
	width: auto;
	margin: 0;
}

.socialInMobileNav {
	display: none;
}

.utilityActionsInMobileNav {
	display: none;
	flex-direction: column;
	.button {
		.navigation & {
			font-size: 1.2rem;
			margin: 1rem;
			justify-content: center;
			align-items: center;
			flex-basis: 50%;
			width: inherit;
			margin: .75rem;
		}
		.icon {
			.navigation & {
				position: relative;
				left: -.3rem;
			}
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
// Inbetween
@include break(max, $screen-lg) {}

@include break(max, $screen-sm-max) {
	.utility {
		height: auto;
		.utility__list,
		.utility__social,
		.utility__buttons,
		.utility__slogan,
		.utility__create,
		.utility__login {
			display: none;
		}

		.utility__lower {
			display: none;
			height: auto;
			&[data-open="true"] {
				display: block;
			}
		}

		a.mobile__search, a.mobile__location {
			position: fixed;
			top: .6rem;
			z-index: 4;
			font-size: 4rem;
			color: $dark-blue;
			text-decoration: none;
			width: 5rem;
			display: flex;
			align-items: center;
			height: 5rem;
			justify-content: center;
			text-align: center;
		}

        a.mobile__search {
			right: 6rem;
			&:after {
				transform: rotate(0deg);
				@include icon("ion-ios-search");
				transition: $transition--all;
			}
			&[data-on="true"]:after {
				transform-origin: center;
				transform: rotate(180deg);
				transition: $transition--all;
				@include icon("ion-android-close");
			}
        }

        a.mobile__location {
			left: 14rem;
			&:after {
				@include icon("ion-android-globe");
			}
        }

		.search__wrapper a.search__submit i {
			font-size: 3rem;
			line-height: 1.2;
		}
		.search__form {
			height: auto;
			.search__submit {
				height: 4rem;
			}
			.search__input {
				height: 4rem;
			}
		}
		.utility__upper {
			background-color: white;
			border-bottom: 1px solid #ddd;
			padding: 0;
		}
		.utility__section.utility__logo {
			display: none;
		}
		&.utility--mobile-open {
			background-color: #d9edf3;
		}
		.search {
			display: block;
			width: 100%;
		}
		.utility__lower {
			border-bottom: 1px solid #ddd;
			.utility__logo img {
				padding: 0;
				width: 9rem;
			}
		}
	}
	.socialInMobileNav {
		display: flex;
		flex-direction: row;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		padding-top: .5rem;
		padding-bottom: .5rem;
		.social-links__item {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			align-items: center;
			> a {
				html body & {
					display: block;
					text-align: center;
				}
			}
			html body & {
				border-bottom: none;
			}
			&:first-child {
				html body & {
					border-top: none;
					border-bottom: none;
				}
			}
			.icon {
				&:before {
					font-size: 2.4rem;
				}
			}
		}
	}

	.utilityActionsInMobileNav {
		display: flex;
		justify-content: center;
	}
	.utility .container {
		justify-content: flex-start;
		flex-grow: 1;
	}
	.utility .utility__section.mobile__logo {
        position: absolute;
        top: .2rem;
        left: 1.5rem;
        padding: 0;
        display: block;
        > img {
            height: 6rem;
        }
	}
	&.utility--scrolled {
		// height: 8rem;
		.utility__list {
			margin: 1rem 0;
			transition: $transition--all;
		}
	}
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝      
@include break(min, $screen-lg-min) {
	.utility {
		.utility__social ul li {
			padding: 0 .4rem;
		}
		a.mobile__search {
			display: none;
		}
		.utility__section.mobile__logo {
			display: none;
		}
		.search {
			flex-grow: 1;
			justify-content: flex-end;
		}
		.utility__buttons {
			justify-content: flex-end;
		}
		&.utility--footer .utility__dropdown .dropdown__item.utility__item>a {
			color: white;
		}
		.js-dropdown.utility__dropdown {
			.js-dropdown-trigger {
				display: none;
			}
			float: none;
			display: inline;
			padding: 0;
			ul {
				height: auto;
				opacity: 1;
				position: static;
				width: auto;
				display: inline;
				visibility: visible;
				max-height: inherit;
				background: transparent;
				color: inherit;
				box-shadow: none;
				li {
					float: left;
					padding: 1rem; // height: 5rem;
					display: table;
					width: auto;
				}
			}
		}
	}
}

//████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
//╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
// 	 ██║   ███████║██████╔╝██║     █████╗     ██║   
// 	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║   
// 	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║   
// 	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝   
@include break(min, $screen-md-min, max, $screen-md-max) {
	.utility .container {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
	.utility__section.utility__list {
		padding: 0;
	}
	.utility ul li {
		padding: 0 .4rem;
	}
	.utility__section.mobile__logo {
		display: none;
	}
}
