.table-data {
	display: flex;
	justify-content: space-between;
	margin-bottom: 3rem;
	&--max-w {
		max-width: 50rem;
	}
	&__col {
		text-align: center;
		&--text-right {
			text-align: right;
		}
	}
	&__info {
		margin-bottom: 1rem;
		font-size: 1.4rem;
	}
	&__bold {
		font-weight: 600;
	}
	&__member {
		font-family: $font-heading;
		font-size: 1.6rem;
		font-weight: 300;
	}
	&__desc {
		font-weight: 600;
		font-size: 1.8rem;
		p {
			margin-bottom: 0;
			line-height: 1.4;
		}
	}
}

.container--table {
	overflow-x: auto;
	.table {
		min-width: 80rem;
		table-layout: fixed;
        &.annuity_prods {
            border: 1px solid #ccc;
            td, th {
                padding: 1.5rem;
            }
        }
        & > tbody {
            > tr > th {
                background-color: #eee;
            }
            > tr:nth-child(2n+1) {
                background-color: #F5F5F5;
            }
        }
	}
}

// --------- Support for migrated prof dev content ---------
table {
    &.curriculum {
        margin: 10px auto 20px;
        border: 0;
        border-collapse: collapse;
        font-size: .9em;
        line-height: 1.2;
        width: 100%;

        td,th {
            color: #333333;
            border: 1px solid #aaaaaa;
            padding: 3px 4px 5px;
            vertical-align: top
        }
        tfoot td {
            background-color: #D2F3FF;
            text-align: center
        }
    }

    .required {
    background-color: #d4e5f0
    }

    .elective {
    background-color: #E9E9F5
    }

    .level1 {
    background-color: #D2F3FF
    }

    .level2 {
    background-color: #BADEEF
    }

    .level3 {
    background-color: #9ACFE9
    }

    .topics {
    text-align: center
    }

    .notecell {
    background-color: #f4f4f3
    }
}





// --------- END OF TEMPORARY COURSE TABLE STYLES ---------


// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

@include break(max, $screen-xs-min) {
	.table-data {
		&--max-w {
			flex-wrap: wrap;
		}
		&__col {
			&:first-child {
				.table-data__info {
					display: none;
				}
			}
		}
		&__info {
			margin-bottom: 3rem;
			font-size: 2rem;
			font-weight: 600;
		}
	}
}

@include break(max, $screen-xs-max) {
	.table-data {
		&--max-w {
			max-width: initial;
		}
		&__col {
			flex-basis: 100%;
			&--text-right {
				text-align: center;
			}
		}
	}
}