@media print {
	.video,
	.utility,
	.primary-nav,
	.share__social,
	.card__header,
	.footer,
	.button,
	.menu-text,
	.login__status,
	.login__content,
	.back-to-top{
		display: none !important;
	}
	.breadcrumb li {
		display: inline;
	}
	/*a:after {
		text-overflow: ellipsis;
		width: 200px;
		display: block;
		overflow: hidden;
	}*/
    // Hide bootstrap's link href output
    a[href]:after {
    content: none !important;
    }
	.main {
		margin-top: 0 !important;
	}
	.image--right,
	.image--left {
		float: none!important;
		padding: 2em 0 !important;
		clear: both !important;
		display: block !important;
	}
	.secondary-nav,
	.interior-nav {
		position: static !important;
	}
	hr {
		border-top: none !important;
		border-bottom: 1px solid #333 !important;
	}
	* {
		border: none !important;
	}
	.card {
		padding: 0 !important;
		margin: 0 !important;
	}
	.col--right {
		margin: 0;
	}
	.accordion{
		.accordion__panel {
			max-height: 42rem;
			opacity: 1;
		}
		.accordion__item .accordion__toggle {
			display: none;
		}
	}
}
