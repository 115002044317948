.carousel-indicators-item {
	border-color: #69625d !important;
	background-color:lightgrey !important;
}

.carousel-indicators {
	z-index:2;
	@media(min-width:992px){ 
		bottom: 60px;
	}
}

.carousel-control{
	width: 5% !important;
}

.carousel-inner{
	width: 100%; 
	height: 80%; 
	overflow: clip;
}