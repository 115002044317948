/*back to top*/
.back-to-top {
	position: fixed;
	z-index: 5;
	right: 0;
	width: 5em;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	font-weight: bold;
	color: turquoise;
	font-size: 2rem;
	transition: $transition--opacity;
	&:before{
		@include icon('ion-chevron-up');
		border: 3px solid;
		border-radius: 100%;
		padding: 1rem;
		display: block;
		width: 5rem;
		height: 5rem;
		margin: auto;
		margin-bottom: 0.5rem;
	}
	i.fa{
		font-size: 3rem;
	}
	span {
		display: block;
		font-size: 1.2rem;
		&::selection {
			background-color: transparent;
		}
	}
	&.back-to-top--SCROLLED {
		bottom: 5rem;
		cursor: pointer;
		visibility: visible;
		opacity: 0.6;
		transition: $transition--opacity;
		&:hover {
			opacity: 1;
			transition: $transition--opacity;
		}
	}
}
.IE-11, [class*="Edge"]{
	.back-to-top {
		color: black;
	}
}