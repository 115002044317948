.partner__list {
	display: flex;
	flex-direction: column;
	.partner__logo {
		padding: 2rem;
	}
	.partner__item {
		display: flex;
		flex-wrap: wrap;
		flex-grow: 1;
		justify-content: center;

        &.sponsors_exhibitors {
            margin-bottom: 3rem;
        }
	}
	.partner__header {
		text-align: center;
	}

    .sponsors_exhibitors .partner__logo {
        padding: 0;
        display: flex;
        flex-basis: 20%;
        justify-content: center;
    }

    .sponsors_exhibitors .partner__bio {
        padding: 0 4rem;
    }

    .partner__bio {
        flex-basis: calc(80% - 8rem);
    }
}



/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.partner__list,
	.partner__list .partner__item,
	.partner__list {
		min-height: 100%;
		flex:inherit;
	}
	.partner__list .partner__item{
		flex-wrap: wrap;
	}

    .partner__list 
    {   
        .sponsors_exhibitors .partner__logo {
            padding: 2rem;
            display: flex;
            flex-basis: 100%;
        }

        .partner__bio {
            flex-basis: 100%;
        }
    }
}




/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
