$article-padding-top:4rem;
$article-padding-bottom:6rem;

@mixin article-padding {
	padding-top: $article-padding-top;
	padding-bottom: $article-padding-bottom;
}

.eyebrow--dash {
    padding-right:5.2rem;
    position:relative;
    text-transform:uppercase;
    display:inline !important;
    font-weight:700;
    &:after {
        content:"";
        position:absolute;
        display:block;
        right:0;
        top:calc(50%);
        width:4.6rem;
        border-top:2px $sky-blue solid;
    }
    &:not(.video__eyebrow) {
        &:after {
            [class*="background--"] & {
                border-top: 2px solid #fff;
            }
        }
    }
}

/* Tooltip container */
.custom-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Tooltip text */
.custom-tooltip .custom-tooltiptext {
    visibility: hidden;
    width: 500px;
    background-color: #EEE;
    color: black;
    text-align: center;
    padding: 2rem;
    border: 1px solid #BBB;
    border-radius: 6px;
 
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .custom-tooltiptext {
    visibility: visible;
}

.word-break--break-word {
    word-break: break-word;
}

.padding-top-2 {
    padding-top: 2rem;
}

.padding-top-3 {
    padding-top: 3rem;
}

.padding-top-4 {
    padding-top: 4rem;
}

.max-width--full {
    max-width: 100%;
}

.padding-bottom-2 {
    padding-bottom: 2rem;
}

.page__header {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin-top: 0;
	margin-bottom: 2rem;
	.page__h1 {
		flex-grow: 1;
		margin: 0;
        flex-basis: 60%;
	}
	.header__content {
		flex-basis: 100%;
	}
}

a {
	color: $light-blue;
	&:hover,
	&:focus {
		color: $blue;
	}
}

// Secondary Link Styles (Not including buttons)
@each $color,
$var in $color-list {
	.#{$color} {
        a:hover:not([class~="button"]),
        a:focus:not([class~="button"]){
            color:#FFF;
        }
    }
}

.blue,
.dark-blue {
    a:not([class~="button"]) {
        color:$light-green;
    }
}

.text--center,
[class^="col-"].text--center {
	text-align: center;
}

.article {
	position: relative;
	@include article-padding;
	&.b-lazy {
		background-size: cover;
	}
	&.article--off-white {
		color: $text-slate;
	}
	&.article--overlap {
		margin-bottom: -14rem;
	}
	&.article--underlap,
	&.article--inner-nav,
	{
		padding-top: 10rem;
	}

	.article__date
	{
		margin-bottom: 0;
		position: relative;
		top: -10px;
		font-size: 1.3rem;
	}
}

article {
	@each $color,
	$var in $color-list {
		&.article--#{$color} {
			background-color: #{$var};
            @if $color == white {
                color: $text-slate;
            }
            @else if $color == off-white {
                color: $text-slate;
            }
            @else {
                color: white;
            }
		}
	}
}

.container.container--paper {
	box-shadow: 0 -0.6rem 0.1rem rgba(0, 0, 0, .2);
	background: white;
	margin-top: -10rem;
    padding-bottom: 4rem;
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
	&.hr--large {
		margin-top: -4rem;
		margin-bottom: 3rem;
		border-top: 1px solid $border-grey;
		border-bottom: $big-border solid $laser-blue;
	}
	&.hr--narrow {
		@include break(min, $screen-sm-min) {
			max-width: $container-sm;
		}
		@include break(min, $screen-md-min) {
			max-width: $container-md;
		}
		@include break(min, $screen-lg-min) {
			max-width: $container-lg;
		}
	}
	&.hr--grey {
		border-top: 1px solid $border-grey;
	}
}

i.icon {
	@each $color,
	$var in $color-list {
		&.#{$color} {
			color: #{$var};
		}
	}
}

.icon--large {
	width: 14rem;
	height: 14rem;
	padding: 2rem;
	margin: auto;
	border-radius: 100%;
	font-size: 4rem;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 100%;
	}
	span {
		font-size: 50%;
	}
}

[class^="col-"].col--border {
	border-right: 1px solid $border-grey;
	padding-right: 4rem;
	&+[class^="col-"] {
		padding-left: 4rem;
	}
}

[class^="col-"] {
	text-align: left;
}

.article__header--center,
.article--center {
	text-align: center;
}

.article__footer {
	padding: 4rem 0;
}

.section {
	&--padding-b {
		padding-bottom: 4rem;
	}
	&--grey {
		background: #eee;
	}
}

.section__row {
	// padding-bottom: 2rem;
}

.section__row.row {
    margin-left: 0;
    margin-right: 0;
}

.section--narrow {
	max-width: 96rem;
	margin: auto;
}

.section__footer {
	padding: 2rem 0;
}

.container--narrow {
	max-width: 120rem;
	margin: auto;
}

.container--extra-narrow {
    max-width: 96rem;
	margin: auto;
}

img {
	max-width: 100%;
	&.image--margin-top {
		margin: auto;
		margin-top: 3rem;
		display: block;
	}
}

.figure--right {
	float: right;
	max-width: 28rem;
    margin-left: 2rem;
	img {
		width: 100%;
	}
	figcaption {
		text-align: center;
		padding: 1rem;
	}
}

.aside {
	padding: 0 4rem;
	&--bordered {
		position: relative;
		min-height: 100%;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 4rem;
			border-left: 1px solid $grey;
		}
	}
}

.image--right {
	float: right;
	max-width: 38rem;
	padding-left: 2rem;
	padding-bottom: 1rem;
}

.image--left {
	float: left;
	max-width: 38rem;
	padding-right: 2rem;
	padding-bottom: 1rem;
}

.image--center {
	padding-bottom: 1rem;
	max-width: 38rem;
	margin: auto;
}

.section__header {
	h2:after {
		position: absolute;
		left: 0;
		top: -1rem;
		width: 4rem;
	}
}

.heading--icon {
	margin-top: 0;
	position: relative;
	.icon {
		display: inline-block;
		font-size: 4rem;
		color: $dark-blue;
		width: 4rem;
		position: absolute;
		top: 0;
		left: -4rem;
		background: transparent;
	}
}

h2.heading--icon {
	position: relative;
	margin-top: 4rem;
	&:after {
		height: .2rem;
		background-color: $blue;
		content: "";
		position: absolute;
		left: 0;
		top: -1rem;
		width: 4rem;
	}
	.icon {
		left: -6rem;
	}
}

.anchor {
    display: block;
    position: relative;
    top: -24rem;
    visibility: hidden;
}

.article__header,
.gallery__header,
.section__header {
	position: relative;
	@include clearfix;
	/*.anchor {
		display: block;
		position: relative;
		top: -24rem;
		visibility: hidden;
	}*/
	// a {
	// 	color: inherit;
	// }
	h2 {
		margin-top: 0;
		&:after {
			height: .2rem;
			background-color: $laser-blue;
			content: "";
		}
    }
	&.header--noline h2:after {
		content: none;
	}
}

.container--inner-nav {
	margin-top: 2rem;
}

.article__header {
	margin: auto;
	text-align: center;
	max-width: 72rem;
	margin-bottom: 3rem;
	h2:after {
		position: absolute;
		left: calc(50% - 5rem);
		bottom: 0rem;
		margin-bottom: 0;
		width: 10rem;
	}
	p {
		font-weight: bold;
        margin-bottom: 2.1rem;
	}
	&.header--left {
		text-align: left;
		max-width: none;
		h2:after {
			left: 0;
			bottom: initial;
			top: -1rem;
			width: 4rem;
		}
	}
	&.header--line-bottom h2:after {
		background-color: $laser-blue;
		top: inherit;
		bottom: 0;
	}
}

.arrow {
	position: absolute;
	width: 4rem;
	height: 4rem;
	left: calc(50% - 2rem);
	bottom: 2rem;
	z-index: 2;
	border: 2px solid;
	color: white;
	background-color: $blue;
	border-radius: 100%;
	text-align: center;
	line-height: 4.4rem;
	font-size: 2rem;
	&:after {
		display: block;
		height: 8rem;
		content: "";
		width: 1px;
		background-color: $blue;
		position: absolute;
		left: calc(50% - 1px);
	}
}

.anchor--ref {
	display: inline-block;
	padding-top: 12rem;
	margin-top: -12rem;
}

.section__content {
	h3 {
		font-family: $font-body;
	}
	br+br {
		display: none;
	}
}

.posts__list {
	padding: 0;
	list-style: none;
	margin-bottom: 0;
	.post__item {
		border-bottom: 1px solid #eee;
		padding: 2rem 0;
		.tag {}
		h3 {
			margin-top: 1rem;
		}
	}
}

.text--legal {
	font-size: 80%;
	color: #888;
	margin: 2rem 0 0;
    text-align: center;
}

// Pages
.category-landing {
	.article__header h2:after,
	.section__header h2:after {
		background-color: $light-green;
	}
}

.content-detail {
	.article__header h2:after,
	.section__header h2:after {
		background-color: $purple;
	}
}

.cta {
	&__sticky-top {
		position: fixed;
		padding: 0.5rem 0;
		right: 1.5rem;
		top: 20rem;
		border-top: 0.2rem solid $border-grey;
		border-bottom: 0.2rem solid $border-grey;
		z-index: 999999;
		font-size: 1rem;
		text-align: center;
		p {
			margin: 0;
		}
	}
	&__icon {
		border-radius: 50%;
		font-size: 2rem;
		background: $cherokee;
		text-align: center;
		width: 3rem;
		height: 3rem;
		display: block;
		margin: 0.4rem auto;
		p {
			line-height: 3rem;
			font-weight: bold;
		}
	}
	&__link {
		font-weight: bold;
		&,
		&:hover,
		&:focus {
			color: $light-blue;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-xs-max) {
	.image--right,
	.image--left {
		float: none;
		display: block;
		max-width: 100%;
		padding: 0;
		margin: 1rem auto;
	}
	.arrow {
		display: none;
	}
	.article__header {
		margin-bottom: 1rem;
	}
	.cta {
		&__sticky-top {
			display: none;
		}
	}
}

@include break(max, $screen-sm-max) {
	.header {
		background-color: white;
		.header__logo {
			display: none;
		}
	}
	.container.container--paper {
		margin-top: -1.6rem;
	}
	.article {
		padding-top: 1.6rem;
		padding-bottom: 3rem;
		.heading--icon {
			margin-top: 8rem;
			.icon {
				top: -7rem;
				left: 0;
			}
		}
		&.article--overlap {
			margin-top: 0;
		}
	}
	.article.article--top {
		margin-top: 0;
	}
	[class^=col-].col--border,
	[class^=col-].col--border+[class^=col-] {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
	img {
		max-width: 100%;
	}
	.cta {
		&__sticky-top {
			text-align: left;
			top: 7.5rem;
			right: 2.5rem;
		}
		&__wrapper {
			position: relative;
			padding-left: 3.5rem;
		}
		&__icon {
			position: absolute;
			left: 0;
			top: 0;
			margin: 0;
		}
	}
	.dropdown--mobile {
		.js-dropdown-trigger {
			background-color: $off-white;
			position: relative;
			display: block;
			padding: 1rem;
			margin-bottom: 2rem;
			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}
			.heading--icon i {
				display: none;
			}
			h3 {
				margin: 0;
				padding: 0;
			}
			&:after {
				position: absolute;
				content: "";
				height: 0;
				width: 0;
				right: 2rem;
				top: calc(50% - .5rem);
				border: 1rem solid transparent;
				border-top-color: $text-slate;
			}
		}
		.js-dropdown-menu {
			display: none;
		}
		&[data-open="true"] {
			.js-dropdown-menu {
				display: block;
			}
			.js-dropdown-trigger:after {
				top: calc(50% - 1.5rem);
				border-top-color: transparent;
				border-bottom-color: $text-slate;
			}
		}
	}
	.aside {
		padding: 0;
		&--bordered {
			&:before {
				display: none
			}
		}
	}
}

#toast_wrap {
    position: fixed;
    top: 90%;
    left: 15%;
    z-index: 1000;
    background: #ffc;
    padding: 5px;
    border: 1px solid #CCCCCC;
    text-align: center;
    font-weight: bold;
    width: 70%;

	background-color: $blue;
	color: white;
	border-radius: $border-radius;
    opacity: 0;
}

#toast
{
	position: relative;
	top: 25%;
	text-align: center;
}

.toast_animate
{
	-webkit-animation: fadeinout 6s ease forwards;
	animation: fadeinout 6s ease forwards;
}

@-webkit-keyframes fadeinout {
  50% { opacity: 1; }
}

@keyframes fadeinout {
  50% { opacity: 1; }
}



@include break(max, $screen-xs-max) {
    .figure--right {
        max-width: 35%;
        padding: 0;
        margin-left: 2rem;

        img {
            width: 100%;
        }

        figcaption {
            text-align: center;
            padding: 1rem;
        }
    }
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝

@include break(min, $screen-md-min) {
	// .header__logo {
	//   width: 20rem;
	//   padding: 1rem;
	//   position: absolute;
	//   height: 100%;
	//   display: flex;
	//   align-items: center;
	// }
	// // .primary-nav,
	// // .utility {
	// //   width: calc(100% - 20rem);
	// //   align-self: flex-end;
	// // }
	// &.header--SCROLLED {
	//   transition: box-shadow 600ms;
	//   box-shadow: 780px 0 0 0px white, -780px 0 0 0px white;
	// }
}
