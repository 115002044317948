// ***********************************************************************
// *------------------------ TYPOGRAPHY ---------------------------------*
// ***********************************************************************

$font-body: 'Open Sans', sans-serif;
$font-heading: 'Roboto', sans-serif;

@mixin heading{
	font-family: $font-heading;
	font-weight: 700;
}

$icons:IonIcons;

$big-border: 3rem;
$border-radius: .4rem;
// ***********************************************************************
// *--------------------------- COLORS ----------------------------------*
// ***********************************************************************

// Base Colors
$white: #FFFFFF;
$off-white: #F2F2F2;
$grey: #6F6F6F;
$light-grey: #ddd;
$med-grey: #bbb;
$black: #000000;
$white-translucent: rgba(255,255,255,0.4);
$black-translucent: rgba(0,0,0,0.6);
$cherokee: #fdda94;

// Text colors
$text-slate:#35424A;
$slate:#35424A;
$text-light:#444444 !default;

// Social Colors
$facebook-blue: #3b5998;                                  
$twitter-blue: #4099ff;

// limra Colors
$blue: #01B5F0;
$dark-blue: #012557;
$light-blue: #368ED8;
$sky-blue: #009FDC;
$tertiary-blue: #0d3d79;
$orange: #ED8B00;
$light-orange: lighten($orange, 10%);
$light-green: #84BD00;
$green: #2FA95E;
$red: #D01947;
$laser-blue: #00ccff;
$purple:#4C2E88;
$white: #FFFFFF;

// official limra brand colors (see LIMRA_BrandColors pdf)
$limra-light-blue: #00B5EF;
$limra-blue: #006E9F;
$limra-dark-blue: #00437B;
$limra-light-green: #82C341;
$limra-green: #00AB4E;

// loma colors
$loma-blue: #0070C9;
$loma-purple:#403597;
$loma-strong-blue: #00437B;
$loma-header-blue: #066CAC;
$loma-azure: #009FDC;
$loma-mid-blue: #006E9F;
$loma-white: #FFFFFF;
$loma-lavender:#594DBC;
$loma-medium-blue: #0069ad;
$loma-dark-blue: #00548a;
$loma-strong-blue: #108fcf;
$loma-ultramarine: #101080;
$loma-dove-gray: #666666;
$loma-smoke: #f4f4f3;
$loma-cloudy: #e9e9e9;
$loma-plum: #413199;
$loma-pine: #006a65;
$loma-header-blue: #066cac;

$color-list: (
    blue:$limra-blue,
	dark-blue:$limra-dark-blue,
	red: $red,
	light-blue:$limra-light-blue,
	off-white:$off-white,
	sky-blue:$sky-blue,
	purple:$purple,
	orange:$orange,
	light-green:$limra-light-green,
	slate:$slate,
	green:$limra-green, 
	azure:$loma-azure, 
	white:$loma-white, 
	loma-blue:$loma-blue,
	loma-mid-blue:$loma-mid-blue,
	loma-purple:$loma-purple,
	loma-lavender:$loma-lavender,
	loma-medium-blue:$loma-medium-blue,
	loma-dark-blue:$loma-dark-blue,
	loma-strong-blue:$loma-strong-blue,
	loma-ultramarine:$loma-ultramarine,
	loma-dove-gray:$loma-dove-gray,
	loma-smoke:$loma-smoke,
	loma-cloudy:$loma-cloudy,
	loma-plum:$loma-plum,
	loma-pine:$loma-pine
);

$limra-color-list: (
    blue:$limra-blue,
	dark-blue:$limra-dark-blue,
	light-blue:$limra-light-blue,
	light-green:$limra-light-green,
	green:$limra-green
);

// Borders
$border-grey: #ccc;

// Padding/Margins
$content-padding:3.6rem;
$p-spacing: 2rem;

// Some math to get container size then base blocks off them
// Its half the container size at each media query
$container-sm-half:37.5rem;
$container-md-half:48.5rem;
$container-lg-half:58.5rem;

$container-sm:75rem;
$container-md:97rem;
$container-lg:117rem;
$container-xl:144rem;

// Animations
$transition--opacity: opacity 300ms linear;
$transition--all: all 300ms ease-in-out;
$transition--all-fast: all 200ms;
$transition--slow: all 400ms;
