.callout {
	position: relative;
	background: $limra-dark-blue;
	color: white;
	display: flex;
	margin: 6rem 0;
	justify-content: center;

    @each $color,
	$var in $limra-color-list {
		&.#{$color} {
			background: #{$var};
		}
	}
	.callout__image{
		// margin-left: 12rem;
		margin-top: -3rem;
		margin-bottom: -3rem;
		position: relative;
		z-index: 1;
		flex-basis: 33%;
	}
	.callout__content{
		margin: 4rem;
		margin-left: 12rem;
		font-size: 2.6rem;
		align-items: center;
    display: flex;
    flex-basis: 33%;
    p{
    	line-height: 1.4;
    	margin: 0;
    }
	}
	.chevron__background {
		position: absolute;
		height: 100%;
		top: 0;
		right: 0;
		z-index: 0;
		opacity: 0.6;
	}
}



/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.callout{
		.callout__image{
			display: none;
		}
		.callout__content{
			margin: 4rem 2rem;
			flex-basis: 100%;
		}
	}
}