﻿/* LIMRA ICONS */
/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@font-face {
  font-family: 'limraiconfont';
  src: url('../font/limraiconfont.eot?36081397');
  src: url('../font/limraiconfont.eot?36081397#iefix') format('embedded-opentype'),
       url('../font/limraiconfont.woff?36081397') format('woff'),
       url('../font/limraiconfont.ttf?36081397') format('truetype'),
       url('../font/limraiconfont.svg?36081397#limraiconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'limraiconfont';
    src: url('../font/limraiconfont.svg?36081397#limraiconfont') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "limraiconfont";
  font-style: normal;
  font-weight: normal;
  color: #006e9f;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-spin5:before { content: '\e800'; } /* '' */
.icon-analytics:before { content: '\e801'; } /* '' */
.icon-industry-issues:before { content: '\e802'; } /* '' */
.icon-methodology:before { content: '\e803'; } /* '' */
.icon-spin1:before { content: '\e830'; } /* '' */
.icon-spin2:before { content: '\e831'; } /* '' */
.icon-spin3:before { content: '\e832'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-spin6:before { content: '\e838'; } /* '' */
.icon-key-findings:before { content: '\e839'; } /* '' */

a > i.icon {
    cursor: pointer;
}

html {
	font-size: 10px;
	-webkit-font-smoothing: antialiased;

    &:lang(ja)
    {
        > body {
        font-family: "MS UI Gothic";
        }
        > h1,h2,h3,h4 {
	        font-family: "MS UI Gothic";
        }
    }   
}

body {
	font-family: $font-body;
	color: $text-light;
	font-size: 1.6rem;
}

p {
	line-height: 1.8;
	margin-top: 0;
	margin-bottom: 1.6rem;
}

label.checkboxlist {
    font-size: 1.4rem;
    font-weight: 400;
}

/* Headings */

h1,
h2,
h3,
h4{
	font-family: $font-heading;
	font-weight: 700;
	margin-top: 1.5rem;
	margin-bottom: 1.2rem;
	line-height: 1.5rem;
}

h5,
h6 {
	// font-family: $font-body;
	font-family: $font-body;
	font-weight: 400;
	margin-top: 1.5rem;
	margin-bottom: 0.8rem;
	line-height: 1.5rem;
}

h1 {
	font-size: 4.242rem;
	line-height: 7rem;
	margin-top: 2.6rem;
	@include break(max, $screen-xs-max) {
		font-size: 3.242rem;
		line-height: 4rem;
		margin-top: 1rem;
	}
}

h2 {
	font-size: 3.2rem;
	line-height: 5rem;
	margin-top: 3rem;
}

h3 {
	font-size: 2.121rem;
	line-height: 3rem;
	margin-top: 3rem;
}

h4 {
	font-size: 1.686rem;
	line-height: 2.4rem;
}

h5 {
	font-size: 0.707rem;
}

h6 {
	font-size: 0.471333rem;
	font-weight: 700;
}

/* Tables */

table {
	margin-top: 1.5rem;
	border-spacing: 0px;
	border-collapse: collapse;
}

table td,
table th {
	padding: 5px;
	line-height: 33px;
}


/* Code blocks */

code {
	vertical-align: bottom;
}

@each $color,
$var in $color-list {
	.text--#{$color} {
		color: #{$var};
	}
}

.text--left {
	text-align: left;
}

.text--right {
	text-align: right;
}

.small {
	font-size: 85%;
	margin-bottom: .5rem;
}

.ol--styled {
	li {
		padding-top: .6rem;
		counter-increment: step-counter;
		&:before {
			content: counter(step-counter);
		}
	}
}

.ul--icons {
	li {
		&:before {
			font: {
				family: FontAwesome;
				size: 2rem;
			}
			position: absolute;
		}
		&.icon-location:before {
			content: "\f041";
		}
		&.icon-hours:before {
			content: "\f017";
		}
		&.icon-direction:before {
			content: "\f14e";
		}
		&.icon-contact:before {
			content: "\f1fa";
		}
	}
	address {
		font-style: normal;
	}
}
.ul--checkmark{
	li{
		padding-top: 1rem;
		&:before {
			font-size: 2rem;
			position: absolute;
			@include icon('fa-check');
		}
	}
}
.block-lg {
	@media screen and (min-width: 1200px) {
		display: block;
	}
}
.ul--split{
	max-width: 64rem;
	display: block;
	padding-left: 0;
	margin: auto;
	@include clearfix;
	li{
		float: left;
		width: calc(50% - 2rem);
		text-align: left;
		padding-left: 0;
		margin-left: 2rem;
	}
}

