.gallery {
	&--margin {
		margin: 5rem 0;
	}
	&__title {
		text-align: center;
		margin-bottom: 2.7rem;
	}
	&__bl {
		margin-bottom: 1rem;
	}
}

.container--gallery {
	position: relative;
}

.gallery__header {
	text-align: left;
	margin-bottom: 4rem;
}

.gallery-slider {
	visibility: hidden;
	&[loaded="true"]{
		opacity: 1;
		visibility: visible;
	}
	&[data-type="cards"] {
		overflow: hidden;
		.swiper-wrapper {
			flex-wrap: nowrap;
		}
		.swiper-slide {
			width: 26rem;
		}
	}
	&--margin-t {
		margin-top: 2rem;
	}
	&__imgwrap {
		&--max-w {
			max-width: 15.5rem;
			margin: 0 auto;
		}
	}
	&__img {
		width: 100%;
	}
	&__title {
		text-transform: capitalize;
        margin-bottom: 0.5rem;
	}
	&__text {
		font-size: 1.4rem;
		line-height: 1.6;
	}
	.swiper-wrapper {
		flex-wrap: wrap;
	}
	.swiper-slide {
		margin-bottom: 3rem;
	}
	&[data-cols="5"] {
		.swiper-slide {
			flex-basis: 20%;
		}
	}
	&[data-cols="6"] {
		.swiper-wrapper {
			justify-content: center;
		}
		.swiper-slide {
			flex-basis: calc(16.6% - 2rem);
            padding: 1rem;
		}
	}
}

/*
████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
	 ██║   ███████║██████╔╝██║     █████╗     ██║
	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║
	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║
	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝
*/

@include break(max, $screen-md) {
	.gallery-slider {
		&__imgwrap {
			&--max-w {
				margin: 0;
			}
		}
		&[data-cols="5"] {
			.swiper-slide {
				flex-basis: auto;
			}
		}

		&[data-cols="6"] {
			.swiper-wrapper {
				justify-content: initial;
			}
			.swiper-slide {
				flex-basis: auto;
                padding: 0rem;
			}
		}
		.swiper-wrapper {
			flex-wrap: initial;
		}
		.swiper-slide {
			flex-basis: auto;
			margin-bottom: 0;
			padding: 0;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

@include break(max, $screen-sm-max) {
	.swiper-button-next,
	.swiper-button-prev {
		display: none;
	}
	.gallery {
		&__title {
			margin-bottom: 1.5rem;
			text-align: left;
		}
	}
	.container--gallery {
		width: 100%;
		padding: 0;
		.swiper-wrapper{
			padding-left: 1rem;
		}
		&:before,
		&:after {
			content: "";
			width: 4rem;
			height: 100%;
			display: block;
			position: absolute;
			z-index: 2;
			top: 0;
		}
		&:before {
			background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			left:0;
		}
		&:after {
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			right:0;
		}
	}
}

/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {
	.gallery-slider[data-type="cards"] {
		// overflow: visible;
		.swiper-button-next,
		.swiper-button-prev {
			display: block;

		}
	}
	.swiper-button-next{
		right: -2rem;
	}
	.swiper-button-prev{
		left: -2rem;
	}
	.container--gallery .gallery-slider[data-type="cards"]{
		&:before,
		&:after {
			content: initial;
			width: 3rem;
			height: 100%;
			display: block;
			position: absolute;
			z-index: 2;
			top: 0;
		}
		&:before {
			background: linear-gradient(to right, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
			left: 15px;
		}
		&:after {
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%);
			right: 15px;
		}
	}
}