.hero {
	color: white;
	display: flex;
	position: relative;
	min-height: 50rem;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50%;
	background-color: $blue;
	.container {
		display: flex;
	}
	&.hero--event {
		justify-content: center;
		.hero__content {
			margin: auto;
			text-align: center;
			max-width: 96rem;
		}
	}
	.hero__tag a,
	.hero__title a {
		display: inline;
		background-size: 0px .2rem;
		background-position: 0px 98%;
		background-repeat: no-repeat;
		transition: all 0.2s ease-in-out;
		text-decoration: none;
		color: white;
		&:active,
		&:focus,
		&:hover {
			color: white;
			background-image: linear-gradient(to top, #8ce3ff 50%, #8ce3ff 50%);
			background-size: 100% .2rem;
		}
	}

	.breadcrumb {
		position: absolute;
		top: 11rem;
	}
	.container--info [class^="col-"] {
		text-align: center;
	}
	.container--hero {
		max-width: 64rem;
		p {
			margin-bottom: .4rem;
		}
		h1 {
			margin-top: 0;
		}
	}
	.hero__content {
		z-index: 2;
		position: relative;

		div
		{
			margin-top: 40px;	
		}

        &.row {
            min-height: 50rem;
            width: 100%;
        }
		h1,h2 {
			margin-top: 0;
			font-size: 2.8rem;
			font-weight: 500;
			line-height: 1.2;
		}
		p {
			// font-size: 2rem;
			line-height: 1.4;
		}
        h1,h2 {
			margin-top: 2rem;
			&.hero__title:before {
				width: 3rem;
				height: 2px;
				background-color: #fff;
				content: "";
				position: absolute;
				top: 1rem
			}
		}
		.hero__spotlight {
			figure {
				float: left;
				position: relative;
				margin-right: 2rem
			}

			figcaption {
				position: absolute;
				bottom: 0;
				padding: 1rem;
				background: #012557
			}
		}
		.hero__tag {
			padding: 0;
			display: block;
			height: 3rem;
			margin-top: -3rem;
		}
		figure {
			max-width: 50%;
			margin: 0 2rem;
		}
	} // Countdown Clock
	.clock__wrapper {
		width: 100%;
		margin: auto;
		text-align: center;
		min-height: 16rem;
	}
	.clock__segment {
		display: inline-block;
		padding: 2rem;
	}
	.segment__num {
		background-color: $orange;
		padding: 0;
		border-radius: 50%;
		width: 8rem;
		height: 8rem;
		text-align: center;
		font-size: 4rem;
		line-height: 8.4rem;
		margin-bottom: 1.4rem;
	}
	.segment__label {
		text-transform: uppercase;
		font-weight: bold;
	}
	// Hero -- Interior
	&.hero--interior {
		display: flex;
		border: none;
		box-shadow: none;
		position: relative;
		z-index: 1;
		min-height: 40rem;
		background-color: $light-green;
		.hero__content {
			// flex-basis: 50%;
			// flex-direction: column;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50%;
			flex-grow: 1;
			display: flex;
		}
		.hero__body {
			flex: 1;
			position: relative;
			padding: 4rem;
			h3 {
				text-align: center;
				font-size: 2.4rem;
			}
		}
		.hero__image {
			flex-grow: 1;
			display: flex;
			position: relative;
			// Image Width
			flex: 0 0 50%;
			background-size: cover;
			align-items: center;
			justify-content: center;
			img {
				// display: block;
			}
		}

		.hero__image--overlay {
			max-width: 36rem;
			display: block;
		}
		h1,h2 {
			position: relative;
			margin-bottom: 3rem;
			&:after {
				height: .2rem;
				background-color: white;
				content: "";
				position: absolute;
				left: 0;
				top: -1rem;
				width: 4rem;
			}
		}
	}
	// Hero -- Conference
	&.hero--conference{
		min-height: initial;
		padding: 2rem 1rem;
		background: $light-blue;
		background-position: center center;
        background-repeat: no-repeat;
		.container{
			display: block;
		}
		.hero__in{
			padding-bottom: 1.5rem;
		}
		.hero__title{
			margin-bottom: 1.5rem;
			letter-spacing: 0.05em;
		}
		.hero__subtitle{
			margin-bottom: 1.5rem;
			font-family: $font-heading;
			font-size: 2.8rem;
			font-weight: 300;
		}
		.hero__details{
			margin-bottom: 2rem;
		}
		.hero__date{
			margin-bottom: 0.3rem;
			text-indent: 0.2em;
			font-size: 1.8rem;
			font-weight: 600;
			letter-spacing: 0.02em;
		}
		.hero__place{
			font-size: 1.9rem;
		}
		.hero__image{
			display: block;
			max-width: 100%;
		}
		.hero__figure{
			text-align: center;
		}
		.hero__figcaption{
			margin-bottom: 1rem;
			font-size: 2.5rem;
			font-family: $font-heading;
			font-weight: 300;
		}
	}
	// Hero -- Chart
	$circleChartSize: 30rem;
	&.hero--chart{
		background-color: $red;
		.hero__image{
			flex: 0 0 50%;
		}
		.hero__content{
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 4rem;
			text-align: center;
			.hero__percent{
				font-size: 9.6rem;
				font-weight: 700;
			}
		}
	}
}

// Hero block styles
.hero--home {
    position: relative;
    flex-direction: column;
    padding-top: 4.8rem;
    padding-bottom: 9.6rem;
    background-color: #fff;

    @media(min-width:992px) {
        color: #fff;

        a {
            color: #fff;
        }
    }

    @media(max-width:991px) {
        color: $text-slate;
        padding-top: 0;
        padding-bottom: 4.8rem;

        .hero__body {
            padding-top: 2.4rem;
        }

        .hero__title a {
            color: $text-slate;

            &:hover,
            &:focus {
                color: $text-slate;
            }
        }

        .button--clear {
            color: $loma-blue;
            border-color: $loma-blue;
        }
    }
}

    
.limra__hero__black {
	color: black !important;
	@media(min-width:992px) {
		flex-basis: 43%;
	}
	@media(max-width:991px) {
		flex-basis: 100%
	}
}

.limra__hero {
	@media(min-width:992px) {
		flex-basis: 43%;
	}
	@media(max-width:991px) {
		flex-basis: 100%
	}
}

.bg-img-pseudo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        object-fit: cover;
        object-position: 50% 50%;
        min-width: 100%;
        min-height: 100%;
    }

    @media(max-width:991px) {
        position: relative;
    }
}
// End Hero block styles


// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

@include break(max, $screen-sm-max) {
	.hero {
		background-size: cover;
		min-height: 0;		
		padding-bottom: 2rem;
		.breadcrumb {
			position: static;
		}
		.hero__background-image {
			background: linear-gradient(135deg, royalblue 0, turquoise 100%) !important;
			min-height: auto;
			&:after {
				display: none;
			}
		}
		.hero__content {
			float: none;
			width: 100%;
			padding: 0;
			z-index: 1;
            figure {
                max-width: 35%;
            }
			.button {
				float: none;
				margin-top: 1rem;
				margin-left: 0;
			}
			.hero__tag {
				display: block;
				height: auto;
				margin-top: 2rem;
			}
            .hero__spotlight figure {
                float: right;
                position: relative;
            }
		}
	}
	.hero.hero--chart{
		.hero__image{
			display: none;
		}
		.hero__content{
			flex-basis: 100%;
			padding: 1rem;
		}
	}
}

@include break(max, $screen-xs-max) {
    .hero figure {
        display: none;
    }
}

@include break(max, $screen-xs-min) {
    .hero.hero--conference {
        background-size: cover;
    }
}


// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝

@include break(min, $screen-md-min) {}


/*
████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
	 ██║   ███████║██████╔╝██║     █████╗     ██║
	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║
	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║
	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝
*/

@include break(min, $screen-sm, max, $screen-lg) {
	.hero{
		.hero__content
		{
			h1:not(.hero__title),h2:not(.hero__title) {
				font-size: 1.8rem;
			}
		}
	}
}

@include break(max, $screen-md) {
	.hero{
		&.hero--conference{
			padding-top: 2rem;
			.hero__title{
				font-size: 2.8rem;
				line-height: 1.4;
			}
			.hero__subtitle{
				margin-top: 2rem;
				font-size: 2.5rem;
			}
		}
	}
}
