.banner {
	display: block;
	color: white;
	.banner__image {
		background-size: cover;
		display: block;
	}
	.banner__body {
        padding-top: 10rem;
        padding-bottom: 10rem;
		max-width: 80rem;
		text-align: center;
		margin: auto;
	}
}