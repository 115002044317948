html,
body {
	font-size: 10px;
}

body {
	backface-visibility: hidden;
	font-size: 1.6rem;
	color: $text-slate;
}

html[data-freeze="true"],
html[data-freeze="true"] body {

	overflow: hidden;
	position: relative;
	height: 100%;
}

ul li
{
	padding-bottom: 5px;
}

// *************************
// JAVASCRIPT
// *************************
// BLazy
.b-lazy {
	opacity: 0;
}

.b-loaded {
	opacity: 1;
	transition: opacity 200ms;
}

// forms
.required
{
	color: $red;	
}

// Dropdown
.js-dropdown {
	padding: 1rem;
	position: relative;
	>.js-dropdown-trigger {
		display: block;
		user-select: none;
		font-weight: bold;
		padding: .2rem .8rem;
		border-bottom: 1px dotted #6f6f6f;
		vertical-align: middle;
		text-decoration: none;
		&:focus,
		&:hover {
			text-decoration: none;
		}
	} // Closed Sub Nav
	>.js-dropdown-menu {
		display: none;
	} // Open Sub Nav
	&[data-open="true"]>.js-dropdown-menu {
		display: block;
	}
	&.dropdown--nested {
		padding: 1rem;
		margin: 0.5rem 0;
		background-color: rgba(165, 165, 165, 0.1);
		>.js-dropdown-trigger {
			font-size: 1.6rem;
			&:after {
				content: "+";
				font-family: monospace;
				padding-left: 1rem;
			}
		}
		>.js-dropdown-trigger,
		>.js-dropdown-menu {
			padding: 0;
			border: 0;
		}
		&[data-open="true"]>.js-dropdown-trigger:after {
			content: "-";
		}
	}
}

// Screen
.screen__overlay {
	position: fixed;
	top: 11rem;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	display: none;
	&[data-overlay="true"] {
		display: block;
		background: rgba(0, 0, 0, 0.4);
	}
}

[v-cloak] {
	visibility: hidden;
}

.main {
	padding-top: $utility-height;
	margin-top: $navigation-height;
	.main__header {
		padding-top: 12rem;
		background-color: $off-white;
		.breadcrumb li a {
			color: lightgreen;
		}
	}
}

.pill {
	border-radius: .6rem;
	padding: .6rem 1rem;
	margin-right: 1rem;
	display: inline-block;
	font-size: 1.4rem;
	&:hover,
	&:focus {
		text-decoration: none;
	}
	@each $color,
	$var in $color-list {
		&.pill--#{$color} {
			background-color: #{$var};
			border: 1px solid #{$var};
			color: white;
		}
	}
}

@each $color,
$var in $color-list {
	&.background--#{$color} {
		background-color: #{$var};
		border: 1px solid #{$var};
        @if $color == white {
            color: $text-slate;
        }
        @else if $color == off-white {
            color: $text-slate;
        }
        @else {
            color: white;
        }
	}
}

.list__labels-tags,
.tag__list
{
	@include unlist;
	margin-top: 2rem;
	margin-bottom: 1rem;
    font-size: 1.2rem;
	display: block;
	clear: both;
	float: none;
	>li {
        margin-right: 0.5rem;
		display: inline-block;
		.result__tag,
		.result__label {
			margin: 0;
		}
	}
	a,
	a:hover,
	a:focus {
		color: white;
	}
	.result__tag {
		background: $med-grey;
		border-radius: 0.5rem;
		display: inline-block;
		padding: 0.2rem 1rem;
		position: relative;
		margin: 0 1rem 1rem 0;
		max-width: 17rem;
        text-decoration: none;
		text-align: center;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
	}

	.result__label {
		background: $label-color;
		border-radius: 3px;
		display: inline-block;
		height: 3rem;
		line-height: 3rem;
		padding: 0 2.0rem;
		position: relative;
		margin: 0 1rem 1rem 0;
		text-decoration: none;
		min-width: 85px;
		text-align: center;
		color: #fff;
		border: 0 none;
	}
}

.tag {
	background-color: $red;
	text-transform: uppercase;
	padding: 0.4rem 1rem;
	margin-right: 1rem;
	display: inline-block;
	color: white;
	font-weight: bold;
	font-size: 1.2rem;
	&:hover,
	&:focus {
		text-decoration: none;
	}
	@each $color,
	$var in $color-list {
		&.tag--#{$color} {
			background-color: #{$var};
			border: 1px solid #{$var};
			color: white;
		}
	}
}

.icon--number,
.icon--info {
	display: inline-block;
	border-radius: 100%;
	background-color: red;
	color: white;
	width: 4rem;
	line-height: 4rem;
	height: 4rem;
	font-weight: bold;
	font-size: 2rem;
	text-align: center;
}

.link--image {
	display: inline-block;
	max-width: 15rem;
	margin-right: 1rem;
	margin-top: 1rem;
	img {
		width: 100%;
	}
}

.validation-summary-errors
{
	border: 2px solid $red;
	padding: 1.2rem;

	li
	{
		color: $red;
	}
}

@each $color,
$var in $color-list {
	.icon--#{$color} {
		background-color: #{$var};
		color: white;
	}
}

.simple-list {
	padding-left: 0;
	list-style-type: none;
	&__option {
		margin-bottom: 1rem;
		&--no-margin {
			margin-bottom: 0;
		}
	}
}

.divider {
	&--dark {
		border-color: $grey;
	}
}

.closeThis
{
	position: relative;
	top:-15px;
	left: -21px;
	background-color: black;
	color: white;
	border-radius: 100%;
	font-size: 1.1rem;
    cursor: pointer;

	i
	{
		padding-left: 4px;
		padding-right: 4px;
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
@include break(max, $screen-xs-min) {
	.mob-hidden {
		display: none;
	}
	.button.mob-hidden {
		display: none;
	}
	.mob-visible {
		display: block;
	}
	.text--mob-center {
		text-align: center;
	}
}

@include break(max, $screen-sm-max) {
	.main {
		padding-top: 0;
        margin-top: 6.5rem;
	}
	.screen__overlay {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		display: none;
		&.screen__overlay--on {
			display: block;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
	.tablet-visible {
		display: block;
	}
	.tablet-hidden {
		display: none
	}
	.button.tablet-hidden {
		display: none
	}
    .tag__list {
        font-size: 1.2rem;
    }
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝
@include break(min, $screen-md) {
	.desktop-hidden {
		display: none;
	}
	.dropdown--mobile {
		padding: 0;
		.js-dropdown-trigger {
			color: $text-slate;
			pointer-events: none;
			padding: 0;
			border: 0;
		}
		.js-dropdown-menu {
			display: block;
		}
	}
}
