.people{
	position: relative;
	margin: 2.5rem 0 5rem;
	.people__container{
		max-width: 117rem;
		padding: 0 1.5rem;
		margin: 0 auto;
	}
	.people__list{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.people__item{
		flex-basis: calc(25% - 2rem);
		margin-bottom: 3rem;
		padding: 0 1rem;
	}
	.people__preview{
		position: relative;
		display: block;
		width: auto;
		margin: 0 auto;
		color: inherit;
		&:hover,
		&:focus,
		&:active{
			text-decoration: none;
			color: inherit;
		}
		&:before{
			content: "\f104";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -0.9rem;
			font-size: 3rem;
			font-family: "Ionicons";
			color: $grey;
			line-height: 1;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s ease, visibility 0.2s ease;
		}
		&.is--active{
			&:before{
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.people__pic{
		max-width: 12rem;
		margin: auto;
		margin-bottom: 1rem;
	}
	.people__img{
		display: block;
		max-width: 100%;
	}
	.people__desc{
		display: block;
		min-height: 4rem;
		padding-left: 1.5rem;
	}
	.people__name{
		font-weight: 600;
		color: $light-blue;
		text-indent: -0.6em;
		display: block;
		padding-left: 1em;
		span{
			padding-left: 0.5rem;
		}
	}
	.people__bio{
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		opacity: 0;
		visibility: hidden;
		margin-top: 3rem;
		padding-bottom: 1.5rem;
		background: #eee;
		transition: opacity 0.2s ease, visibility 0.2s ease;
		&.is--active{
			opacity: 1;
			visibility: visible;
		}
	}
	.people__position{
		margin-bottom: 1.5rem;
		p{
			margin-bottom: 0;
		}
	}
	.people__avatar{
		max-width: 325px;
		margin: 4rem auto 2.8rem;
	}
	.people__close{
		position: absolute;
		z-index: 1;
		top: 1.5rem;
		right: 1.5rem;
		font-size: 2.5rem;
		color: $black;
	}
	.people__link{
		text-align: center;
		font-size: 2rem;
	}
	.keynote {
		&.people__item {
			flex-basis: calc(50% - 2rem);
		
			.people__pic {
				max-width: 32rem;
				max-height: 32rem;
			}
		}	
		.people__name {
			text-align: center;
		}
	}
}

/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/
@include break(max, $screen-xs-max) {
	.people{
		.people__item{
			flex-basis: 50%;
		}
		.people__preview{
			width: auto;
			max-width: 14rem;
			&:before{
				left: 2rem;
				margin-left: 0;
			}
		}
		.people__desc{
			padding-left: 0;
		}
		.keynote {
			.people__preview {
				max-width: 32rem;
			}
		}
	}
}
