.report{
	&--full-w{
		.report__list .report__link{
			flex-basis: 100%;
			padding-left: 0;
		}
	}
}

.report__item {
	display: flex;
	justify-content: space-around;
	flex-flow: row wrap;
	align-items: stretch;
	flex-basis: 50%;
	padding: 0 4rem;
	margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
	.report__header {
		flex: 1 100%;
	}
	.report__content {
		flex: 2;
		padding: 0 2rem;
		h3 {
			margin-top: 0;
		}
	}
    &.wide {
        flex-basis: 75%;
    }
}

.report__image {
	width: 30rem;
	margin-right: 2rem;
	height: auto;
	border-radius: 100%;
	font-size: 4rem;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	img {
		width: 100%;
	}
	span {
		font-size: 50%;
	}
}

.report__video {
	width: 30rem;
	margin-right: 2rem;
	height: auto;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}



.report__file{
	padding: 2rem 0 2rem 0;
	border: 1px solid $border-grey;
	.report__tag{
		text-transform: uppercase;
		font-size: 1.3rem;
		color: $red;
	}
}

.report__list{
	@include unlist;
	display: flex;
	flex-wrap:wrap;
	&--bordered{
		padding: 1rem 0;
		border-top: 1px dotted $grey;
		border-bottom: 1px dotted $grey;
        justify-content: center;
        margin-bottom: 2.1rem;
	}

	.report__link{
		/*flex-basis: calc(33% - 2rem);*/
		margin: 1rem 2rem;
		.icon{
			width: 1.6rem;
		}
		&--initial-w{
			flex-basis: auto;
		}
		a{
			font-weight: bold;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝



@include break(max, $screen-sm-max) {
	.report__item,
	.report__item:first-child {
		display: block;
		flex:inherit;
		.report__content{
			padding: 0;
			flex:inherit;
		}
	}

	.report__image {
		margin: auto;
		padding-bottom: 2rem;
	}
}

@include break(max, $screen-xs-max) {
	.report__list .report__link{
		/*flex-basis: 100%;*/
	}
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝

@include break(min, $screen-md-min) {
    .report__list .report__link.half-width {
        flex-basis: calc(50% - 4rem);
    }
}


/*
████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
	 ██║   ███████║██████╔╝██║     █████╗     ██║
	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║
	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║
	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝
*/

@include break(min, $screen-md-min, max, $screen-md-max) {}