.NewsBlock {
    nav {
        display: flex;
        justify-content: center;
    }

    .search-criteria {
        display: flex;
        flex-basis: auto;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;

        select {
            margin: 0 1rem;
        }
    }
}

.bordered-section {
    border: 1px solid #f2f2f2;
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝\
@include break(max, $screen-xs) {
    .NewsBlock .search-criteria {
        flex-wrap: wrap;
    }
}



    /*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/
    @include break(max, $screen-sm-max) {
}

