.share__social.share--page {
  display: flex;
  margin-top: 4rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 1;
  margin-top: 2rem;
  @include clearfix;
  margin:0;
  padding: 0;
  &.share--float {
    float: right;
  }
  .share__social-item {
    font-size: 1.6rem;
    a {
      text-decoration: none;
    }
    i {
      font-size: 2rem;
      // color: $blue;
    }
  }
}

.share--dropdown {
  &.js-dropdown.share--dropdown {
    .share__trigger {
      color: inherit;
      font-weight: bold;
      font-size: 1.4rem;
      border: none;
    }
    .share__ellipsis {
      font-size: 5rem;
      font-family: serif;
      display: inline-block;
      line-height: 0;
      opacity: 0.6;
      transform: rotate(0deg)
    }
  }
}

.share__social-item {
  display: block;
  i {
    // font-size: 2rem;
  }
  a {
    padding: 1rem;
    display: block;
    font-weight: 700;
    font-size: 1.3rem;
    color: $text-slate;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: lighten($text-slate, 20);
    }
  }
}


// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

@include break(max, $screen-sm-max) {
  .share__social.share--page{
  }
  .share__social.share--page.share--float {
    margin: 0;
    justify-content: flex-start;
    float: none;
    padding: 0;
  }
  .js-dropdown ul.share__social {
    position: fixed;
    top: calc(50vh - 10rem);
    left: calc(50vw - 10rem);
    width: 20rem;
    font-size: 18px;
    max-height: none;
    opacity: 0;
    visibility: hidden;
    .share__social-item a{
    	font-weight: normal;
    	font-size: 1.6rem
    }
    .share__social-item i{
    	width: 2rem;
    	display: inline-block;
    }
  }
  .js-dropdown[data-open=true] ul.share__social {
    opacity: 1;
    visibility: visible;
  }
  .js-dropdown[data-open=true] .js-dropdown-trigger:before,
  .js-dropdown .js-dropdown-trigger:before{
  	display: none;
  }
}

/*
████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
   ██║   ███████║██████╔╝██║     █████╗     ██║
   ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║
   ██║   ██║  ██║██████╔╝███████╗███████╗   ██║
   ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝
*/

@include break(max, $screen-md-min) {
  .share .share__social{
    display: flex;
    justify-content: initial;
    padding-top: 2rem;
  }
}


// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝

@include break(min, $screen-md-min) {}
