

/*
██╗ ██████╗ ██████╗ ███╗   ██╗███████╗
██║██╔════╝██╔═══██╗████╗  ██║██╔════╝
██║██║     ██║   ██║██╔██╗ ██║███████╗
██║██║     ██║   ██║██║╚██╗██║╚════██║
██║╚██████╗╚██████╔╝██║ ╚████║███████║
╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@mixin icon($iconName) {
  font-family: "Ionicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @if $iconName =="ion-user" {
  //   content: "\e005";
  // }
  @if $iconName=="ion-alert" {
    content: "\f101";
  }
  @if $iconName=="ion-alert-circled" {
    content: "\f100";
  }
  @if $iconName=="ion-android-add" {
    content: "\f2c7";
  }
  @if $iconName=="ion-android-add-circle" {
    content: "\f359";
  }
  @if $iconName=="ion-android-alarm-clock" {
    content: "\f35a";
  }
  @if $iconName=="ion-android-alert" {
    content: "\f35b";
  }
  @if $iconName=="ion-android-apps" {
    content: "\f35c";
  }
  @if $iconName=="ion-android-archive" {
    content: "\f2c9";
  }
  @if $iconName=="ion-android-arrow-back" {
    content: "\f2ca";
  }
  @if $iconName=="ion-android-arrow-down" {
    content: "\f35d";
  }
  @if $iconName=="ion-android-arrow-dropdown" {
    content: "\f35f";
  }
  @if $iconName=="ion-android-arrow-dropdown-circle" {
    content: "\f35e";
  }
  @if $iconName=="ion-android-arrow-dropleft" {
    content: "\f361";
  }
  @if $iconName=="ion-android-arrow-dropleft-circle" {
    content: "\f360";
  }
  @if $iconName=="ion-android-arrow-dropright" {
    content: "\f363";
  }
  @if $iconName=="ion-android-arrow-dropright-circle" {
    content: "\f362";
  }
  @if $iconName=="ion-android-arrow-dropup" {
    content: "\f365";
  }
  @if $iconName=="ion-android-arrow-dropup-circle" {
    content: "\f364";
  }
  @if $iconName=="ion-android-arrow-forward" {
    content: "\f30f";
  }
  @if $iconName=="ion-android-arrow-up" {
    content: "\f366";
  }
  @if $iconName=="ion-android-attach" {
    content: "\f367";
  }
  @if $iconName=="ion-android-bar" {
    content: "\f368";
  }
  @if $iconName=="ion-android-bicycle" {
    content: "\f369";
  }
  @if $iconName=="ion-android-boat" {
    content: "\f36a";
  }
  @if $iconName=="ion-android-bookmark" {
    content: "\f36b";
  }
  @if $iconName=="ion-android-bulb" {
    content: "\f36c";
  }
  @if $iconName=="ion-android-bus" {
    content: "\f36d";
  }
  @if $iconName=="ion-android-calendar" {
    content: "\f2d1";
  }
  @if $iconName=="ion-android-call" {
    content: "\f2d2";
  }
  @if $iconName=="ion-android-camera" {
    content: "\f2d3";
  }
  @if $iconName=="ion-android-cancel" {
    content: "\f36e";
  }
  @if $iconName=="ion-android-car" {
    content: "\f36f";
  }
  @if $iconName=="ion-android-cart" {
    content: "\f370";
  }
  @if $iconName=="ion-android-chat" {
    content: "\f2d4";
  }
  @if $iconName=="ion-android-checkbox" {
    content: "\f374";
  }
  @if $iconName=="ion-android-checkbox-blank" {
    content: "\f371";
  }
  @if $iconName=="ion-android-checkbox-outline" {
    content: "\f373";
  }
  @if $iconName=="ion-android-checkbox-outline-blank" {
    content: "\f372";
  }
  @if $iconName=="ion-android-checkmark-circle" {
    content: "\f375";
  }
  @if $iconName=="ion-android-clipboard" {
    content: "\f376";
  }
  @if $iconName=="ion-android-close" {
    content: "\f2d7";
  }
  @if $iconName=="ion-android-cloud" {
    content: "\f37a";
  }
  @if $iconName=="ion-android-cloud-circle" {
    content: "\f377";
  }
  @if $iconName=="ion-android-cloud-done" {
    content: "\f378";
  }
  @if $iconName=="ion-android-cloud-outline" {
    content: "\f379";
  }
  @if $iconName=="ion-android-color-palette" {
    content: "\f37b";
  }
  @if $iconName=="ion-android-compass" {
    content: "\f37c";
  }
  @if $iconName=="ion-android-contact" {
    content: "\f2d8";
  }
  @if $iconName=="ion-android-contacts" {
    content: "\f2d9";
  }
  @if $iconName=="ion-android-contract" {
    content: "\f37d";
  }
  @if $iconName=="ion-android-create" {
    content: "\f37e";
  }
  @if $iconName=="ion-android-delete" {
    content: "\f37f";
  }
  @if $iconName=="ion-android-desktop" {
    content: "\f380";
  }
  @if $iconName=="ion-android-document" {
    content: "\f381";
  }
  @if $iconName=="ion-android-done" {
    content: "\f383";
  }
  @if $iconName=="ion-android-done-all" {
    content: "\f382";
  }
  @if $iconName=="ion-android-download" {
    content: "\f2dd";
  }
  @if $iconName=="ion-android-drafts" {
    content: "\f384";
  }
  @if $iconName=="ion-android-exit" {
    content: "\f385";
  }
  @if $iconName=="ion-android-expand" {
    content: "\f386";
  }
  @if $iconName=="ion-android-favorite" {
    content: "\f388";
  }
  @if $iconName=="ion-android-favorite-outline" {
    content: "\f387";
  }
  @if $iconName=="ion-android-film" {
    content: "\f389";
  }
  @if $iconName=="ion-android-folder" {
    content: "\f2e0";
  }
  @if $iconName=="ion-android-folder-open" {
    content: "\f38a";
  }
  @if $iconName=="ion-android-funnel" {
    content: "\f38b";
  }
  @if $iconName=="ion-android-globe" {
    content: "\f38c";
  }
  @if $iconName=="ion-android-hand" {
    content: "\f2e3";
  }
  @if $iconName=="ion-android-hangout" {
    content: "\f38d";
  }
  @if $iconName=="ion-android-happy" {
    content: "\f38e";
  }
  @if $iconName=="ion-android-home" {
    content: "\f38f";
  }
  @if $iconName=="ion-android-image" {
    content: "\f2e4";
  }
  @if $iconName=="ion-android-laptop" {
    content: "\f390";
  }
  @if $iconName=="ion-android-list" {
    content: "\f391";
  }
  @if $iconName=="ion-android-locate" {
    content: "\f2e9";
  }
  @if $iconName=="ion-android-lock" {
    content: "\f392";
  }
  @if $iconName=="ion-android-mail" {
    content: "\f2eb";
  }
  @if $iconName=="ion-android-map" {
    content: "\f393";
  }
  @if $iconName=="ion-android-menu" {
    content: "\f394";
  }
  @if $iconName=="ion-android-microphone" {
    content: "\f2ec";
  }
  @if $iconName=="ion-android-microphone-off" {
    content: "\f395";
  }
  @if $iconName=="ion-android-more-horizontal" {
    content: "\f396";
  }
  @if $iconName=="ion-android-more-vertical" {
    content: "\f397";
  }
  @if $iconName=="ion-android-navigate" {
    content: "\f398";
  }
  @if $iconName=="ion-android-notifications" {
    content: "\f39b";
  }
  @if $iconName=="ion-android-notifications-none" {
    content: "\f399";
  }
  @if $iconName=="ion-android-notifications-off" {
    content: "\f39a";
  }
  @if $iconName=="ion-android-open" {
    content: "\f39c";
  }
  @if $iconName=="ion-android-options" {
    content: "\f39d";
  }
  @if $iconName=="ion-android-people" {
    content: "\f39e";
  }
  @if $iconName=="ion-android-person" {
    content: "\f3a0";
  }
  @if $iconName=="ion-android-person-add" {
    content: "\f39f";
  }
  @if $iconName=="ion-android-phone-landscape" {
    content: "\f3a1";
  }
  @if $iconName=="ion-android-phone-portrait" {
    content: "\f3a2";
  }
  @if $iconName=="ion-android-pin" {
    content: "\f3a3";
  }
  @if $iconName=="ion-android-plane" {
    content: "\f3a4";
  }
  @if $iconName=="ion-android-playstore" {
    content: "\f2f0";
  }
  @if $iconName=="ion-android-print" {
    content: "\f3a5";
  }
  @if $iconName=="ion-android-radio-button-off" {
    content: "\f3a6";
  }
  @if $iconName=="ion-android-radio-button-on" {
    content: "\f3a7";
  }
  @if $iconName=="ion-android-refresh" {
    content: "\f3a8";
  }
  @if $iconName=="ion-android-remove" {
    content: "\f2f4";
  }
  @if $iconName=="ion-android-remove-circle" {
    content: "\f3a9";
  }
  @if $iconName=="ion-android-restaurant" {
    content: "\f3aa";
  }
  @if $iconName=="ion-android-sad" {
    content: "\f3ab";
  }
  @if $iconName=="ion-android-search" {
    content: "\f2f5";
  }
  @if $iconName=="ion-android-send" {
    content: "\f2f6";
  }
  @if $iconName=="ion-android-settings" {
    content: "\f2f7";
  }
  @if $iconName=="ion-android-share" {
    content: "\f2f8";
  }
  @if $iconName=="ion-android-share-alt" {
    content: "\f3ac";
  }
  @if $iconName=="ion-android-star" {
    content: "\f2fc";
  }
  @if $iconName=="ion-android-star-half" {
    content: "\f3ad";
  }
  @if $iconName=="ion-android-star-outline" {
    content: "\f3ae";
  }
  @if $iconName=="ion-android-stopwatch" {
    content: "\f2fd";
  }
  @if $iconName=="ion-android-subway" {
    content: "\f3af";
  }
  @if $iconName=="ion-android-sunny" {
    content: "\f3b0";
  }
  @if $iconName=="ion-android-sync" {
    content: "\f3b1";
  }
  @if $iconName=="ion-android-textsms" {
    content: "\f3b2";
  }
  @if $iconName=="ion-android-time" {
    content: "\f3b3";
  }
  @if $iconName=="ion-android-train" {
    content: "\f3b4";
  }
  @if $iconName=="ion-android-unlock" {
    content: "\f3b5";
  }
  @if $iconName=="ion-android-upload" {
    content: "\f3b6";
  }
  @if $iconName=="ion-android-volume-down" {
    content: "\f3b7";
  }
  @if $iconName=="ion-android-volume-mute" {
    content: "\f3b8";
  }
  @if $iconName=="ion-android-volume-off" {
    content: "\f3b9";
  }
  @if $iconName=="ion-android-volume-up" {
    content: "\f3ba";
  }
  @if $iconName=="ion-android-walk" {
    content: "\f3bb";
  }
  @if $iconName=="ion-android-warning" {
    content: "\f3bc";
  }
  @if $iconName=="ion-android-watch" {
    content: "\f3bd";
  }
  @if $iconName=="ion-android-wifi" {
    content: "\f305";
  }
  @if $iconName=="ion-aperture" {
    content: "\f313";
  }
  @if $iconName=="ion-archive" {
    content: "\f102";
  }
  @if $iconName=="ion-arrow-down-a" {
    content: "\f103";
  }
  @if $iconName=="ion-arrow-down-b" {
    content: "\f104";
  }
  @if $iconName=="ion-arrow-down-c" {
    content: "\f105";
  }
  @if $iconName=="ion-arrow-expand" {
    content: "\f25e";
  }
  @if $iconName=="ion-arrow-graph-down-left" {
    content: "\f25f";
  }
  @if $iconName=="ion-arrow-graph-down-right" {
    content: "\f260";
  }
  @if $iconName=="ion-arrow-graph-up-left" {
    content: "\f261";
  }
  @if $iconName=="ion-arrow-graph-up-right" {
    content: "\f262";
  }
  @if $iconName=="ion-arrow-left-a" {
    content: "\f106";
  }
  @if $iconName=="ion-arrow-left-b" {
    content: "\f107";
  }
  @if $iconName=="ion-arrow-left-c" {
    content: "\f108";
  }
  @if $iconName=="ion-arrow-move" {
    content: "\f263";
  }
  @if $iconName=="ion-arrow-resize" {
    content: "\f264";
  }
  @if $iconName=="ion-arrow-return-left" {
    content: "\f265";
  }
  @if $iconName=="ion-arrow-return-right" {
    content: "\f266";
  }
  @if $iconName=="ion-arrow-right-a" {
    content: "\f109";
  }
  @if $iconName=="ion-arrow-right-b" {
    content: "\f10a";
  }
  @if $iconName=="ion-arrow-right-c" {
    content: "\f10b";
  }
  @if $iconName=="ion-arrow-shrink" {
    content: "\f267";
  }
  @if $iconName=="ion-arrow-swap" {
    content: "\f268";
  }
  @if $iconName=="ion-arrow-up-a" {
    content: "\f10c";
  }
  @if $iconName=="ion-arrow-up-b" {
    content: "\f10d";
  }
  @if $iconName=="ion-arrow-up-c" {
    content: "\f10e";
  }
  @if $iconName=="ion-asterisk" {
    content: "\f314";
  }
  @if $iconName=="ion-at" {
    content: "\f10f";
  }
  @if $iconName=="ion-backspace" {
    content: "\f3bf";
  }
  @if $iconName=="ion-backspace-outline" {
    content: "\f3be";
  }
  @if $iconName=="ion-bag" {
    content: "\f110";
  }
  @if $iconName=="ion-battery-charging" {
    content: "\f111";
  }
  @if $iconName=="ion-battery-empty" {
    content: "\f112";
  }
  @if $iconName=="ion-battery-full" {
    content: "\f113";
  }
  @if $iconName=="ion-battery-half" {
    content: "\f114";
  }
  @if $iconName=="ion-battery-low" {
    content: "\f115";
  }
  @if $iconName=="ion-beaker" {
    content: "\f269";
  }
  @if $iconName=="ion-beer" {
    content: "\f26a";
  }
  @if $iconName=="ion-bluetooth" {
    content: "\f116";
  }
  @if $iconName=="ion-bonfire" {
    content: "\f315";
  }
  @if $iconName=="ion-bookmark" {
    content: "\f26b";
  }
  @if $iconName=="ion-bowtie" {
    content: "\f3c0";
  }
  @if $iconName=="ion-briefcase" {
    content: "\f26c";
  }
  @if $iconName=="ion-bug" {
    content: "\f2be";
  }
  @if $iconName=="ion-calculator" {
    content: "\f26d";
  }
  @if $iconName=="ion-calendar" {
    content: "\f117";
  }
  @if $iconName=="ion-camera" {
    content: "\f118";
  }
  @if $iconName=="ion-card" {
    content: "\f119";
  }
  @if $iconName=="ion-cash" {
    content: "\f316";
  }
  @if $iconName=="ion-chatbox" {
    content: "\f11b";
  }
  @if $iconName=="ion-chatbox-working" {
    content: "\f11a";
  }
  @if $iconName=="ion-chatboxes" {
    content: "\f11c";
  }
  @if $iconName=="ion-chatbubble" {
    content: "\f11e";
  }
  @if $iconName=="ion-chatbubble-working" {
    content: "\f11d";
  }
  @if $iconName=="ion-chatbubbles" {
    content: "\f11f";
  }
  @if $iconName=="ion-checkmark" {
    content: "\f122";
  }
  @if $iconName=="ion-checkmark-circled" {
    content: "\f120";
  }
  @if $iconName=="ion-checkmark-round" {
    content: "\f121";
  }
  @if $iconName=="ion-chevron-down" {
    content: "\f123";
  }
  @if $iconName=="ion-chevron-left" {
    content: "\f124";
  }
  @if $iconName=="ion-chevron-right" {
    content: "\f125";
  }
  @if $iconName=="ion-chevron-up" {
    content: "\f126";
  }
  @if $iconName=="ion-clipboard" {
    content: "\f127";
  }
  @if $iconName=="ion-clock" {
    content: "\f26e";
  }
  @if $iconName=="ion-close" {
    content: "\f12a";
  }
  @if $iconName=="ion-close-circled" {
    content: "\f128";
  }
  @if $iconName=="ion-close-round" {
    content: "\f129";
  }
  @if $iconName=="ion-closed-captioning" {
    content: "\f317";
  }
  @if $iconName=="ion-cloud" {
    content: "\f12b";
  }
  @if $iconName=="ion-code" {
    content: "\f271";
  }
  @if $iconName=="ion-code-download" {
    content: "\f26f";
  }
  @if $iconName=="ion-code-working" {
    content: "\f270";
  }
  @if $iconName=="ion-coffee" {
    content: "\f272";
  }
  @if $iconName=="ion-compass" {
    content: "\f273";
  }
  @if $iconName=="ion-compose" {
    content: "\f12c";
  }
  @if $iconName=="ion-connection-bars" {
    content: "\f274";
  }
  @if $iconName=="ion-contrast" {
    content: "\f275";
  }
  @if $iconName=="ion-crop" {
    content: "\f3c1";
  }
  @if $iconName=="ion-cube" {
    content: "\f318";
  }
  @if $iconName=="ion-disc" {
    content: "\f12d";
  }
  @if $iconName=="ion-document" {
    content: "\f12f";
  }
  @if $iconName=="ion-document-text" {
    content: "\f12e";
  }
  @if $iconName=="ion-drag" {
    content: "\f130";
  }
  @if $iconName=="ion-earth" {
    content: "\f276";
  }
  @if $iconName=="ion-easel" {
    content: "\f3c2";
  }
  @if $iconName=="ion-edit" {
    content: "\f2bf";
  }
  @if $iconName=="ion-egg" {
    content: "\f277";
  }
  @if $iconName=="ion-eject" {
    content: "\f131";
  }
  @if $iconName=="ion-email" {
    content: "\f132";
  }
  @if $iconName=="ion-email-unread" {
    content: "\f3c3";
  }
  @if $iconName=="ion-erlenmeyer-flask" {
    content: "\f3c5";
  }
  @if $iconName=="ion-erlenmeyer-flask-bubbles" {
    content: "\f3c4";
  }
  @if $iconName=="ion-eye" {
    content: "\f133";
  }
  @if $iconName=="ion-eye-disabled" {
    content: "\f306";
  }
  @if $iconName=="ion-female" {
    content: "\f278";
  }
  @if $iconName=="ion-filing" {
    content: "\f134";
  }
  @if $iconName=="ion-film-marker" {
    content: "\f135";
  }
  @if $iconName=="ion-fireball" {
    content: "\f319";
  }
  @if $iconName=="ion-flag" {
    content: "\f279";
  }
  @if $iconName=="ion-flame" {
    content: "\f31a";
  }
  @if $iconName=="ion-flash" {
    content: "\f137";
  }
  @if $iconName=="ion-flash-off" {
    content: "\f136";
  }
  @if $iconName=="ion-folder" {
    content: "\f139";
  }
  @if $iconName=="ion-fork" {
    content: "\f27a";
  }
  @if $iconName=="ion-fork-repo" {
    content: "\f2c0";
  }
  @if $iconName=="ion-forward" {
    content: "\f13a";
  }
  @if $iconName=="ion-funnel" {
    content: "\f31b";
  }
  @if $iconName=="ion-gear-a" {
    content: "\f13d";
  }
  @if $iconName=="ion-gear-b" {
    content: "\f13e";
  }
  @if $iconName=="ion-grid" {
    content: "\f13f";
  }
  @if $iconName=="ion-hammer" {
    content: "\f27b";
  }
  @if $iconName=="ion-happy" {
    content: "\f31c";
  }
  @if $iconName=="ion-happy-outline" {
    content: "\f3c6";
  }
  @if $iconName=="ion-headphone" {
    content: "\f140";
  }
  @if $iconName=="ion-heart" {
    content: "\f141";
  }
  @if $iconName=="ion-heart-broken" {
    content: "\f31d";
  }
  @if $iconName=="ion-help" {
    content: "\f143";
  }
  @if $iconName=="ion-help-buoy" {
    content: "\f27c";
  }
  @if $iconName=="ion-help-circled" {
    content: "\f142";
  }
  @if $iconName=="ion-home" {
    content: "\f144";
  }
  @if $iconName=="ion-icecream" {
    content: "\f27d";
  }
  @if $iconName=="ion-image" {
    content: "\f147";
  }
  @if $iconName=="ion-images" {
    content: "\f148";
  }
  @if $iconName=="ion-information" {
    content: "\f14a";
  }
  @if $iconName=="ion-information-circled" {
    content: "\f149";
  }
  @if $iconName=="ion-ionic" {
    content: "\f14b";
  }
  @if $iconName=="ion-ios-alarm" {
    content: "\f3c8";
  }
  @if $iconName=="ion-ios-alarm-outline" {
    content: "\f3c7";
  }
  @if $iconName=="ion-ios-albums" {
    content: "\f3ca";
  }
  @if $iconName=="ion-ios-albums-outline" {
    content: "\f3c9";
  }
  @if $iconName=="ion-ios-americanfootball" {
    content: "\f3cc";
  }
  @if $iconName=="ion-ios-americanfootball-outline" {
    content: "\f3cb";
  }
  @if $iconName=="ion-ios-analytics" {
    content: "\f3ce";
  }
  @if $iconName=="ion-ios-analytics-outline" {
    content: "\f3cd";
  }
  @if $iconName=="ion-ios-arrow-back" {
    content: "\f3cf";
  }
  @if $iconName=="ion-ios-arrow-down" {
    content: "\f3d0";
  }
  @if $iconName=="ion-ios-arrow-forward" {
    content: "\f3d1";
  }
  @if $iconName=="ion-ios-arrow-left" {
    content: "\f3d2";
  }
  @if $iconName=="ion-ios-arrow-right" {
    content: "\f3d3";
  }
  @if $iconName=="ion-ios-arrow-thin-down" {
    content: "\f3d4";
  }
  @if $iconName=="ion-ios-arrow-thin-left" {
    content: "\f3d5";
  }
  @if $iconName=="ion-ios-arrow-thin-right" {
    content: "\f3d6";
  }
  @if $iconName=="ion-ios-arrow-thin-up" {
    content: "\f3d7";
  }
  @if $iconName=="ion-ios-arrow-up" {
    content: "\f3d8";
  }
  @if $iconName=="ion-ios-at" {
    content: "\f3da";
  }
  @if $iconName=="ion-ios-at-outline" {
    content: "\f3d9";
  }
  @if $iconName=="ion-ios-barcode" {
    content: "\f3dc";
  }
  @if $iconName=="ion-ios-barcode-outline" {
    content: "\f3db";
  }
  @if $iconName=="ion-ios-baseball" {
    content: "\f3de";
  }
  @if $iconName=="ion-ios-baseball-outline" {
    content: "\f3dd";
  }
  @if $iconName=="ion-ios-basketball" {
    content: "\f3e0";
  }
  @if $iconName=="ion-ios-basketball-outline" {
    content: "\f3df";
  }
  @if $iconName=="ion-ios-bell" {
    content: "\f3e2";
  }
  @if $iconName=="ion-ios-bell-outline" {
    content: "\f3e1";
  }
  @if $iconName=="ion-ios-body" {
    content: "\f3e4";
  }
  @if $iconName=="ion-ios-body-outline" {
    content: "\f3e3";
  }
  @if $iconName=="ion-ios-bolt" {
    content: "\f3e6";
  }
  @if $iconName=="ion-ios-bolt-outline" {
    content: "\f3e5";
  }
  @if $iconName=="ion-ios-book" {
    content: "\f3e8";
  }
  @if $iconName=="ion-ios-book-outline" {
    content: "\f3e7";
  }
  @if $iconName=="ion-ios-bookmarks" {
    content: "\f3ea";
  }
  @if $iconName=="ion-ios-bookmarks-outline" {
    content: "\f3e9";
  }
  @if $iconName=="ion-ios-box" {
    content: "\f3ec";
  }
  @if $iconName=="ion-ios-box-outline" {
    content: "\f3eb";
  }
  @if $iconName=="ion-ios-briefcase" {
    content: "\f3ee";
  }
  @if $iconName=="ion-ios-briefcase-outline" {
    content: "\f3ed";
  }
  @if $iconName=="ion-ios-browsers" {
    content: "\f3f0";
  }
  @if $iconName=="ion-ios-browsers-outline" {
    content: "\f3ef";
  }
  @if $iconName=="ion-ios-calculator" {
    content: "\f3f2";
  }
  @if $iconName=="ion-ios-calculator-outline" {
    content: "\f3f1";
  }
  @if $iconName=="ion-ios-calendar" {
    content: "\f3f4";
  }
  @if $iconName=="ion-ios-calendar-outline" {
    content: "\f3f3";
  }
  @if $iconName=="ion-ios-camera" {
    content: "\f3f6";
  }
  @if $iconName=="ion-ios-camera-outline" {
    content: "\f3f5";
  }
  @if $iconName=="ion-ios-cart" {
    content: "\f3f8";
  }
  @if $iconName=="ion-ios-cart-outline" {
    content: "\f3f7";
  }
  @if $iconName=="ion-ios-chatboxes" {
    content: "\f3fa";
  }
  @if $iconName=="ion-ios-chatboxes-outline" {
    content: "\f3f9";
  }
  @if $iconName=="ion-ios-chatbubble" {
    content: "\f3fc";
  }
  @if $iconName=="ion-ios-chatbubble-outline" {
    content: "\f3fb";
  }
  @if $iconName=="ion-ios-checkmark" {
    content: "\f3ff";
  }
  @if $iconName=="ion-ios-checkmark-empty" {
    content: "\f3fd";
  }
  @if $iconName=="ion-ios-checkmark-outline" {
    content: "\f3fe";
  }
  @if $iconName=="ion-ios-circle-filled" {
    content: "\f400";
  }
  @if $iconName=="ion-ios-circle-outline" {
    content: "\f401";
  }
  @if $iconName=="ion-ios-clock" {
    content: "\f403";
  }
  @if $iconName=="ion-ios-clock-outline" {
    content: "\f402";
  }
  @if $iconName=="ion-ios-close" {
    content: "\f406";
  }
  @if $iconName=="ion-ios-close-empty" {
    content: "\f404";
  }
  @if $iconName=="ion-ios-close-outline" {
    content: "\f405";
  }
  @if $iconName=="ion-ios-cloud" {
    content: "\f40c";
  }
  @if $iconName=="ion-ios-cloud-download" {
    content: "\f408";
  }
  @if $iconName=="ion-ios-cloud-download-outline" {
    content: "\f407";
  }
  @if $iconName=="ion-ios-cloud-outline" {
    content: "\f409";
  }
  @if $iconName=="ion-ios-cloud-upload" {
    content: "\f40b";
  }
  @if $iconName=="ion-ios-cloud-upload-outline" {
    content: "\f40a";
  }
  @if $iconName=="ion-ios-cloudy" {
    content: "\f410";
  }
  @if $iconName=="ion-ios-cloudy-night" {
    content: "\f40e";
  }
  @if $iconName=="ion-ios-cloudy-night-outline" {
    content: "\f40d";
  }
  @if $iconName=="ion-ios-cloudy-outline" {
    content: "\f40f";
  }
  @if $iconName=="ion-ios-cog" {
    content: "\f412";
  }
  @if $iconName=="ion-ios-cog-outline" {
    content: "\f411";
  }
  @if $iconName=="ion-ios-color-filter" {
    content: "\f414";
  }
  @if $iconName=="ion-ios-color-filter-outline" {
    content: "\f413";
  }
  @if $iconName=="ion-ios-color-wand" {
    content: "\f416";
  }
  @if $iconName=="ion-ios-color-wand-outline" {
    content: "\f415";
  }
  @if $iconName=="ion-ios-compose" {
    content: "\f418";
  }
  @if $iconName=="ion-ios-compose-outline" {
    content: "\f417";
  }
  @if $iconName=="ion-ios-contact" {
    content: "\f41a";
  }
  @if $iconName=="ion-ios-contact-outline" {
    content: "\f419";
  }
  @if $iconName=="ion-ios-copy" {
    content: "\f41c";
  }
  @if $iconName=="ion-ios-copy-outline" {
    content: "\f41b";
  }
  @if $iconName=="ion-ios-crop" {
    content: "\f41e";
  }
  @if $iconName=="ion-ios-crop-strong" {
    content: "\f41d";
  }
  @if $iconName=="ion-ios-download" {
    content: "\f420";
  }
  @if $iconName=="ion-ios-download-outline" {
    content: "\f41f";
  }
  @if $iconName=="ion-ios-drag" {
    content: "\f421";
  }
  @if $iconName=="ion-ios-email" {
    content: "\f423";
  }
  @if $iconName=="ion-ios-email-outline" {
    content: "\f422";
  }
  @if $iconName=="ion-ios-eye" {
    content: "\f425";
  }
  @if $iconName=="ion-ios-eye-outline" {
    content: "\f424";
  }
  @if $iconName=="ion-ios-fastforward" {
    content: "\f427";
  }
  @if $iconName=="ion-ios-fastforward-outline" {
    content: "\f426";
  }
  @if $iconName=="ion-ios-filing" {
    content: "\f429";
  }
  @if $iconName=="ion-ios-filing-outline" {
    content: "\f428";
  }
  @if $iconName=="ion-ios-film" {
    content: "\f42b";
  }
  @if $iconName=="ion-ios-film-outline" {
    content: "\f42a";
  }
  @if $iconName=="ion-ios-flag" {
    content: "\f42d";
  }
  @if $iconName=="ion-ios-flag-outline" {
    content: "\f42c";
  }
  @if $iconName=="ion-ios-flame" {
    content: "\f42f";
  }
  @if $iconName=="ion-ios-flame-outline" {
    content: "\f42e";
  }
  @if $iconName=="ion-ios-flask" {
    content: "\f431";
  }
  @if $iconName=="ion-ios-flask-outline" {
    content: "\f430";
  }
  @if $iconName=="ion-ios-flower" {
    content: "\f433";
  }
  @if $iconName=="ion-ios-flower-outline" {
    content: "\f432";
  }
  @if $iconName=="ion-ios-folder" {
    content: "\f435";
  }
  @if $iconName=="ion-ios-folder-outline" {
    content: "\f434";
  }
  @if $iconName=="ion-ios-football" {
    content: "\f437";
  }
  @if $iconName=="ion-ios-football-outline" {
    content: "\f436";
  }
  @if $iconName=="ion-ios-game-controller-a" {
    content: "\f439";
  }
  @if $iconName=="ion-ios-game-controller-a-outline" {
    content: "\f438";
  }
  @if $iconName=="ion-ios-game-controller-b" {
    content: "\f43b";
  }
  @if $iconName=="ion-ios-game-controller-b-outline" {
    content: "\f43a";
  }
  @if $iconName=="ion-ios-gear" {
    content: "\f43d";
  }
  @if $iconName=="ion-ios-gear-outline" {
    content: "\f43c";
  }
  @if $iconName=="ion-ios-glasses" {
    content: "\f43f";
  }
  @if $iconName=="ion-ios-glasses-outline" {
    content: "\f43e";
  }
  @if $iconName=="ion-ios-grid-view" {
    content: "\f441";
  }
  @if $iconName=="ion-ios-grid-view-outline" {
    content: "\f440";
  }
  @if $iconName=="ion-ios-heart" {
    content: "\f443";
  }
  @if $iconName=="ion-ios-heart-outline" {
    content: "\f442";
  }
  @if $iconName=="ion-ios-help" {
    content: "\f446";
  }
  @if $iconName=="ion-ios-help-empty" {
    content: "\f444";
  }
  @if $iconName=="ion-ios-help-outline" {
    content: "\f445";
  }
  @if $iconName=="ion-ios-home" {
    content: "\f448";
  }
  @if $iconName=="ion-ios-home-outline" {
    content: "\f447";
  }
  @if $iconName=="ion-ios-infinite" {
    content: "\f44a";
  }
  @if $iconName=="ion-ios-infinite-outline" {
    content: "\f449";
  }
  @if $iconName=="ion-ios-information" {
    content: "\f44d";
  }
  @if $iconName=="ion-ios-information-empty" {
    content: "\f44b";
  }
  @if $iconName=="ion-ios-information-outline" {
    content: "\f44c";
  }
  @if $iconName=="ion-ios-ionic-outline" {
    content: "\f44e";
  }
  @if $iconName=="ion-ios-keypad" {
    content: "\f450";
  }
  @if $iconName=="ion-ios-keypad-outline" {
    content: "\f44f";
  }
  @if $iconName=="ion-ios-lightbulb" {
    content: "\f452";
  }
  @if $iconName=="ion-ios-lightbulb-outline" {
    content: "\f451";
  }
  @if $iconName=="ion-ios-list" {
    content: "\f454";
  }
  @if $iconName=="ion-ios-list-outline" {
    content: "\f453";
  }
  @if $iconName=="ion-ios-location" {
    content: "\f456";
  }
  @if $iconName=="ion-ios-location-outline" {
    content: "\f455";
  }
  @if $iconName=="ion-ios-locked" {
    content: "\f458";
  }
  @if $iconName=="ion-ios-locked-outline" {
    content: "\f457";
  }
  @if $iconName=="ion-ios-loop" {
    content: "\f45a";
  }
  @if $iconName=="ion-ios-loop-strong" {
    content: "\f459";
  }
  @if $iconName=="ion-ios-medical" {
    content: "\f45c";
  }
  @if $iconName=="ion-ios-medical-outline" {
    content: "\f45b";
  }
  @if $iconName=="ion-ios-medkit" {
    content: "\f45e";
  }
  @if $iconName=="ion-ios-medkit-outline" {
    content: "\f45d";
  }
  @if $iconName=="ion-ios-mic" {
    content: "\f461";
  }
  @if $iconName=="ion-ios-mic-off" {
    content: "\f45f";
  }
  @if $iconName=="ion-ios-mic-outline" {
    content: "\f460";
  }
  @if $iconName=="ion-ios-minus" {
    content: "\f464";
  }
  @if $iconName=="ion-ios-minus-empty" {
    content: "\f462";
  }
  @if $iconName=="ion-ios-minus-outline" {
    content: "\f463";
  }
  @if $iconName=="ion-ios-monitor" {
    content: "\f466";
  }
  @if $iconName=="ion-ios-monitor-outline" {
    content: "\f465";
  }
  @if $iconName=="ion-ios-moon" {
    content: "\f468";
  }
  @if $iconName=="ion-ios-moon-outline" {
    content: "\f467";
  }
  @if $iconName=="ion-ios-more" {
    content: "\f46a";
  }
  @if $iconName=="ion-ios-more-outline" {
    content: "\f469";
  }
  @if $iconName=="ion-ios-musical-note" {
    content: "\f46b";
  }
  @if $iconName=="ion-ios-musical-notes" {
    content: "\f46c";
  }
  @if $iconName=="ion-ios-navigate" {
    content: "\f46e";
  }
  @if $iconName=="ion-ios-navigate-outline" {
    content: "\f46d";
  }
  @if $iconName=="ion-ios-nutrition" {
    content: "\f470";
  }
  @if $iconName=="ion-ios-nutrition-outline" {
    content: "\f46f";
  }
  @if $iconName=="ion-ios-paper" {
    content: "\f472";
  }
  @if $iconName=="ion-ios-paper-outline" {
    content: "\f471";
  }
  @if $iconName=="ion-ios-paperplane" {
    content: "\f474";
  }
  @if $iconName=="ion-ios-paperplane-outline" {
    content: "\f473";
  }
  @if $iconName=="ion-ios-partlysunny" {
    content: "\f476";
  }
  @if $iconName=="ion-ios-partlysunny-outline" {
    content: "\f475";
  }
  @if $iconName=="ion-ios-pause" {
    content: "\f478";
  }
  @if $iconName=="ion-ios-pause-outline" {
    content: "\f477";
  }
  @if $iconName=="ion-ios-paw" {
    content: "\f47a";
  }
  @if $iconName=="ion-ios-paw-outline" {
    content: "\f479";
  }
  @if $iconName=="ion-ios-people" {
    content: "\f47c";
  }
  @if $iconName=="ion-ios-people-outline" {
    content: "\f47b";
  }
  @if $iconName=="ion-ios-person" {
    content: "\f47e";
  }
  @if $iconName=="ion-ios-person-outline" {
    content: "\f47d";
  }
  @if $iconName=="ion-ios-personadd" {
    content: "\f480";
  }
  @if $iconName=="ion-ios-personadd-outline" {
    content: "\f47f";
  }
  @if $iconName=="ion-ios-photos" {
    content: "\f482";
  }
  @if $iconName=="ion-ios-photos-outline" {
    content: "\f481";
  }
  @if $iconName=="ion-ios-pie" {
    content: "\f484";
  }
  @if $iconName=="ion-ios-pie-outline" {
    content: "\f483";
  }
  @if $iconName=="ion-ios-pint" {
    content: "\f486";
  }
  @if $iconName=="ion-ios-pint-outline" {
    content: "\f485";
  }
  @if $iconName=="ion-ios-play" {
    content: "\f488";
  }
  @if $iconName=="ion-ios-play-outline" {
    content: "\f487";
  }
  @if $iconName=="ion-ios-plus" {
    content: "\f48b";
  }
  @if $iconName=="ion-ios-plus-empty" {
    content: "\f489";
  }
  @if $iconName=="ion-ios-plus-outline" {
    content: "\f48a";
  }
  @if $iconName=="ion-ios-pricetag" {
    content: "\f48d";
  }
  @if $iconName=="ion-ios-pricetag-outline" {
    content: "\f48c";
  }
  @if $iconName=="ion-ios-pricetags" {
    content: "\f48f";
  }
  @if $iconName=="ion-ios-pricetags-outline" {
    content: "\f48e";
  }
  @if $iconName=="ion-ios-printer" {
    content: "\f491";
  }
  @if $iconName=="ion-ios-printer-outline" {
    content: "\f490";
  }
  @if $iconName=="ion-ios-pulse" {
    content: "\f493";
  }
  @if $iconName=="ion-ios-pulse-strong" {
    content: "\f492";
  }
  @if $iconName=="ion-ios-rainy" {
    content: "\f495";
  }
  @if $iconName=="ion-ios-rainy-outline" {
    content: "\f494";
  }
  @if $iconName=="ion-ios-recording" {
    content: "\f497";
  }
  @if $iconName=="ion-ios-recording-outline" {
    content: "\f496";
  }
  @if $iconName=="ion-ios-redo" {
    content: "\f499";
  }
  @if $iconName=="ion-ios-redo-outline" {
    content: "\f498";
  }
  @if $iconName=="ion-ios-refresh" {
    content: "\f49c";
  }
  @if $iconName=="ion-ios-refresh-empty" {
    content: "\f49a";
  }
  @if $iconName=="ion-ios-refresh-outline" {
    content: "\f49b";
  }
  @if $iconName=="ion-ios-reload" {
    content: "\f49d";
  }
  @if $iconName=="ion-ios-reverse-camera" {
    content: "\f49f";
  }
  @if $iconName=="ion-ios-reverse-camera-outline" {
    content: "\f49e";
  }
  @if $iconName=="ion-ios-rewind" {
    content: "\f4a1";
  }
  @if $iconName=="ion-ios-rewind-outline" {
    content: "\f4a0";
  }
  @if $iconName=="ion-ios-rose" {
    content: "\f4a3";
  }
  @if $iconName=="ion-ios-rose-outline" {
    content: "\f4a2";
  }
  @if $iconName=="ion-ios-search" {
    content: "\f4a5";
  }
  @if $iconName=="ion-ios-search-strong" {
    content: "\f4a4";
  }
  @if $iconName=="ion-ios-settings" {
    content: "\f4a7";
  }
  @if $iconName=="ion-ios-settings-strong" {
    content: "\f4a6";
  }
  @if $iconName=="ion-ios-shuffle" {
    content: "\f4a9";
  }
  @if $iconName=="ion-ios-shuffle-strong" {
    content: "\f4a8";
  }
  @if $iconName=="ion-ios-skipbackward" {
    content: "\f4ab";
  }
  @if $iconName=="ion-ios-skipbackward-outline" {
    content: "\f4aa";
  }
  @if $iconName=="ion-ios-skipforward" {
    content: "\f4ad";
  }
  @if $iconName=="ion-ios-skipforward-outline" {
    content: "\f4ac";
  }
  @if $iconName=="ion-ios-snowy" {
    content: "\f4ae";
  }
  @if $iconName=="ion-ios-speedometer" {
    content: "\f4b0";
  }
  @if $iconName=="ion-ios-speedometer-outline" {
    content: "\f4af";
  }
  @if $iconName=="ion-ios-star" {
    content: "\f4b3";
  }
  @if $iconName=="ion-ios-star-half" {
    content: "\f4b1";
  }
  @if $iconName=="ion-ios-star-outline" {
    content: "\f4b2";
  }
  @if $iconName=="ion-ios-stopwatch" {
    content: "\f4b5";
  }
  @if $iconName=="ion-ios-stopwatch-outline" {
    content: "\f4b4";
  }
  @if $iconName=="ion-ios-sunny" {
    content: "\f4b7";
  }
  @if $iconName=="ion-ios-sunny-outline" {
    content: "\f4b6";
  }
  @if $iconName=="ion-ios-telephone" {
    content: "\f4b9";
  }
  @if $iconName=="ion-ios-telephone-outline" {
    content: "\f4b8";
  }
  @if $iconName=="ion-ios-tennisball" {
    content: "\f4bb";
  }
  @if $iconName=="ion-ios-tennisball-outline" {
    content: "\f4ba";
  }
  @if $iconName=="ion-ios-thunderstorm" {
    content: "\f4bd";
  }
  @if $iconName=="ion-ios-thunderstorm-outline" {
    content: "\f4bc";
  }
  @if $iconName=="ion-ios-time" {
    content: "\f4bf";
  }
  @if $iconName=="ion-ios-time-outline" {
    content: "\f4be";
  }
  @if $iconName=="ion-ios-timer" {
    content: "\f4c1";
  }
  @if $iconName=="ion-ios-timer-outline" {
    content: "\f4c0";
  }
  @if $iconName=="ion-ios-toggle" {
    content: "\f4c3";
  }
  @if $iconName=="ion-ios-toggle-outline" {
    content: "\f4c2";
  }
  @if $iconName=="ion-ios-trash" {
    content: "\f4c5";
  }
  @if $iconName=="ion-ios-trash-outline" {
    content: "\f4c4";
  }
  @if $iconName=="ion-ios-undo" {
    content: "\f4c7";
  }
  @if $iconName=="ion-ios-undo-outline" {
    content: "\f4c6";
  }
  @if $iconName=="ion-ios-unlocked" {
    content: "\f4c9";
  }
  @if $iconName=="ion-ios-unlocked-outline" {
    content: "\f4c8";
  }
  @if $iconName=="ion-ios-upload" {
    content: "\f4cb";
  }
  @if $iconName=="ion-ios-upload-outline" {
    content: "\f4ca";
  }
  @if $iconName=="ion-ios-videocam" {
    content: "\f4cd";
  }
  @if $iconName=="ion-ios-videocam-outline" {
    content: "\f4cc";
  }
  @if $iconName=="ion-ios-volume-high" {
    content: "\f4ce";
  }
  @if $iconName=="ion-ios-volume-low" {
    content: "\f4cf";
  }
  @if $iconName=="ion-ios-wineglass" {
    content: "\f4d1";
  }
  @if $iconName=="ion-ios-wineglass-outline" {
    content: "\f4d0";
  }
  @if $iconName=="ion-ios-world" {
    content: "\f4d3";
  }
  @if $iconName=="ion-ios-world-outline" {
    content: "\f4d2";
  }
  @if $iconName=="ion-ipad" {
    content: "\f1f9";
  }
  @if $iconName=="ion-iphone" {
    content: "\f1fa";
  }
  @if $iconName=="ion-ipod" {
    content: "\f1fb";
  }
  @if $iconName=="ion-jet" {
    content: "\f295";
  }
  @if $iconName=="ion-key" {
    content: "\f296";
  }
  @if $iconName=="ion-knife" {
    content: "\f297";
  }
  @if $iconName=="ion-laptop" {
    content: "\f1fc";
  }
  @if $iconName=="ion-leaf" {
    content: "\f1fd";
  }
  @if $iconName=="ion-levels" {
    content: "\f298";
  }
  @if $iconName=="ion-lightbulb" {
    content: "\f299";
  }
  @if $iconName=="ion-link" {
    content: "\f1fe";
  }
  @if $iconName=="ion-load-a" {
    content: "\f29a";
  }
  @if $iconName=="ion-load-b" {
    content: "\f29b";
  }
  @if $iconName=="ion-load-c" {
    content: "\f29c";
  }
  @if $iconName=="ion-load-d" {
    content: "\f29d";
  }
  @if $iconName=="ion-location" {
    content: "\f1ff";
  }
  @if $iconName=="ion-lock-combination" {
    content: "\f4d4";
  }
  @if $iconName=="ion-locked" {
    content: "\f200";
  }
  @if $iconName=="ion-log-in" {
    content: "\f29e";
  }
  @if $iconName=="ion-log-out" {
    content: "\f29f";
  }
  @if $iconName=="ion-loop" {
    content: "\f201";
  }
  @if $iconName=="ion-magnet" {
    content: "\f2a0";
  }
  @if $iconName=="ion-male" {
    content: "\f2a1";
  }
  @if $iconName=="ion-man" {
    content: "\f202";
  }
  @if $iconName=="ion-map" {
    content: "\f203";
  }
  @if $iconName=="ion-medkit" {
    content: "\f2a2";
  }
  @if $iconName=="ion-merge" {
    content: "\f33f";
  }
  @if $iconName=="ion-mic-a" {
    content: "\f204";
  }
  @if $iconName=="ion-mic-b" {
    content: "\f205";
  }
  @if $iconName=="ion-mic-c" {
    content: "\f206";
  }
  @if $iconName=="ion-minus" {
    content: "\f209";
  }
  @if $iconName=="ion-minus-circled" {
    content: "\f207";
  }
  @if $iconName=="ion-minus-round" {
    content: "\f208";
  }
  @if $iconName=="ion-model-s" {
    content: "\f2c1";
  }
  @if $iconName=="ion-monitor" {
    content: "\f20a";
  }
  @if $iconName=="ion-more" {
    content: "\f20b";
  }
  @if $iconName=="ion-mouse" {
    content: "\f340";
  }
  @if $iconName=="ion-music-note" {
    content: "\f20c";
  }
  @if $iconName=="ion-navicon" {
    content: "\f20e";
  }
  @if $iconName=="ion-navicon-round" {
    content: "\f20d";
  }
  @if $iconName=="ion-navigate" {
    content: "\f2a3";
  }
  @if $iconName=="ion-network" {
    content: "\f341";
  }
  @if $iconName=="ion-no-smoking" {
    content: "\f2c2";
  }
  @if $iconName=="ion-nuclear" {
    content: "\f2a4";
  }
  @if $iconName=="ion-outlet" {
    content: "\f342";
  }
  @if $iconName=="ion-paintbrush" {
    content: "\f4d5";
  }
  @if $iconName=="ion-paintbucket" {
    content: "\f4d6";
  }
  @if $iconName=="ion-paper-airplane" {
    content: "\f2c3";
  }
  @if $iconName=="ion-paperclip" {
    content: "\f20f";
  }
  @if $iconName=="ion-pause" {
    content: "\f210";
  }
  @if $iconName=="ion-person" {
    content: "\f213";
  }
  @if $iconName=="ion-person-add" {
    content: "\f211";
  }
  @if $iconName=="ion-person-stalker" {
    content: "\f212";
  }
  @if $iconName=="ion-pie-graph" {
    content: "\f2a5";
  }
  @if $iconName=="ion-pin" {
    content: "\f2a6";
  }
  @if $iconName=="ion-pinpoint" {
    content: "\f2a7";
  }
  @if $iconName=="ion-pizza" {
    content: "\f2a8";
  }
  @if $iconName=="ion-plane" {
    content: "\f214";
  }
  @if $iconName=="ion-planet" {
    content: "\f343";
  }
  @if $iconName=="ion-play" {
    content: "\f215";
  }
  @if $iconName=="ion-playstation" {
    content: "\f30a";
  }
  @if $iconName=="ion-plus" {
    content: "\f218";
  }
  @if $iconName=="ion-plus-circled" {
    content: "\f216";
  }
  @if $iconName=="ion-plus-round" {
    content: "\f217";
  }
  @if $iconName=="ion-podium" {
    content: "\f344";
  }
  @if $iconName=="ion-pound" {
    content: "\f219";
  }
  @if $iconName=="ion-power" {
    content: "\f2a9";
  }
  @if $iconName=="ion-pricetag" {
    content: "\f2aa";
  }
  @if $iconName=="ion-pricetags" {
    content: "\f2ab";
  }
  @if $iconName=="ion-printer" {
    content: "\f21a";
  }
  @if $iconName=="ion-pull-request" {
    content: "\f345";
  }
  @if $iconName=="ion-qr-scanner" {
    content: "\f346";
  }
  @if $iconName=="ion-quote" {
    content: "\f347";
  }
  @if $iconName=="ion-radio-waves" {
    content: "\f2ac";
  }
  @if $iconName=="ion-record" {
    content: "\f21b";
  }
  @if $iconName=="ion-refresh" {
    content: "\f21c";
  }
  @if $iconName=="ion-reply" {
    content: "\f21e";
  }
  @if $iconName=="ion-reply-all" {
    content: "\f21d";
  }
  @if $iconName=="ion-ribbon-a" {
    content: "\f348";
  }
  @if $iconName=="ion-ribbon-b" {
    content: "\f349";
  }
  @if $iconName=="ion-sad" {
    content: "\f34a";
  }
  @if $iconName=="ion-sad-outline" {
    content: "\f4d7";
  }
  @if $iconName=="ion-scissors" {
    content: "\f34b";
  }
  @if $iconName=="ion-search" {
    content: "\f21f";
  }
  @if $iconName=="ion-settings" {
    content: "\f2ad";
  }
  @if $iconName=="ion-share" {
    content: "\f220";
  }
  @if $iconName=="ion-shuffle" {
    content: "\f221";
  }
  @if $iconName=="ion-skip-backward" {
    content: "\f222";
  }
  @if $iconName=="ion-skip-forward" {
    content: "\f223";
  }
  @if $iconName=="ion-social-android" {
    content: "\f225";
  }
  @if $iconName=="ion-social-android-outline" {
    content: "\f224";
  }
  @if $iconName=="ion-social-angular" {
    content: "\f4d9";
  }
  @if $iconName=="ion-social-angular-outline" {
    content: "\f4d8";
  }
  @if $iconName=="ion-social-apple" {
    content: "\f227";
  }
  @if $iconName=="ion-social-apple-outline" {
    content: "\f226";
  }
  @if $iconName=="ion-social-bitcoin" {
    content: "\f2af";
  }
  @if $iconName=="ion-social-bitcoin-outline" {
    content: "\f2ae";
  }
  @if $iconName=="ion-social-buffer" {
    content: "\f229";
  }
  @if $iconName=="ion-social-buffer-outline" {
    content: "\f228";
  }
  @if $iconName=="ion-social-chrome" {
    content: "\f4db";
  }
  @if $iconName=="ion-social-chrome-outline" {
    content: "\f4da";
  }
  @if $iconName=="ion-social-codepen" {
    content: "\f4dd";
  }
  @if $iconName=="ion-social-codepen-outline" {
    content: "\f4dc";
  }
  @if $iconName=="ion-social-css3" {
    content: "\f4df";
  }
  @if $iconName=="ion-social-css3-outline" {
    content: "\f4de";
  }
  @if $iconName=="ion-social-designernews" {
    content: "\f22b";
  }
  @if $iconName=="ion-social-designernews-outline" {
    content: "\f22a";
  }
  @if $iconName=="ion-social-dribbble" {
    content: "\f22d";
  }
  @if $iconName=="ion-social-dribbble-outline" {
    content: "\f22c";
  }
  @if $iconName=="ion-social-dropbox" {
    content: "\f22f";
  }
  @if $iconName=="ion-social-dropbox-outline" {
    content: "\f22e";
  }
  @if $iconName=="ion-social-euro" {
    content: "\f4e1";
  }
  @if $iconName=="ion-social-euro-outline" {
    content: "\f4e0";
  }
  @if $iconName=="ion-social-facebook" {
    content: "\f231";
  }
  @if $iconName=="ion-social-facebook-outline" {
    content: "\f230";
  }
  @if $iconName=="ion-social-foursquare" {
    content: "\f34d";
  }
  @if $iconName=="ion-social-foursquare-outline" {
    content: "\f34c";
  }
  @if $iconName=="ion-social-freebsd-devil" {
    content: "\f2c4";
  }
  @if $iconName=="ion-social-github" {
    content: "\f233";
  }
  @if $iconName=="ion-social-github-outline" {
    content: "\f232";
  }
  @if $iconName=="ion-social-google" {
    content: "\f34f";
  }
  @if $iconName=="ion-social-google-outline" {
    content: "\f34e";
  }
  @if $iconName=="ion-social-googleplus" {
    content: "\f235";
  }
  @if $iconName=="ion-social-googleplus-outline" {
    content: "\f234";
  }
  @if $iconName=="ion-social-hackernews" {
    content: "\f237";
  }
  @if $iconName=="ion-social-hackernews-outline" {
    content: "\f236";
  }
  @if $iconName=="ion-social-html5" {
    content: "\f4e3";
  }
  @if $iconName=="ion-social-html5-outline" {
    content: "\f4e2";
  }
  @if $iconName=="ion-social-instagram" {
    content: "\f351";
  }
  @if $iconName=="ion-social-instagram-outline" {
    content: "\f350";
  }
  @if $iconName=="ion-social-javascript" {
    content: "\f4e5";
  }
  @if $iconName=="ion-social-javascript-outline" {
    content: "\f4e4";
  }
  @if $iconName=="ion-social-linkedin" {
    content: "\f239";
  }
  @if $iconName=="ion-social-linkedin-outline" {
    content: "\f238";
  }
  @if $iconName=="ion-social-markdown" {
    content: "\f4e6";
  }
  @if $iconName=="ion-social-nodejs" {
    content: "\f4e7";
  }
  @if $iconName=="ion-social-octocat" {
    content: "\f4e8";
  }
  @if $iconName=="ion-social-pinterest" {
    content: "\f2b1";
  }
  @if $iconName=="ion-social-pinterest-outline" {
    content: "\f2b0";
  }
  @if $iconName=="ion-social-python" {
    content: "\f4e9";
  }
  @if $iconName=="ion-social-reddit" {
    content: "\f23b";
  }
  @if $iconName=="ion-social-reddit-outline" {
    content: "\f23a";
  }
  @if $iconName=="ion-social-rss" {
    content: "\f23d";
  }
  @if $iconName=="ion-social-rss-outline" {
    content: "\f23c";
  }
  @if $iconName=="ion-social-sass" {
    content: "\f4ea";
  }
  @if $iconName=="ion-social-skype" {
    content: "\f23f";
  }
  @if $iconName=="ion-social-skype-outline" {
    content: "\f23e";
  }
  @if $iconName=="ion-social-snapchat" {
    content: "\f4ec";
  }
  @if $iconName=="ion-social-snapchat-outline" {
    content: "\f4eb";
  }
  @if $iconName=="ion-social-tumblr" {
    content: "\f241";
  }
  @if $iconName=="ion-social-tumblr-outline" {
    content: "\f240";
  }
  @if $iconName=="ion-social-tux" {
    content: "\f2c5";
  }
  @if $iconName=="ion-social-twitch" {
    content: "\f4ee";
  }
  @if $iconName=="ion-social-twitch-outline" {
    content: "\f4ed";
  }
  @if $iconName=="ion-social-twitter" {
    content: "\f243";
  }
  @if $iconName=="ion-social-twitter-outline" {
    content: "\f242";
  }
  @if $iconName=="ion-social-usd" {
    content: "\f353";
  }
  @if $iconName=="ion-social-usd-outline" {
    content: "\f352";
  }
  @if $iconName=="ion-social-vimeo" {
    content: "\f245";
  }
  @if $iconName=="ion-social-vimeo-outline" {
    content: "\f244";
  }
  @if $iconName=="ion-social-whatsapp" {
    content: "\f4f0";
  }
  @if $iconName=="ion-social-whatsapp-outline" {
    content: "\f4ef";
  }
  @if $iconName=="ion-social-windows" {
    content: "\f247";
  }
  @if $iconName=="ion-social-windows-outline" {
    content: "\f246";
  }
  @if $iconName=="ion-social-wordpress" {
    content: "\f249";
  }
  @if $iconName=="ion-social-wordpress-outline" {
    content: "\f248";
  }
  @if $iconName=="ion-social-yahoo" {
    content: "\f24b";
  }
  @if $iconName=="ion-social-yahoo-outline" {
    content: "\f24a";
  }
  @if $iconName=="ion-social-yen" {
    content: "\f4f2";
  }
  @if $iconName=="ion-social-yen-outline" {
    content: "\f4f1";
  }
  @if $iconName=="ion-social-youtube" {
    content: "\f24d";
  }
  @if $iconName=="ion-social-youtube-outline" {
    content: "\f24c";
  }
  @if $iconName=="ion-soup-can" {
    content: "\f4f4";
  }
  @if $iconName=="ion-soup-can-outline" {
    content: "\f4f3";
  }
  @if $iconName=="ion-speakerphone" {
    content: "\f2b2";
  }
  @if $iconName=="ion-speedometer" {
    content: "\f2b3";
  }
  @if $iconName=="ion-spoon" {
    content: "\f2b4";
  }
  @if $iconName=="ion-star" {
    content: "\f24e";
  }
  @if $iconName=="ion-stats-bars" {
    content: "\f2b5";
  }
  @if $iconName=="ion-steam" {
    content: "\f30b";
  }
  @if $iconName=="ion-stop" {
    content: "\f24f";
  }
  @if $iconName=="ion-thermometer" {
    content: "\f2b6";
  }
  @if $iconName=="ion-thumbsdown" {
    content: "\f250";
  }
  @if $iconName=="ion-thumbsup" {
    content: "\f251";
  }
  @if $iconName=="ion-toggle" {
    content: "\f355";
  }
  @if $iconName=="ion-toggle-filled" {
    content: "\f354";
  }
  @if $iconName=="ion-transgender" {
    content: "\f4f5";
  }
  @if $iconName=="ion-trash-a" {
    content: "\f252";
  }
  @if $iconName=="ion-trash-b" {
    content: "\f253";
  }
  @if $iconName=="ion-trophy" {
    content: "\f356";
  }
  @if $iconName=="ion-tshirt" {
    content: "\f4f7";
  }
  @if $iconName=="ion-tshirt-outline" {
    content: "\f4f6";
  }
  @if $iconName=="ion-umbrella" {
    content: "\f2b7";
  }
  @if $iconName=="ion-university" {
    content: "\f357";
  }
  @if $iconName=="ion-unlocked" {
    content: "\f254";
  }
  @if $iconName=="ion-upload" {
    content: "\f255";
  }
  @if $iconName=="ion-usb" {
    content: "\f2b8";
  }
  @if $iconName=="ion-videocamera" {
    content: "\f256";
  }
  @if $iconName=="ion-volume-high" {
    content: "\f257";
  }
  @if $iconName=="ion-volume-low" {
    content: "\f258";
  }
  @if $iconName=="ion-volume-medium" {
    content: "\f259";
  }
  @if $iconName=="ion-volume-mute" {
    content: "\f25a";
  }
  @if $iconName=="ion-wand" {
    content: "\f358";
  }
  @if $iconName=="ion-waterdrop" {
    content: "\f25b";
  }
  @if $iconName=="ion-wifi" {
    content: "\f25c";
  }
  @if $iconName=="ion-wineglass" {
    content: "\f2b9";
  }
  @if $iconName=="ion-woman" {
    content: "\f25d";
  }
  @if $iconName=="ion-wrench" {
    content: "\f2ba";
  }
  @if $iconName=="ion-xbox" {
    content: "\f30c";
  }
}


/*
Just use ths folloing to get font-awesome icons in content
.icon:after{
	@include icon('fa-glass');
}
*/
