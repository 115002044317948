.infographic {
	max-width: 76rem;
	margin: auto;
	.infographic__item {
		display: flex;
		align-items: center;
		margin: 1rem 0;
	}
	.infographic__title {
		color: $purple;
		flex-grow: 1;
		justify-content: flex-end;
		display: flex;
		flex-basis: 30%;
		h2 {
			margin: 0;
			text-align: right;
		}
	}
	.infographic__icon {
		margin: 0 2rem 0 4rem;
	}
	.infographic__content {
		flex-grow: 1; // justify-content: flex-end;
		display: flex;
		flex-basis: 50%;
		p {
			margin: 0;
		}
	}
	.infographic__footer {
		display: flex;
		padding-top: 2rem;
		padding-bottom: 6rem;
	}
	.infographic__caption {
		flex-grow: 1;
		flex-basis: 50%;
        padding-right:3rem;
		h3,
		p {
			margin: 0
		}
	}
	.infographic__image {
		flex-grow: 1;
		flex-basis: 50%;
	}
}


// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

@include break(max, $screen-sm-max) {
	.infographic {
		.infographic__image,
		.infographic__title,
		.infographic__icon,
		.infographic__content,
		.infographic__caption{
			flex-basis: initial;
			margin: 0;
            padding-right: 0;

		}
		.infographic__footer,
		.infographic__item {
			flex-direction: column;
			padding: 2rem;
			flex-basis: initial;
		}
	}
}