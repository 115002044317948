.countries {
	color: $text-slate;
	background: white;
	padding: .4rem;
	display: flex;
	flex-direction: column;
	border-radius: $border-radius;
	cursor: pointer;
	font-size: 1.4rem;
	position: fixed;
	top: 1rem;
	left: 2rem;
	z-index:5;
	width: 18rem;
	.countries__input {
		color: $text-slate;
		margin: 1rem 0.4rem;
		width: calc(100% - .8rem);
		&:focus {
			border: 1px solid $laser-blue;
		}
	}
	.countries__item {
		padding: .4rem 0;
		a {
			color: $text-slate;
		}
	}
	.countries__target {
		display: flex; // align-items: center;
		// flex-grow: 1;
	}
	.countries__list {
		flex-direction: column;
		display: flex;
		@include unlist;
	}
	.countries__selected {
		background: white;
		padding: 0 1rem;
		border-radius: .4rem;
		border-radius: 1px solid #eee;
		display: flex;
	}
	.countries__image {
		flex: 0 0 3.6rem;
		height: 3.6rem;
		display: block;
		border-radius: 100%;
		overflow: hidden;
		margin-right: 1rem;
		img {
			width: 100%;
		}
	}
	.courtries__name {
		align-self: center;
	}
	.countries__caption {
		color: $text-slate;
	}
	.countries__figure {
		display: flex;
		align-items: center;
	}
	.countries__panel {
		// background: white;
		display: flex;
		height: 0;
		flex-direction: column;
		overflow: hidden;
		&[open] {
			height: auto;
			max-height: 100rem;
		}
	}
}



/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.countries{
		padding: 1rem;
		width: 100%;
		position: relative;
		margin-top: 6.5rem;
		top: 0;
		left: 0;
		border-radius: 0;
	}
}

/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝      
*/

@include break(min, $screen-lg-min) {

}

/*
████████╗ █████╗ ██████╗ ██╗     ███████╗████████╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝╚══██╔══╝
	 ██║   ███████║██████╔╝██║     █████╗     ██║   
	 ██║   ██╔══██║██╔══██╗██║     ██╔══╝     ██║   
	 ██║   ██║  ██║██████╔╝███████╗███████╗   ██║   
	 ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝   ╚═╝   
*/

@include break(min, $screen-md-min, max, $screen-md-max) {

}