$transition--slow: all 400ms;
.accordion__control {
	text-align: right;
	span {
		cursor: pointer;
		color: royalblue;
	}
}

.accordion .accordion__item {
	background: #fff
}

.accordion {
	list-style: none;
	margin: 2rem 0;

	&.accordion--no-date {
		.accordion__item {
			padding-left: 6rem;

			.accordion__toggle {
				left: 2rem;
			}
		}

		&.accordion--hidden {
			.accordion__item {
				padding: 0 1.5rem;
			}
		}
	}

	&.accordion--hidden {
		margin-left: -1.5rem;
		margin-right: -1.5rem;

		.accordion__item.accordion__item--selected {
			.accordion__panel {
				// max-height: 100rem;
				display: block;
				transition: none;
			}
		}

		.accordion__item {
			h3 {
				margin: 0;
				font-size: 2rem;
				color: inherit;
			}

			background: 0 0;

			&:focus,
			&:hover,
			&:active {
				background: 0 0;

				.accordion__tab {
					cursor: default;

					h3 {
						color: inherit;
					}
				}
			}

			.accordion__toggle {
				display: none;
			}

			.accordion__date {
				padding: 0;
				width: 5rem;
				padding-top: 1rem;

				.accordion__day {
					font-size: 2.6rem;
				}

				.accordion__month {
					font-size: 1.6rem;
				}
			}

			.accordion__panel {
				padding-right: 0;
				transition: none;
				height: auto;
				display: block;
				opacity: 1;

				section {
					display: none;
				}

				footer {
					margin: 1rem 0;
				}
			}
		}
	}

	.accordion__date {
		position: absolute;
		left: 0;
		top: 0;
		text-align: center;
		padding: 2rem 0 0;
		height: 100%;
		width: 14rem;
		color: $dark-blue;
		line-height: 1;
		border-right: 1px solid $border-grey;

		.accordion__dateleft {
			display: inline-block;
			width: 6rem;
			float: left;
			padding-left: 1rem;
		}

		.accordion__datemiddle {
			display: inline-block;
			font-size: 3.2rem;
			font-weight: 700;
			width: 1.2rem;
		}

		.accordion__dateright {
			display: inline-block;
			width: 6rem;
			padding-right: 1rem;
		}

		.accordion__month {
			font-size: 1.8rem;
			display: block;
			padding-bottom: .5rem;
		}

		.accordion__day {
			font-size: 3.2rem;
			display: block;
			font-weight: bold;
		}
	}

	.accordion__item {
		text-decoration: none;
		margin: 0;
		position: relative;
		display: block;
		padding: 2rem 4rem 2rem 20rem;
		height: auto;
		transition: $transition--all;
		overflow: hidden;
		outline: none;

		.accordion__tab {
			cursor: pointer;
			outline: none;

			p {
				margin-bottom: 0;
			}
		}

		h3.h3__link {
			margin: 0;
			color: $light-blue;
		}

		&:hover,
		&:focus {
			background: #f2f2f2;

			.accordion__tab {
				h3,
				.accordion__toggle {
					color: $blue;
				}
			}
		}

		.accordion__toggle {
			position: absolute;
			display: block;
			left: 16rem;
			width: 2.5rem;
			line-height: 2.5rem;
			height: 2.5rem;
			font-size: 2.5rem;
			color: $light-blue;
			z-index: 0;
			text-align: center;
			cursor: pointer;
			transform: rotate(0);
			transform-origin: center;
			transition: $transition--all-fast;
		}

		&[data-open="true"] {
			h3.h3__link {
				cursor: pointer;
			}

			background: #f2f2f3;

			.accordion__panel {
				display: block;
				opacity: 1;
				transition: $transition--slow;
			}

			.accordion__toggle {
				transform: rotate(90deg);
				transition: $transition--all-fast;
			}
		}

		&.accordion--hide {
			height: 0;
			padding: 0;
			border: none;
		}
	}

	.accordion__panel {
		padding-right: 4rem;
		// max-height: 0;
		display: none;
		// opacity: 0;
		transition: $transition--slow;

		[class*="image--"] {
			max-width: 20rem;
		}
	}

	.accordion__el {
		margin-bottom: 2rem;

		h4 {
			margin-bottom: 0;
			letter-spacing: 0.02em;
		}
	}

	.accordion__text {
		&--xs-margin {
			margin-bottom: 0.3rem;
		}
	}
}

// ██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝███████║██║   ██║██╔██╗ ██║█████╗
// ██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

@include break(max, $screen-xs) {
    .accordion {
        &.accordion--with-date {
            .accordion__item {
            min-height: 20rem;
            }
        }
        .accordion__item {
            padding: 2rem 1rem 2rem 12rem;
            .accordion__toggle {
                left: 9rem;
            }
        }
        .accordion__date {
            width: 8rem;
            .accordion__dateleft, {
                display: block;
                float: none;
                padding: 0;
                width: auto;
            }
            .accordion__datemiddle, .accordion__dateright {
                display: block;
                width: auto;
                padding: 0;
            }
        }
    }
}

@include break(max, $screen-xs-max) {
	.accordion{
		.accordion__panel{
			padding-right: 0;
		}
		.accordion__item.accordion__item--selected {
			.accordion__panel {
				display: block;
				transition: none;
			}
		}
	}
}

@include break(max, $screen-sm-max) {
	.accordion{
		&.accordion--mobile{
			margin-top: 0;
			.accordion__item{
				padding: 1rem 2rem 1rem 4rem;
				.accordion__toggle{
					left: 0.5rem;
				}
				h3{
					font-size: 2rem;
				}
			}
		}
	}
}

// ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗
// ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
// ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
// ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝
// ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║
// ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝

@include break(min, $screen-md-min) {}