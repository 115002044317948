.member__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	.member__item {
		text-align: center;
    flex-basis: 25%;
    padding: 2rem;
		h3 {
			margin-top: 1rem;
		}
		p {
			margin-bottom: 0;
		}
		img {
			margin: 1rem;
		}
	}
	&.member--lg {
		.member__item {
			max-width: 30rem;
			text-align: center;
			img {
				max-width: 20rem;
			}
		}
	}
	&.member--sm {
		justify-content: flex-start;
		margin-bottom: 6rem;
		.member__item {
			flex-basis: 33.333%;
			text-align: left;
			padding-bottom: 0;
			h3{
				line-height: 1;
			}
			p{
				margin: 0;
				font-size: 1.4rem;
				line-height: 1.4;
			}
			img {
				float: left;
				max-width: 10rem;
				margin-left: 0;
				margin-bottom: 4rem;
			}
		}
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.member__list.member--lg .member__item,
	.member__list.member--sm .member__item {
		flex-basis: 100%;
		padding: 1rem;
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
